import * as Yup from "yup";

const isVideoUrl = (url) => {
  if (!url) {
    return false;
  }
  const youtubePattern =
    /^(https?:\/\/)?(www\.)?youtu\.be\/([a-zA-Z0-9_-]{11})/;
  const vimeoPattern = /^(https?:\/\/)?(www\.)?vimeo\.com\/(\d+)/;

  const youtubeMatch = url.match(youtubePattern);
  const vimeoMatch = url.match(vimeoPattern);

  return youtubeMatch !== null || vimeoMatch !== null;
};

const videoUrlSchema = Yup.string().test(
  "is-video-url",
  "Solo enlaces desde vimeo o youtube son aceptados",
  (value) => {
    return isVideoUrl(value);
  }
);

export const createVideoPostSchema = Yup.object({
  videoURL: videoUrlSchema.required(
    "Introduzca un enlace valido para continuar"
  ),
  description: Yup.string(),
  //currentDreamId: Yup.string().required("Debe seleccionar una opción"),
  files: Yup.array(),
});
