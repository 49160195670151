import { Drawer, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import RightMenuEvents from "../../../components/RightMenu/RightMenuEvents";

import { useDispatch, useSelector } from "react-redux";
import {
  getEventBatch,
  getEventsFromCurrentMonth,
  getEventsFromCurrentWeek,
} from "../../../store/actions/events";
import EventsMainContent from "../../../components/dreamerPage/eventsMainContent/EventsMainContent";
import RightNotificationMenu from "../../../components/RightMenu/RightNotificationMenu";
import { Animated } from "react-animated-css";
import DreamsMainContent from "../../../components/dreamerPage/dreamsMainContent/DreamsMainContent";
import { getDreamsBatch } from "../../../store/actions/dreamers";

export const Dreams = () => {
  const [openRightSidebar, setOpenRightSidebar] = useState(false);
  const [openNotificationsMod, setNotificationsMod] = useState(false);
  const [openPopupMenu, setOpenPopupMenu] = useState(false);
  const [lastDream, setLastDream] = useState(null);
  const [lastMonthlyDream, setLastMonthlyDream] = useState(null);
  const [lastWeekDream, setLastWeekDream] = useState(null);

  const [dreamsNumber, setDreamsNumber] = useState(100);
  const [monthlyDreamsNumber, setMonthlyDreamNumber] = useState(100);
  const [weekDreamsNumber, setWeekDreamsNumber] = useState(100);

  const [dreams, setDreams] = useState([]);
  const [monthDreams, setMonthDreams] = useState([]);
  const [weekDreams, setWeekDreams] = useState([]);

  const dispatch = useDispatch();

  const { dreams: reduxDreams } = useSelector((state) => state.dreamers);
  //const { lastDreamsBatchSize } = useSelector((state) => state.dreamers);
  const { monthlyEvents: reduxMonthlyDreams } = useSelector(
    (state) => state.dreamers
  );
  const { weekDreams: reduxWeekDreams } = useSelector(
    (state) => state.dreamers
  );

  const BATCH_SIZE = 5;

  const handleAllDreamsScroll = () => {
    const setDreamsData = (lastDream, dreamsNum) => {
      setLastDream(lastDream);
      setDreamsNumber(dreamsNum);
    };

    dispatch(getDreamsBatch(lastDream, BATCH_SIZE, setDreamsData));
  };

  const handleMonthlyDreamsScroll = () => {
    dispatch(getEventsFromCurrentMonth(lastMonthlyDream, BATCH_SIZE));
  };

  const handleWeekDreamsScroll = () => {
    dispatch(getEventsFromCurrentWeek(lastWeekDream, BATCH_SIZE));
  };

  //Sets up dreams state: All tab
  useEffect(() => {
    if (reduxDreams?.length) {
      setDreams(reduxDreams);
      //setLastDream(reduxDreams[reduxDreams.length - 1]);
      //setDreamsNumber(reduxDreams.length);
    } else {
      if (dreamsNumber >= BATCH_SIZE) {
        handleAllDreamsScroll();
      }
    }
  }, []);

  //Sets up dreams state: Month tab
  useEffect(() => {
    if (reduxMonthlyDreams?.length) {
      setMonthDreams(reduxMonthlyDreams);
      setLastWeekDream(reduxMonthlyDreams[reduxMonthlyDreams.length - 1]);
      setWeekDreamsNumber(reduxMonthlyDreams.length);
    } else {
      if (weekDreamsNumber >= BATCH_SIZE) {
        handleMonthlyDreamsScroll();
      }
    }
  }, []);

  //Sets up dreams state: Week tab
  useEffect(() => {
    if (reduxWeekDreams?.length) {
      setWeekDreams(reduxWeekDreams);
      setLastMonthlyDream(reduxWeekDreams[reduxWeekDreams.length - 1]);
      setMonthlyDreamNumber(reduxWeekDreams.length);
    } else {
      if (monthlyDreamsNumber >= BATCH_SIZE) {
        handleWeekDreamsScroll();
      }
    }
  }, []);

  //Tracks dreams changes through scroll: All tab
  useEffect(() => {
    if (reduxDreams) {
      setDreams(reduxDreams);
      //setLastDream(reduxDreams[reduxDreams.length - 1]);
      //setDreamsNumber(reduxDreams.length);
    }
  }, [reduxDreams]);

  //Tracks dreams changes through scroll: Month tab
  useEffect(() => {
    if (reduxMonthlyDreams) {
      setMonthDreams(reduxMonthlyDreams);
      setLastMonthlyDream(reduxMonthlyDreams[reduxMonthlyDreams.length - 1]);
      setMonthlyDreamNumber(reduxMonthlyDreams.length);
    }
  }, [reduxMonthlyDreams]);

  //Tracks dreams changes through scroll: Week tab
  useEffect(() => {
    if (reduxWeekDreams) {
      setWeekDreams(reduxWeekDreams);
      setLastWeekDream(reduxWeekDreams[reduxWeekDreams.length - 1]);
      setWeekDreamsNumber(reduxWeekDreams.length);
    }
  }, [reduxWeekDreams]);

  return (
    <>
      <Grid
        item
        minHeight="100vh"
        xl={11}
        lg={11}
        md={12}
        xs={12}
        sx={{ padding: { md: "20px 60px", xs: "20px 25px" } }}
      >
        <DreamsMainContent
          allDreams={dreams}
          nextDreamsAllTab={handleAllDreamsScroll}
          dreamsNumber={dreamsNumber}
          monthDreams={monthDreams}
          nextDreamsMonthTab={handleMonthlyDreamsScroll}
          monthlyDreamsNumber={monthlyDreamsNumber}
          weekDreams={weekDreams}
          nextDreamsWeekTab={handleWeekDreamsScroll}
          weekDreamsNumber={weekDreamsNumber}
          setOpenRightSidebar={setOpenRightSidebar}
        />
      </Grid>
      {/* <Grid item xl={2} lg={3} position="fixed" right={0}>
        <Drawer
          anchor="right"
          open={openRightSidebar}
          onClose={() => setOpenRightSidebar(false)}
          PaperProps={{
            sx: {
              borderTopLeftRadius: "32px",
              borderBottomLeftRadius: "32px",
              width: { lg: "30%", md: "40%", sm: "60%", xs: "85%" },
            },
          }}
        >
          {openNotificationsMod ? (
            <RightNotificationMenu
              openPopupMenu={openPopupMenu}
              setOpenPopupMenu={setOpenPopupMenu}
              setNotificationsMod={setNotificationsMod}
            />
          ) : (
            <RightMenuEvents
              // events={events}
              setOpenPopupMenu={setOpenPopupMenu}
              setNotificationsMod={setNotificationsMod}
              setOpenRightSidebar={setOpenRightSidebar}
            />
          )}
        </Drawer>

        <Grid
          item
          xl={2}
          lg={3}
          position="fixed"
          sx={{ right: 0, width: "100%" }}
          display={{ xs: "none", lg: "block" }}
        >
          {!openNotificationsMod && (
            <Animated
              animationIn="fadeInRight"
              animationOut="fadeOutLeft"
              isVisible={true}
              animationInDuration={500}
              animationOutDuration={500}
            >
              <RightMenuEvents
                events={events}
                setOpenPopupMenu={setOpenPopupMenu}
                setNotificationsMod={setNotificationsMod}
                setOpenRightSidebar={setOpenRightSidebar}
              />
            </Animated>
          )}

          {openNotificationsMod && (
            <Animated
              animationIn="fadeInRight"
              animationOut="fadeOutLeft"
              isVisible={true}
              animationInDuration={500}
              animationOutDuration={500}
            >
              <RightNotificationMenu
                openPopupMenu={openPopupMenu}
                setOpenPopupMenu={setOpenPopupMenu}
                setNotificationsMod={setNotificationsMod}
              />
            </Animated>
          )}
        </Grid>
      </Grid> */}
    </>
  );
};
