import { Box, Stack, Typography } from "@mui/material";

import { FiCheck } from "react-icons/fi";

const InterestedDreamer = ({ userData }) => {
  return (
    <Stack direction="row" color="#fff">
      <Stack direction="row" gap={2}>
        <Box width="38px" height="38px">
          <img
            src="https://picsum.photos/id/309/200/300"
            alt="Soñador"
            width="100%"
            height="100%"
            style={{ borderRadius: "12px" }}
          />
        </Box>
        <Typography>{userData.name}</Typography>
      </Stack>

      <span
        style={{
          marginLeft: "auto",
          backgroundColor: "#53d769",
          borderRadius: "8px",
          width: "28px",
          height: "28px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <FiCheck />
      </span>
    </Stack>
  );
};

export default InterestedDreamer;
