import { useEffect, useState } from "react";

import PostComment from "../postComment/PostComment";

import {
  createComment,
  getPostComments,
  getRecentComments,
} from "../../../store/actions/dreamers";
import {
  Box,
  Button,
  DialogContent,
  InputAdornment,
  OutlinedInput,
} from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";

import { BiFace, BiSend } from "react-icons/bi";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import { timestampCreador } from "../../../firebase/firebase-config";

const CommentsContainer = ({ userId, postId }) => {
  const { comments } = useSelector((state) => {
    // console.log(state); // Log the entire state
    return state.loggedUser.language;
  });
  const [postComments, setPostComments] = useState([]);
  const [showMore, setShowMore] = useState(false);
  const [lastComment, setLastComment] = useState(null);
  const [commentsNumber, setCommentsNumber] = useState(100);

  const currentUser = useSelector((state) => state.loggedUser.data);

  const BATCH_SIZE = 2;

  const { getFieldProps, handleSubmit } = useFormik({
    initialValues: {
      comment: "",
    },
    //validationSchema: logInSchema,
    onSubmit: (values) => {
      const commentData = {
        cdate: timestampCreador.fromDate(new Date()),
        content: values.comment,
        userRef: currentUser.id,
        isActive: true,
      };

      createComment(userId, postId, commentData);
    },
  });

  useEffect(() => {
    const getComments = async () => {
      const comments = await getPostComments(
        userId,
        postId,
        BATCH_SIZE,
        lastComment
      );
      setPostComments((prevComments) => [...prevComments, ...comments]);
      setLastComment(comments[comments.length - 1]);
      setCommentsNumber(comments.length);
    };

    if (commentsNumber >= BATCH_SIZE) {
      getComments();
    }
  }, [showMore]);

  useEffect(() => {
    const setNewComments = (comments) => {
      setPostComments((prevComments) => {
        const commentsArr = [...prevComments, ...comments];
        commentsArr.sort(
          (firstValue, secondValue) => secondValue.cdate - firstValue.cdate
        );

        return commentsArr;
      });
    };

    const unsubscribe = getRecentComments(postId, setNewComments, userId, 1);

    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault();
        handleSubmit();
      }}
    >
      <DialogTitle id="scroll-dialog-title">{comments?.title}</DialogTitle>
      <DialogContent
        sx={{
          padding: "24px",
          backgroundColor: "#fff",
          maxHeight: "600px",
        }}
      >
        <Box>
          {postComments.map((comment) => {
            const img = comment?.commentOwnerData?.imgURL;
            const name = comment?.commentOwnerData?.name;

            return (
              <PostComment
                key={comment.id}
                commentOwner={{ img, name }}
                commentContent={comment?.content}
                commentDate={comment.cdate}
              />
            );
          })}
        </Box>
      </DialogContent>

      <DialogActions
        sx={{
          padding: "24px",
          flexDirection: { sm: "row", xs: "column-reverse" },
          alignItems: { sm: "normal", xs: "center" },
          justifyContent: { sm: "center", xs: "normal" },
        }}
      >
        <Button
          variant="contained"
          sx={{
            marginRight: { sm: "auto", xs: "" },
            marginTop: { sm: 0, xs: "20px" },
          }}
          onClick={() => setShowMore((prevState) => !prevState)}
        >
          {comments?.moreComments}
        </Button>

        <Box>
          <OutlinedInput
            {...getFieldProps("comment")}
            id="outlined-adornment-comment"
            fullWidth
            type="text"
            placeholder={comments?.reply}
            sx={{ width: "100%" }}
            endAdornment={
              <InputAdornment position="end" sx={{ cursor: "pointer" }}>
                <BiFace color="8F92A1" />
                <span onClick={(event) => handleSubmit(event)}>
                  <BiSend color="#0659FD" />
                </span>
              </InputAdornment>
            }
          />
        </Box>
      </DialogActions>
    </form>
  );
};

export default CommentsContainer;
