import { Card, CardContent, CardMedia, Stack, Typography } from "@mui/material";
import { useEffect } from "react";
import { useState } from "react";

import { FiCheck } from "react-icons/fi";

const NextEventCard = ({ eventData }) => {
  const [eventDate, setEventDate] = useState("");

  useEffect(() => {
    if (eventData) {
      //const dateJSformat = eventData.eventDate.toDate();
      const dateJSformat = new Date(
        eventData.eventDate.seconds * 1000 +
          eventData.eventDate.nanoseconds / 100000
      );

      setEventDate(
        dateJSformat.toLocaleDateString("en-CR", {
          day: "numeric",
          month: "long",
          year: "numeric",
        })
      );
    }
  }, [eventData]);

  return (
    <Card
      variant="outlined"
      sx={{ border: "none", backgroundColor: "#1e1f20" }}
    >
      <CardMedia>
        <img
          src={eventData?.imgURL}
          alt="Cover del Evento"
          style={{ borderRadius: "8px", height: "140px", width: "100%" }}
        />
      </CardMedia>
      <CardContent sx={{ padding: 0 }}>
        <Stack direction="row" alignItems="center" width="100%">
          <Stack mr="auto">
            <Typography variant="h6" color="#fff">
              {eventData.name}
            </Typography>
            <Typography sx={{ color: "#8F92A1" }}>{eventDate}</Typography>
          </Stack>

          <span
            style={{
              backgroundColor: "#4b4c4d",
              borderRadius: "8px",
              width: "28px",
              height: "28px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <FiCheck />
          </span>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default NextEventCard;
