import { CircularProgress, Grid, Stack, useMediaQuery } from "@mui/material";
import DreamGrid from "../DreamsGrid/DreamsGrid";
import CausesChipRow from "../causesChipRow/CausesChipRow";
import Filters from "../filters/Filters";

import { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";
import useFilterCategDreams from "../../../customHooks/useFilterCategDreams";
import { getDreamers, getDreamsBatch } from "../../../store/actions/dreamers";
import Hero from "../hero/Hero";
import useFilterDreams from "../../../customHooks/useFilterDreams";

const Dreams = () => {
  const isDown700 = useMediaQuery("(max-width:700px)");
  const isDown580 = useMediaQuery("(max-width:580px)");

  const dispatch = useDispatch();

  const dreams = useSelector((state) => state.dreamers.dreams);
  const dreamers = useSelector((state) => state.dreamers.dreamers);

  const selectedCategory = useSelector(
    (state) => state.loggedUser.selectedCategory
  );
  const filteredDreamsByCateg = useFilterCategDreams(selectedCategory, dreams);
  const dreamFilters = useSelector((state) => state.makers.dreamFilters);
  const filteredDreamsByOpt = useFilterDreams(
    filteredDreamsByCateg,
    dreamFilters?.[0] || ["recent"]
  );

  // const filteredDreamsByOpt = useFilterDreams(filteredDreamsByCateg, [
  //   "recent",
  //   "close",
  //   "dreams",
  //   "ascending",
  // ]);
  const [lastDream, setLastDream] = useState(null);
  const [dreamsNumber, setDreamsNumber] = useState(100);
  //const [triggerNextDreams, setTriggerNextDreams] = useState(false);

  const BATCH_SIZE = 5;

  const getDreams = async () => {
    const setDreamsData = (lastDream, dreamsNum) => {
      setLastDream(lastDream);
      setDreamsNumber(dreamsNum);
    };

    dispatch(getDreamsBatch(lastDream, BATCH_SIZE, setDreamsData));
  };

  useEffect(() => {
    if (dreams) {
      dispatch(getDreamers(dreams, dreamers));
    }
  }, [dreams]);

  useEffect(() => {
    if (dreamsNumber >= BATCH_SIZE) {
      getDreams();
    }
  }, []);

  return (
    <>
      {/* <Grid container> */}
      {/* <Grid item lg={6} md={4} xs={12}>
          <Box marginBottom="10px">
            <Typography fontSize="22px">Hola,</Typography>
            <Typography fontWeight="bold" fontSize="26px">
              {userData.name} 👋
            </Typography>
          </Box>
        </Grid> */}

      {/* <Grid
          item
          lg={6}
          md={8}
          xs={12}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems={{ xs: "flex-start", md: "flex-end" }}
        >
          <Stack direction="row" gap={3} width={isDown700 ? "100%" : "65%"}>
            <Searchbar />
            <span
              style={{
                borderRadius: "50%",
                padding: "10px 15px",
                border: "1px solid #EAEAEA",
              }}
            >
              <img src={bellIcon} alt="" />
            </span>
          </Stack>
        </Grid> */}
      {/* </Grid> */}

      <Grid item xl={11} lg={11} md={12} xs={12} mt={7} minHeight="100vh">
        <section
          style={{
            padding: isDown700 ? (isDown580 ? "0 20px" : "0 40px") : "0 40px",
          }}
        >
          <Hero />

          <CausesChipRow />
          <Filters />

          <InfiniteScroll
            style={{ scrollbarWidth: "none" }}
            dataLength={filteredDreamsByCateg.length} //This is important field to render the next data
            next={getDreams}
            hasMore={dreamsNumber >= BATCH_SIZE}
            loader={
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                height="200px"
              >
                <CircularProgress />
              </Stack>
            }
            endMessage={
              <p style={{ textAlign: "center", marginTop: "40px" }}>
                <b>Parece que no hay más sueños 😐 </b>
              </p>
            }
          >
            <DreamGrid dreams={filteredDreamsByOpt} />
          </InfiniteScroll>
        </section>
      </Grid>
    </>
  );
};

export default Dreams;
