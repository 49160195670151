import { db } from "../../firebase/firebase-config";

export const sendMessage = async (messageData, executeAction) => {
  let skipFinally = false;

  try {
    const docData = await db.collection("contact").add(messageData);

    return docData.id;
  } catch (error) {
    console.log(error);
    skipFinally = true;
  } finally {
    if (executeAction && !skipFinally) {
      executeAction();
    }
  }
};
