import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  InputBase,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";

const HelpType = ({ formik, dreamForm }) => {
  return (
    <>
      <Typography fontWeight="bold" variant="h6" component="h2" mb={2}>
        {dreamForm?.name}
      </Typography>

      <Grid container columnSpacing={3} rowSpacing={3}>
        <Grid item lg={6} xs={12}>
          <Box
            sx={{
              backgroundColor: "#fff",
              padding: "15px",
              borderRadius: "24px",
              height: "100%",
            }}
          >
            <FormControl variant="outlined" sx={{ width: "100%", padding: 0 }}>
              <label
                htmlFor="outlined-typeOfDream"
                style={{ fontWeight: "bold" }}
              >
                <span> {dreamForm?.typeOfDream}</span>
              </label>
              <InputBase
                id="outlined-typeOfDream"
                placeholder={dreamForm?.describe}
                name="typeOfDream"
                onChange={formik.handleChange}
                value={formik.values.typeOfDream}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.typeOfDream &&
                  Boolean(formik.errors.typeOfDream)
                }
                sx={{ border: "none" }}
              />

              <FormHelperText error id="outlined-typeofDream">
                {formik.touched.typeOfDream && formik.errors.typeOfDream
                  ? formik.errors.typeOfDream
                  : " "}
              </FormHelperText>
            </FormControl>
          </Box>
        </Grid>
        <Grid item lg={6} xs={12}>
          <Box
            sx={{
              backgroundColor: "#fff",
              padding: "15px",
              borderRadius: "24px",
              height: "100%",
            }}
          >
            <FormControl variant="outlined" sx={{ width: "100%", padding: 0 }}>
              <label htmlFor="availableTime" style={{ fontWeight: "bold" }}>
                <span>{dreamForm?.time}</span>
              </label>
              <Select
                id="availableTime"
                name="availableTime"
                variant="outlined"
                onChange={formik.handleChange}
                value={formik.values.availableTime}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.availableTime &&
                  Boolean(formik.errors.availableTime)
                }
                sx={{
                  borderRadius: "24px",
                  borderColor: "transparent !important",
                  border: "none !important",
                }}
              >
                <MenuItem value={1}>{dreamForm?.oneMonth}</MenuItem>
                <MenuItem value={2}>{dreamForm?.twoMonths}</MenuItem>
                <MenuItem value={3}>{dreamForm?.threeMonths}</MenuItem>
              </Select>

              <FormHelperText error id="outlined-availableTime">
                {formik.touched.availableTime && formik.errors.availableTime
                  ? formik.errors.availableTime
                  : " "}
              </FormHelperText>
            </FormControl>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default HelpType;
