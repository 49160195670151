import { Box, Button, Drawer, Grid, Stack, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import EventHeader from "../../../components/dreamerPage/eventHeader/EventHeader";

import { FiChevronLeft } from "react-icons/fi";
import { FiClock } from "react-icons/fi";
import RightMenuSoloEvent from "../../../components/RightMenu/RightMenuSoloEvent";

import { FiMenu } from "react-icons/fi";
import { useSelector } from "react-redux";
import { useState } from "react";
import { useEffect } from "react";
import { getOneUser } from "../../../store/actions/userActions";
import { Animated } from "react-animated-css";
import RightNotificationMenu from "../../../components/RightMenu/RightNotificationMenu";
import { getOneEvent } from "../../../store/actions/events";

const EventDetails = () => {
  const [openRightSidebar, setOpenRightSidebar] = useState(false);
  const { general } = useSelector((state) => {
    // console.log(state); // Log the entire state
    return state.loggedUser.language;
  });
  const [openNotificationsMod, setNotificationsMod] = useState(false);
  const [eventUserCreator, setEventUserCreator] = useState(null);
  const [interestedUsers, setInterestedUsers] = useState([]);
  const [openPopupMenu, setOpenPopupMenu] = useState(false);
  const [currentEvent, setCurrentEvent] = useState(null);

  const navigation = useNavigate();
  const { id } = useParams();

  const { events: allEvents } = useSelector((state) => state.events);

  const selectedEvent = allEvents.find((event) => event.id === id);

  useEffect(() => {
    if (selectedEvent || currentEvent) {
      const getUser = async () => {
        const user = await getOneUser(
          selectedEvent?.userRef || currentEvent.userRef
        );
        setEventUserCreator(user);
      };

      getUser();
    }
  }, [selectedEvent, currentEvent]);

  useEffect(() => {
    if (!selectedEvent) {
      const getEvent = async () => {
        const event = await getOneEvent(id);
        setCurrentEvent(event);
      };

      getEvent();
    }
  }, [selectedEvent]);

  useEffect(() => {
    if (selectedEvent || currentEvent) {
      const interestedUsersId =
        selectedEvent?.interestedUsers || currentEvent.interestedUsers;
      const list = [];

      const getUser = async () => {
        for (const userId of interestedUsersId) {
          const user = await getOneUser(userId);
          list.push(user);
        }

        setInterestedUsers(list);
      };

      getUser();
    }
  }, [selectedEvent, currentEvent]);

  return (
    <>
      <Grid
        item
        xl={9}
        lg={8}
        md={11}
        xs={12}
        sx={{
          padding: { md: "20px 60px", xs: "20px 25px" },
          backgroundColor: "#fff",
        }}
      >
        <Box mb={10} maxWidth="1100px" mr="auto" ml="auto">
          <Stack direction="row" alignItems="center" my={3}>
            <Button
              variant="contained"
              disableElevation
              onClick={() => navigation(-1)}
              sx={{
                borderRadius: "12px",
                backgroundColor: "#e2e3e6",
                color: "#8F92A1",
                width: "103px",
                fontWeight: "bold",
                mr: "auto",
                py: 2,
              }}
            >
              <FiChevronLeft />
              {general?.goBack}
            </Button>

            <Box
              component="span"
              display={{ xs: "block", lg: "none" }}
              sx={{ cursor: "pointer" }}
              onClick={() => setOpenRightSidebar(true)}
            >
              <FiMenu size={21} />
            </Box>
          </Stack>

          <Stack direction="row">
            <EventHeader
              userData={eventUserCreator}
              eventData={selectedEvent || currentEvent}
            />
          </Stack>

          <Box width="100%" my={4}>
            <img
              width="100%"
              height="470px"
              src={selectedEvent?.imgURL || currentEvent?.imgURL}
              alt="Cover de evento"
              style={{
                borderRadius: "8px",
                objectFit: "cover",
                objectPosition: "center",
              }}
            />
          </Box>

          <Typography variant="h4" fontWeight="bold">
            {selectedEvent?.name || currentEvent?.name}
          </Typography>

          <Stack direction="row" alignItems="center" gap={2} my={4}>
            <span
              style={{
                backgroundColor: "#F7F7F7",
                padding: "10px 15px",
                borderRadius: "12px",
              }}
            >
              <FiClock />
            </span>

            <Box>
              <Typography fontSize="14px" fontWeight="bold">
                {selectedEvent?.eventDate
                  ? selectedEvent?.eventDate.toDate().toLocaleString("CR", {
                      day: "numeric",
                      month: "long",
                      year: "numeric",
                    })
                  : currentEvent?.eventDate.toDate().toLocaleString("CR", {
                      day: "numeric",
                      month: "long",
                      year: "numeric",
                    })}
              </Typography>
              <Typography fontSize="14px" color="#8F92A1">
                {selectedEvent?.duration || currentEvent?.duration}
              </Typography>
            </Box>
          </Stack>

          <Stack gap={2} width="70%">
            <Typography fontWeight="bold">Descripcion del Evento</Typography>
            <Typography color="#8F92A1">
              {selectedEvent?.description || currentEvent?.description}
            </Typography>
          </Stack>
        </Box>
      </Grid>
      <Grid item xl={2} lg={3} width="100%" position="fixed" right={0}>
        <Drawer
          anchor="right"
          open={openRightSidebar}
          onClose={() => setOpenRightSidebar(false)}
          PaperProps={{
            sx: {
              borderTopLeftRadius: "32px",
              borderBottomLeftRadius: "32px",
              width: { lg: "30%", md: "40%", sm: "60%", xs: "85%" },
            },
          }}
        >
          {openNotificationsMod ? (
            <RightNotificationMenu
              setOpenRightSidebar={setOpenRightSidebar}
              openPopupMenu={openPopupMenu}
              setOpenPopupMenu={setOpenPopupMenu}
              setNotificationsMod={setNotificationsMod}
            />
          ) : (
            <RightMenuSoloEvent
              interestedUsers={interestedUsers}
              openPopupMenu={openPopupMenu}
              setOpenPopupMenu={setOpenPopupMenu}
              setNotificationsMod={setNotificationsMod}
              setOpenRightSidebar={setOpenRightSidebar}
            />
          )}
        </Drawer>

        <Grid
          item
          xl={2}
          lg={3}
          position="fixed"
          sx={{ right: 0, width: "100%" }}
          display={{ xs: "none", lg: "block" }}
        >
          {!openNotificationsMod && (
            <Animated
              animationIn="fadeInRight"
              animationOut="fadeOutLeft"
              isVisible={true}
              animationInDuration={500}
              animationOutDuration={500}
            >
              <RightMenuSoloEvent
                setOpenRightSidebar={setOpenRightSidebar}
                interestedUsers={interestedUsers}
                openPopupMenu={openPopupMenu}
                setOpenPopupMenu={setOpenPopupMenu}
                setNotificationsMod={setNotificationsMod}
              />
            </Animated>
          )}

          {openNotificationsMod && (
            <Animated
              animationIn="fadeInRight"
              animationOut="fadeOutLeft"
              isVisible={true}
              animationInDuration={500}
              animationOutDuration={500}
            >
              <RightNotificationMenu
                setOpenRightSidebar={setOpenRightSidebar}
                openPopupMenu={openPopupMenu}
                setOpenPopupMenu={setOpenPopupMenu}
                setNotificationsMod={setNotificationsMod}
              />
            </Animated>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default EventDetails;
