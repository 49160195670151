import loupe from "../../../assets/img/loupe-icon.svg";

import { FiMenu } from "react-icons/fi";

import { Box, Stack } from "@mui/material";
import Popper from "@mui/material/Popper";
import Badge from "@mui/material/Badge";

import { Search, SearchIconWrapper, StyledInputBase } from "./styles";
import { useEffect, useState } from "react";
import SearchFilters from "./SearchFilters";
import { useSelector } from "react-redux";

const Searchbar = ({
  btnAction,
  searchTerm,
  handleChange,
  setFilterType,
  filterType,

  dreamersPage,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [badgeVisibility, setBadgeVisibility] = useState(true);
  const { general } = useSelector((state) => {
    // console.log(state); // Log the entire state
    return state.loggedUser.language;
  });
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  useEffect(() => {
    if (filterType !== "all") {
      setBadgeVisibility(false);
    } else {
      setBadgeVisibility(true);
    }
  }, [filterType]);

  return (
    <>
      <Search sx={{ display: "flex", alignItems: "center" }}>
        <SearchIconWrapper>
          <img src={loupe} alt="" />
        </SearchIconWrapper>
        <StyledInputBase
          placeholder={general?.searchOn}
          value={searchTerm}
          onChange={handleChange}
          inputProps={{ "aria-label": "Buscar" }}
        />
        <Stack
          direction="row"
          marginLeft="auto"
          paddingRight="10px"
          alignItems="center"
        >
          <span
            onClick={handleClick}
            style={{
              marginRight: "20px",
              color: "#8F92A1",
              fontWeight: "bold",
              lineHeight: "1rem",
              cursor: "pointer",
              verticalAlign: "center",
            }}
          >
            <Badge color="primary" variant="dot" invisible={badgeVisibility}>
              {general?.filters}
            </Badge>

            <Popper open={open} anchorEl={anchorEl} placement="bottom">
              <SearchFilters
                setFilterType={setFilterType}
                filterType={filterType}
              />
            </Popper>
          </span>
          <Box
            component="span"
            display={{ xs: "inline", lg: "none" }}
            onClick={btnAction}
            style={{ cursor: "pointer" }}
          >
            <FiMenu />
          </Box>
        </Stack>
      </Search>
    </>
  );
};

export default Searchbar;
