import { BiHomeAlt2, BiUser, BiExit } from "react-icons/bi";
import { FiSettings } from "react-icons/fi";
import { BsColumnsGap } from "react-icons/bs";

export const iconsList = [
  {
    icon: <BiHomeAlt2 size={24} />,
    url: "/home/maker",
    caption: "Inicio",
  },
  {
    icon: <BsColumnsGap size={24} />,
    url: "/home/dreamer/my-dreams",
    caption: "Mis sueños",
    // urlMaker: "/home/maker/social",
    // captionMaker: "Red social",
  },
  {
    icon: <BiUser size={24} />,
    url: "/home/maker/account",
    caption: "Perfil",
  },
  {
    icon: <FiSettings size={24} />,
    url: "/home/maker/notifications-config",
    caption: "Configuración",
  },
  {
    icon: <BiExit size={24} />,
    url: "#",
    caption: "Cerrar sesión",
  },
];

export const makerIconsList = [
  {
    icon: <BiHomeAlt2 size={24} />,
    url: "/home/maker",
    caption: "Inicio",
  },
  {
    icon: <BsColumnsGap size={24} />,
    url: "/home/maker/social",
    caption: "Red social",
  },
  {
    icon: <BiUser size={24} />,
    url: "/home/maker/account",
    caption: "Perfil",
  },
  {
    icon: <FiSettings size={24} />,
    url: "/home/maker/notifications-config",
    caption: "Configuración",
  },
  {
    icon: <BiExit size={24} />,
    url: "#",
    caption: "Cerrar sesión",
  },
];
