import React, { useState } from "react";
import {
  Dialog,
  Box,
  Stepper,
  Step,
  StepLabel,
  Button,
  useMediaQuery,
} from "@mui/material";

import SignUpFirstStep from "./signUpFirstStep/SignUpFirstStep";
import SignUpSecondStep from "./signUpSecondStep/SignUpSecondStep";

const SignUpModal = ({ open, handleOpen }) => {
  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const Step1Content = () => {
    return (
      <div style={{ borderRadius: "20px" }}>
        <SignUpFirstStep handleNext={handleNext} currentStep={activeStep} />
      </div>
    );
  };

  const Step2Content = () => {
    return (
      <div>
        <SignUpSecondStep handleBack={handleBack} handleClose={handleOpen} />
      </div>
    );
  };

  const steps = [
    { label: "Paso 1", content: <Step1Content /> },
    { label: "Paso 2", content: <Step2Content /> },
  ];

  return (
    <div>
      <Dialog
        fullWidth
        maxWidth="xl"
        sx={{ borderRadius: "20px" }}
        PaperProps={{
          bgcolor: "#fff",
          boxShadow: "none",
          borderRadius: "20px",
          width: "100%",
        }}
        open={open}
        onClose={() => {}}
      >
        <Box>{steps[activeStep].content}</Box>
      </Dialog>
    </div>
  );
};

export default SignUpModal;
