import {
  Box,
  Button,
  Card,
  Popper,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import CardContent from "@mui/material/CardContent";

import { FiCheck } from "react-icons/fi";
import { BiEnvelope, BiDotsVerticalRounded } from "react-icons/bi";
import { useEffect, useState } from "react";
import FriendsModal from "../profile/FriendsModal";
import { useSelector } from "react-redux";
import {
  checkUserIsAFollower,
  createFollower,
  createNotification,
  deleteFollower,
} from "../../../store/actions/userActions";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { callDeleteAlert } from "../../../customAlert/DeleteElement";
import { timestampCreador } from "../../../firebase/firebase-config";

import noImagePlaceHolder from "../../../assets/img/avatar-no-image.png";

const UserProfileCard = ({ friends, userData, general }) => {
  const isHeightUp864 = useMediaQuery("(min-height:864px)");
  const isDown1440 = useMediaQuery("(max-width:1439px)");

  const [openFriendsModal, setOpenFriendsModal] = useState(false);
  const [followId, setFollowId] = useState(false);
  const [isUserAFollower, setIsUserAFollower] = useState(false);
  const [followBtnVisibility, setFollowBtnVisibility] = useState(true);
  const [editBtnVisibility, setEditBtnVisibility] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const currentUser = useSelector((state) => state.loggedUser.data);
  const navigate = useNavigate();
  const location = useLocation();

  const { id: userPorfileId } = useParams();

  useEffect(() => {
    const checkFollowing = async () => {
      const result = await checkUserIsAFollower(userPorfileId, currentUser.id);
      if (result.exist) {
        setIsUserAFollower(result.exist);
        setFollowId(result.docId);
      }
    };

    checkFollowing();
  }, [isUserAFollower]);

  useEffect(() => {
    if (currentUser.id === userPorfileId) {
      setFollowBtnVisibility(false);
    }
  }, []);

  useEffect(() => {
    if (location.pathname.includes("profile-info")) {
      setEditBtnVisibility(true);
    }
  }, []);

  const startFollowingUser = () => {
    const today = new Date();
    const cdate = timestampCreador.fromDate(today);
    const followerData = { cdate, userRef: currentUser.id };
    let fullName = `${currentUser.name} `;

    if (currentUser.lastname) fullName = fullName + currentUser.lastname;

    const notificationData = {
      cdate,
      fromUserId: currentUser.id,
      fromUserName: fullName,
      isRead: false,
      subject: "A comenzado a seguirte",
      type: "follower",
    };

    createFollower(userPorfileId, followerData);
    createNotification(userPorfileId, notificationData);
    setIsUserAFollower(true);
  };

  const stopFollowingUser = async () => {
    await deleteFollower(userPorfileId, followId, currentUser.id);
    setIsUserAFollower(false);
  };

  return (
    <Card variant="outline" sx={{ borderRadius: "12px", padding: "25px 15px" }}>
      <CardContent>
        <Stack textAlign="center" gap={3}>
          <Stack gap={1}>
            <Box
              width={isDown1440 ? "168px" : "108px"}
              height={isDown1440 ? "168px" : "108px"}
              margin="0 auto"
            >
              <img
                src={userData?.imgURL || noImagePlaceHolder}
                alt=""
                width="100%"
                height="100%"
                style={{ borderRadius: "36px" }}
              />
            </Box>

            <Typography variant="h4" fontWeight="bold">
              {userData?.name}
            </Typography>
            <Typography variant="body2">{userData?.email}</Typography>

            {isUserAFollower ? (
              <Button
                variant="outlined"
                onClick={() =>
                  callDeleteAlert(
                    stopFollowingUser,
                    undefined,
                    "Ya no sigues este usuario",
                    `¿Dejar de seguir a ${userData?.name}?`
                  )
                }
              >
                {" "}
                <FiCheck size={18} /> Siguiendo
              </Button>
            ) : (
              <Button
                variant="outlined"
                onClick={startFollowingUser}
                sx={{ display: followBtnVisibility ? "block" : "none" }}
              >
                {general?.follow}
              </Button>
            )}
          </Stack>

          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            gap={3}
          >
            <Typography variant="body1">
              <span style={{ fontWeight: "bold" }}>
                {userData?.postsCount ?? 0}
              </span>{" "}
              {general?.posts}
            </Typography>
            <Typography variant="body1">
              <span style={{ fontWeight: "bold" }}>
                {userData?.followersCount ?? 0}
              </span>{" "}
              {general?.followers}
            </Typography>
          </Stack>

          <Button
            disableElevation
            variant="contained"
            onClick={() =>
              navigate(`/home/maker/account/edit/${currentUser.id}`)
            }
            sx={{
              borderRadius: "12px",
              bgcolor: "#53d769",
              width: "149px",
              display: editBtnVisibility ? "block" : "none",
              margin: "auto",
              py: "12px",
            }}
          >
            {general?.editProfile}
          </Button>
          <Stack
            gap={3}
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <span
              onClick={(event) => {
                setAnchorEl(anchorEl ? null : event.currentTarget);
              }}
              style={{
                border: "2px solid rgb(143, 146, 161, 0.2)",
                borderRadius: "12px",
                padding: "7px 7px",
                cursor: "pointer",
              }}
            >
              <BiDotsVerticalRounded size={18} />
              <Popper open={open} anchorEl={anchorEl}>
                <Box
                  sx={{
                    p: 1,
                    bgcolor: "background.paper",
                    borderRadius: "12px",
                    cursor: "pointer",
                    border: "1px solid rgba(0,0,0,0.75)",
                    //boxShadow: "1px 1px 5px 0px rgba(0,0,0,0.75)",
                    cursor: "pointer",
                  }}
                >
                  <small
                    onClick={() => navigate("/home/maker/profile-config")}
                    style={{ display: "block" }}
                  >
                    {general?.config}
                  </small>
                </Box>
              </Popper>
            </span>
          </Stack>
        </Stack>

        <Stack width="90%" margin="32px auto">
          <Typography variant="h6" fontWeight="bold">
            {general?.aboutUs}
          </Typography>
          <Typography variant="body1" sx={{ color: "#8F92A1" }}>
            {userData?.description}
          </Typography>
        </Stack>

        {/* {isHeightUp864 ? (
          <DreamerFriends friends={friends} />
        ) : (
          <Button
            variant="contained"
            sx={{ display: "block", m: "auto" }}
            onClick={() => setOpenFriendsModal(true)}
          >
            Ver amigos
          </Button>
        )} */}
      </CardContent>

      <FriendsModal
        open={openFriendsModal}
        setOpen={setOpenFriendsModal}
        friends={friends}
      />
    </Card>
  );
};

export default UserProfileCard;
