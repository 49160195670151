import { Box, CircularProgress, Stack } from "@mui/material";
import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";
import LocationDetails from "../locationDetails/LocationDetails";

const center = {
  lat: -3.745,
  lng: -38.523,
};

const EventMap = () => {
  const containerStyle = {
    minWidth: "100%",
    minHeight: "100%",
  };

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_KEY,
  });

  return (
    <>
      {isLoaded ? (
        <Stack width="100%" height="100%" gap={3}>
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={center}
            zoom={10}
          >
            {/* Child components, such as markers, info windows, etc. */}
            <></>
          </GoogleMap>
          {/* <Box>
            <LocationDetails />
          </Box> */}
        </Stack>
      ) : (
        <CircularProgress />
      )}
    </>
  );
};

export default EventMap;
