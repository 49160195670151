import { Avatar, Box, Stack, Typography, useMediaQuery } from "@mui/material";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Chip from "@mui/material/Chip";

import calendarIcon from "../../../assets/img/calendar-icon.svg";
import taskIcon from "../../../assets/img/task-icon.svg";
import avatar1 from "../../../assets/img/avatar-chat-1.png";
import avatar2 from "../../../assets/img/avatar-chat-2.png";

import hummingbird from "../../../assets/img/hummingbird-about-page.png";

const Hero = ({ aboutPage }) => {
  const isDown1600 = useMediaQuery("(max-width:1600px)");
  const isDown1420 = useMediaQuery("(max-width:1420px)");
  const isDown600 = useMediaQuery("(max-width:600px)");

  return (
    <Grid container sx={{ position: "relative" }}>
      <Grid
        item
        lg={6}
        xs={12}
        sx={{
          height: { md: "auto", lg: "80vh" },
        }}
      >
        <Box
          sx={{
            width: isDown1600 ? (isDown1420 ? "95%" : "80%") : "65%",
            marginTop: "70px",
          }}
        >
          <Typography
            variant={isDown600 ? "h4" : "h3"}
            component="h1"
            sx={{ fontWeight: "bold", color: "#fff" }}
          >
             {aboutPage?.mainTitle}:
         
          </Typography>
          <Typography
            variant={isDown600 ? "h5" : "h4"}
            component="h1"
            sx={{ fontWeight: "bold", color: "#fff" }}
          >
            {aboutPage?.title}
          </Typography>

          <Typography variant="h6" color={"#fff"} sx={{ mb: 4, mt: 3 }}>
            {aboutPage?.subtitle}
          </Typography>

          <Stack direction="row" sx={{ marginTop: "90px" }} spacing={4}>
            <Button variant="contained" sx={{ backgroundColor: "#8828ff" }}>
              {aboutPage.button}
            </Button>
          </Stack>
        </Box>
      </Grid>
      <Grid
        item
        lg={6}
        xs={12}
        justifyContent="center"
        alignItems="center"
        display="flex"
        position="relative"
        marginTop="70px"
        marginBottom={{ xs: "30px", sm: 0 }}
      >
        <div>
          <Card
            sx={{
              width: 350,
              borderRadius: "20px",
              position: isDown1420 ? "static" : "absolute",
              bottom: "140px",
              left: -40,
            }}
          >
            <CardContent sx={{ background: "#fff", padding: "20px" }}>
              <Box sx={{ backgroundColor: "#fafafa", padding: "30px" }}>
                <p className="fw-bold text-center"> {aboutPage?.text2}</p>
                <Stack direction="row" gap={3}>
                  <img src={calendarIcon} alt="" width="20px" />
                  <img src={taskIcon} alt="" width="20px" />
                </Stack>
                <Box
                  sx={{
                    backgroundColor: "#fff",
                    borderRadius: "8px",
                    padding: "10px",
                  }}
                >
                  <Stack direction="row">
                    <Chip label="SmileUp" sx={{ color: "#8828FF" }} />
                    <div style={{ marginLeft: "auto", position: "relative" }}>
                      <Avatar
                        alt="Persona"
                        src={avatar1}
                        sx={{
                          position: "absolute",
                          top: 0,
                          right: "80%",
                          zIndex: 100,
                        }}
                      />
                      <Avatar alt="Persona" src={avatar2} />
                    </div>
                  </Stack>
                  <p className="fw-bold">Unete a nuestra historia</p>
                  <Stack direction="row" gap={3}>
                    <p style={{ color: "#ADABB7" }}>11 Oct</p>
                    <p style={{ color: "#ADABB7" }}>3/8 Task</p>
                  </Stack>
                </Box>
              </Box>
            </CardContent>
          </Card>

          <Box
            sx={{
              position: "absolute",
              bottom: isDown1600 ? "120px" : "40px",
              left: isDown1600 ? "120px" : 0,
              // bottom: "120px",
              // left: "120px",
              width: isDown1600 ? "600px" : "1500px",
              zIndex: 100,
            }}
          >
            <img
              style={isDown1420 ? { display: "none" } : {}}
              src={hummingbird}
              alt="Colibri"
              width={isDown1600 ? "100%" : "auto"}
            />
          </Box>
        </div>
      </Grid>
    </Grid>
  );
};

export default Hero;
