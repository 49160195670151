import React, { useState } from "react";
import { useSelector } from "react-redux";
import { ReactComponent as Celebrate } from "../../../assets/img/celebrate-confirm-modal.svg";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { addMakerToDream } from "../../../store/actions/dreamers";
import { MdClose } from "react-icons/md";
import Colors from "../../../utils/Colors";

const ConfirmHelp = ({
  open,
  handleOpen,
  content,
  userId,
  makerId,
  dreamId,
  makerName,
  imgURL,
  refresh,
  transformDream,
}) => {
  const userData = useSelector((state) => state.loggedUser.data);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const [isSuccess, setIsSuccess] = useState(false);

  const navigateTo = (link) => {
    navigate(link);
  };

  const handleConfirm = async () => {
    if (isLoading) {
      return; // Prevent multiple clicks while loading
    }

    setIsLoading(true);

    if (!isSuccess) {
      try {
        const image = imgURL ? imgURL : "https://picsum.photos/400";

        await addMakerToDream(userId, dreamId, makerId, makerName, image);
        setIsSuccess(true);

        // Assuming refresh is needed after the success
        refresh();

        setTimeout(() => handleOpen(false), 2000);
      } catch (error) {
        console.error("Error joining dream:", error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleDeny = () => {
    handleOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={() => handleOpen(false)}
      maxWidth={"xs"}
      PaperProps={{
        sx: {
          maxWidth: "375px",
          borderRadius: "32px",
          minWidth: "375px",
        },
      }}
    >
      <Box
        sx={{ position: "absolute", right: 25, top: 15,cursor:'pointer' }}
        onClick={() => handleOpen(false)}
      >
        <MdClose size={28} color={Colors.primary} />
      </Box>
      <DialogTitle>
        <Stack textAlign="center" gap={2}>
          <Box width="70px" height="120px" margin="auto">
            <Celebrate width="100%" height="100%" />
          </Box>

          <div>
            <Typography fontWeight="bold" variant="h4">
              {transformDream?.thankyou}
            </Typography>
            <Typography fontWeight="bold" variant="h4">
              {userData?.name}!
            </Typography>
          </div>

          <DialogContent>
            {isLoading ? (
              <DialogContentText color="#A2A1A8" width="80%" margin="auto">
                <CircularProgress />
              </DialogContentText>
            ) : (
              <DialogContentText color="#A2A1A8" width="80%" margin="auto">
                {isSuccess ? transformDream?.success : content}
              </DialogContentText>
            )}
          </DialogContent>

          <DialogActions>
            <Grid container direction="row" gap={2} justifyContent={"center"}>
              <Button
                variant="contained"
                disableElevation
                onClick={handleConfirm}
                disabled={isLoading}
                sx={{ borderRadius: "10px", py: 1, background: "#7152F3" }}
              >
                {isSuccess ? transformDream?.goBack : transformDream?.confirm}
              </Button>
              {!isSuccess && (
                <Button
                  variant="outlined"
                  disableElevation
                  onClick={handleDeny}
                  sx={{ borderRadius: "10px", py: 1, borderColor: "#7152F3" }}
                >
                  {transformDream?.cancel}
                </Button>
              )}
            </Grid>
          </DialogActions>
        </Stack>
      </DialogTitle>
    </Dialog>
  );
};

export default ConfirmHelp;
