import {
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";

import envelope from "../../../assets/img/envelope-icon.svg";
import phone from "../../../assets/img/phone-icon.svg";
import bg from "../../../assets/img/lets-talk-bg.png";

const LetsTalkSection = ({ aboutPage }) => {
  const isDown1280 = useMediaQuery("(max-width:1280px)");
  const isDown899 = useMediaQuery("(max-width:899px)");
  const isDown540 = useMediaQuery("(max-width:540px)");

  return (
    <Box
      component="section"
      sx={{
        backgroundImage: `url(${bg})`,
        backgroundRepeat: "no-repeat",
        WebkitBackgroundSize: "100% 100%",
        height: { xs: "718px", sm: "70vh" },
        //height: isDown540 ? "70vh" : "50vh",
        paddingLeft: isDown899 ? "20px" : "",
        paddingRight: isDown899 ? "20px" : "",
        marginBottom: 0,
      }}
    >
      <Grid
        container
        display="flex"
        alignItems="center"
        justifyContent="flex-end"
        height="100%"
      >
        <Grid
          item
          sx={{ color: "#fff" }}
          md={6}
          xs={12}
          textAlign={isDown899 ? "center" : ""}
        >
          <Typography variant="h3" fontWeight="bold">
            {aboutPage?.section6.title}
          </Typography>
          <Typography
            variant="body1"
            margin={isDown899 && "auto"}
            width={isDown1280 ? "60%" : "35%"}
            lineHeight="28px"
          >
            {aboutPage?.section6.text}
          </Typography>
        </Grid>
        <Grid item md={6} xs={12}>
          <Grid
            container
            spacing={3}
            display="flex"
            justifyContent={isDown899 ? "center" : "flex-end"}
          >
            <Grid item>
              <Card
                variant="outline"
                sx={{
                  borderRadius: "20px",
                  border: "1px solid #fff",
                  width: isDown540 ? "100%" : "387px",
                  backgroundColor: "rgba(255, 255, 255, 0.75)",
                }}
              >
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item>
                      <Box
                        sx={{
                          backgroundColor: "#fff",
                          borderRadius: "50%",
                          padding: "12px",
                        }}
                      >
                        <img src={envelope} alt="" width="40px" />
                      </Box>
                    </Grid>
                    <Grid item>
                      <Typography
                        variant="h5"
                        component="h4"
                        fontWeight="bold"
                        mb={1}
                      >
                        Email
                      </Typography>
                      <Typography variant="body1" sx={{ color: "#666666" }}>
                        hola@smileup.com
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid item>
              <Card
                variant="outline"
                sx={{
                  borderRadius: "20px",
                  border: "1px solid #fff",
                  width: isDown540 ? "100%" : "387px",
                  backgroundColor: "rgba(255, 255, 255, 0.75)",
                }}
                md={6}
              >
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item>
                      <Box
                        sx={{
                          backgroundColor: "#fff",
                          borderRadius: "50%",
                          padding: "12px",
                        }}
                      >
                        <img src={phone} alt="" />
                      </Box>
                    </Grid>
                    <Grid item>
                      <Typography
                        variant="h5"
                        component="h4"
                        fontWeight="bold"
                        mb={1}
                      >
                        WhatsAPP
                      </Typography>
                      <Typography variant="body1" sx={{ color: "#666666" }}>
                        +506 8822-2112
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default LetsTalkSection;
