import { Box, Button, Stack, Typography } from "@mui/material";

import { FiChevronRight } from "react-icons/fi";

import { useState } from "react";
import NextEventCard from "../dreamerPage/nextEventCard/NextEventCard";
import TopUserActions from "../dreamerPage/topUserActions/TopUserActions";
import { useSelector } from "react-redux";

const RightMenuEvents = ({
  userPhoto,
  //openPopupMenu,
  //setOpenPopupMenu,
  setNotificationsMod,
  events,
  setOpenRightSidebar,
}) => {
  const [openPopupMenu, setOpenPopupMenu] = useState(false);
  const nextEvents = events.slice(0, 3);
  const { general } = useSelector((state) => state.loggedUser.language);
  return (
    <Box
      sx={{
        backgroundColor: "#1e1f20",
        height: "100vh",
        borderTopLeftRadius: "32px",
        borderBottomLeftRadius: "32px",
        //padding: "15px",
        padding: "20px 40px",
        overflow: "scroll",
      }}
    >
      <Stack direction="row">
        <Box ml="auto" my={1}>
          <TopUserActions
            openPopupMenu={openPopupMenu}
            setOpenPopupMenu={setOpenPopupMenu}
            setNotificationsMod={setNotificationsMod}
            setOpenRightSidebar={setOpenRightSidebar}
          />
        </Box>
      </Stack>

      <Typography variant="h4" sx={{ color: "#fff" }} width="60%">
        {general?.comingSoon}
      </Typography>

      <Stack my={3}>
        {nextEvents.map((event) => {
          return <NextEventCard eventData={event} />;
        })}
      </Stack>

      <Button
        variant="text"
        sx={{ textTransform: "uppercase", color: "#8F92A1" }}
      >
        {general?.viewMore} <FiChevronRight size={21} />
      </Button>
    </Box>
  );
};

export default RightMenuEvents;
