import { Box, Button, Modal, useMediaQuery, useTheme } from "@mui/material";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import registerBg from "../../assets/img/register-morpho.jpg";

import { RegisterForm } from "../registerForm/RegisterForm";

const RegisterModal = ({
  //handleOpen,
  open,
  handleOpenLogIn,
  handleOpenPassRecovery,
  handleOpenSignUpSteps,
  handleOpenSignIn,
  handleOpenSignInStep,
  handleOpenSignUp,
  register,
}) => {
  const theme = useTheme();
  const isDown1200 = useMediaQuery("(max-width:1200px)");

  return (
    <Modal open={open} onClose={() => handleOpenSignUp(false)}>
      <Box
        className="d-flex align-items-center bg-white"
        sx={{
          position: "absolute",
          // width: isDown1200 ? "50%" : "85%",
          width: "85%",
          maxWidth: 1200,
          minWidth: 325,
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          borderRadius: "20px",
          maxHeight: "800px",
          overflow: "hidden",
          [theme.breakpoints.down("lg")]: {
            width: "50%",
          },
          [theme.breakpoints.down("md")]: {
            width: "85%",
          },
        }}
      >
        <Grid container sx={{ height: "100%" }}>
          <Grid
            item
            lg={6}
            sx={{
              background: `url(${registerBg})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundPosition: "top",

              borderRadius: "20px",
              position: "relative",
            }}
          >
            <Box
              position="absolute"
              sx={{
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                textAlign: "center",
                display: isDown1200 && "none",
              }}
            >
              <Typography sx={{ color: "#fff", mb: 2 }} variant="h5">
                {register?.hello}
              </Typography>
              <Typography sx={{ color: "#fff", mb: 1 }} variant="body1">
                {register?.registerText}
              </Typography>
              <Button
                variant="contained"
                onClick={() => {
                  handleOpenSignUp(false);
                  handleOpenLogIn(true);
                }}
                sx={{
                  borderRadius: "8px",
                  padding: "10px 15px",
                  width: "100%",
                  backgroundColor: "#0659fd",
                }}
              >
                {register?.login}
              </Button>
            </Box>
          </Grid>
          <Grid item lg={5} md={12} sx={{ padding: "40px 20px" }}>
            <RegisterForm
              handleOpenLogIn={handleOpenLogIn}
              handleOpenSignIn={handleOpenSignIn}
              handleOpenSignInStep={handleOpenSignInStep}
              handleOpenSignUp={handleOpenSignUp}
              handleOpenSignUpSteps={handleOpenSignUpSteps}
              handleOpenPassRecovery={handleOpenPassRecovery}
              register={register}
              // handleOpen={handleOpen}
              // handleOpenLogIn={handleOpenLogIn}
              // handleOpenPassRecovery={handleOpenPassRecovery}
              // handleOpenSignUpSteps={handleOpenSignUpSteps}
            />
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default RegisterModal;
