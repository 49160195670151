import { useSelector } from "react-redux";
import { ReactComponent as Celebrate } from "../../../assets/img/celebrate-confirm-modal.svg";
import { Box, Button, Modal, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

const ConfirmDreamModal = ({ open, handleOpen, content }) => {
  const userData = useSelector((state) => state.loggedUser.data);
  const navigate = useNavigate();

  const navigateTo = (link) => {
    navigate(link);
  };

  const handleConfirm = () => {
    setTimeout(() => {
      handleOpen(false);
      navigateTo("/home/dreamer/my-dreams");
    }, 1000);
  };
  return (
    <Modal
      open={open}
      onClose={() => handleOpen(false)}
      sx={{ borderRadius: "30px" }}
    >
      <div
        className="d-flex align-items-center bg-white"
        style={{
          position: "absolute",
          //width: isDown1200 ? "50%" : "85%",
          maxWidth: 1200,
          minWidth: 325,
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          borderRadius: "20px",
          padding: "20px",
        }}
      >
        <Stack textAlign="center" gap={2} width="100%">
          <Box width="70px" height="120px" margin="auto">
            <Celebrate width="100%" height="100%" />
          </Box>

          <div>
            <Typography fontWeight="bold" variant="h5">
              Muchas gracias
            </Typography>
            <Typography fontWeight="bold" variant="h5">
              {userData?.name}
            </Typography>
          </div>
          <Typography color="#A2A1A8" variant="body2" width="80%" margin="auto">
            {content}
          </Typography>
          <Button
            variant="contained"
            disableElevation
            onClick={handleConfirm}
            sx={{ borderRadius: "10px", py: 1, background: "#7152F3" }}
          >
            Confirmar
          </Button>
        </Stack>
      </div>
    </Modal>
  );
};

export default ConfirmDreamModal;
