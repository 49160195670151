import { types } from "../types/types";
import { addEvents } from "../utils/events";

const initialState = {
  events: [],
  monthlyEvents: [],
  weekEvents: [],
  isLoading: false,
};

export const eventsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.getEvents:
      return {
        ...state,
        events: addEvents(state.events, action.payload.events),
      };

    case types.getEventsByMonth:
      return {
        ...state,
        monthlyEvents: addEvents(state.monthlyEvents, action.payload.events),
      };

    case types.getEventsByWeek:
      return {
        ...state,
        weekEvents: addEvents(state.weekEvents, action.payload.events),
      };

    case types.isLoadingEvents:
      return {
        ...state,
        isLoading: action.payload.isLoading,
      };

    default:
      return state;
  }
};
