import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Typography,
} from "@mui/material";

const ConfigMyAccount = ({
  handleBlur,
  handleChange,
  values,
  touched,
  errors,
}) => {
  return (
    <>
      <Typography variant="h4" mb={4}>
        Mi cuenta
      </Typography>
      <Grid container spacing={4}>
        <Grid item sm={6} xs={12}>
          <FormControl fullWidth>
            <label style={{ fontWeight: "bold" }}>Idioma</label>
            <Select
              sx={{ borderRadius: "50px" }}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.language}
              name="language"
            >
              <MenuItem value={"es"}>Español</MenuItem>
              <MenuItem value={"en"}>Inglés</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item sm={6} xs={12}>
          <FormControl fullWidth>
            <label style={{ fontWeight: "bold" }}>Moneda</label>
            <Select
              sx={{ borderRadius: "50px" }}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.currency}
              name="currency"
            >
              <MenuItem value={"CRC"}>Colón Costarricense</MenuItem>
              <MenuItem value={"USD"}>Dolar Estadounidense</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      <Grid container spacing={4} mt={2}>
        <Grid item sm={4} xs={12}>
          <FormControl>
            <label style={{ fontWeight: "bold" }}>Mostrar mi email</label>

            <RadioGroup
              //defaultValue={true}
              name="showEmail"
              onChange={handleChange}
              value={values.showEmail}
            >
              <FormControlLabel value={"Si"} control={<Radio />} label="Si" />
              <FormControlLabel value={"No"} control={<Radio />} label="No" />
            </RadioGroup>

            <FormHelperText error id="outlined-showEmail">
              {touched.showEmail && errors.showEmail ? errors.showEmail : " "}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item sm={4} xs={12}>
          <FormControl>
            <label style={{ fontWeight: "bold" }}>Mostrar Perfiles</label>
            <RadioGroup
              //defaultValue={true}
              name="showProfiles"
              onChange={handleChange}
              value={values.showProfiles}
            >
              <FormControlLabel value={"Si"} control={<Radio />} label="Si" />
              <FormControlLabel value={"No"} control={<Radio />} label="No" />
            </RadioGroup>

            <FormHelperText error id="outlined.showProfiles">
              {touched.showProfiles && errors.showProfiles
                ? errors.showProfiles
                : " "}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item sm={4} xs={12}>
          <FormControl>
            <label style={{ fontWeight: "bold" }}>
              Mostrar Perfiles Sociales
            </label>

            <RadioGroup
              //defaultValue={true}
              name="showSocialProfiles"
              onChange={handleChange}
              value={values.showSocialProfiles}
            >
              <FormControlLabel value={"Si"} control={<Radio />} label="Si" />
              <FormControlLabel value={"No"} control={<Radio />} label="No" />
            </RadioGroup>

            <FormHelperText error id="outlined-showSocialProfiles">
              {touched.showSocialProfiles && errors.showSocialProfiles
                ? errors.showSocialProfiles
                : " "}
            </FormHelperText>
          </FormControl>
        </Grid>
      </Grid>
    </>
  );
};

export default ConfigMyAccount;
