const useFilterDreams = (dreams, activeFilter) => {
  let sortedDreams;
  let copyDreamsArr = dreams.slice();

  //dreams.sort((dream) => orderingConditions.some(() => ))

  switch (activeFilter) {
    case "recent":
      sortedDreams = copyDreamsArr.sort((firstValue, secondValue) => {
        const result = secondValue.cdate - firstValue.cdate;

        return result;
      });

      return sortedDreams;

    case "old":
      sortedDreams = copyDreamsArr.sort((firstValue, secondValue) => {
        const result = firstValue.cdate - secondValue.cdate;

        return result;
      });

      return sortedDreams;

    case "alphabetic":
      sortedDreams = copyDreamsArr.sort((firstValue, secondValue) => {
        const result = firstValue.name.localeCompare(secondValue.name);

        return result;
      });

      return sortedDreams;
    default:
      return dreams;
  }
};

export default useFilterDreams;
