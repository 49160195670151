import { Stack } from "@mui/material";
import { BiDotsVerticalRounded } from "react-icons/bi";

import noImagePlaceholder from "../../../assets/img/avatar-no-image.png";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useState } from "react";
import { useEffect } from "react";

const PostHeader = ({ postData, setPostOwnerData, postOwnerData }) => {
  const [profileURL, setProfileURL] = useState("");

  const [userFullName, setUserFullName] = useState("");

  const navigate = useNavigate();

  const userData = useSelector((state) => state.loggedUser.data);
  const dreamers = useSelector((state) => state.dreamers.dreamers);
  const location = useLocation();

  useEffect(() => {
    const setPostOwner = () => {
      const postOwner = dreamers.find(
        (dreamer) => dreamer.id === postData.userOwnerId
      );
      setPostOwnerData(postOwner);

      const fullName = `${postOwner?.name ?? ""} ${postOwner?.lastname ?? ""}`;
      setUserFullName(fullName);
    };

    if (dreamers) {
      setPostOwner();
    }
  }, [dreamers]);

  useEffect(() => {
    if (userData.id === postData.userOwnerId) {
      if (location.pathname.includes("maker")) {
        //go to maker profile
        const url = `/home/maker/account/${postData.userOwnerId}`;
        setProfileURL(url);
      } else {
        //go to dreamer profile
        const url = "/home/dreamer/account";
        setProfileURL(url);
      }
    } else {
      if (location.pathname.includes("maker")) {
        //go to maker profile
        const url = `/home/maker/profile/${postData.userOwnerId}`;
        setProfileURL(url);
      } else {
        //go to dreamer profile

        const url = `/home/dreamer/profile/${postData.userOwnerId}`;
        setProfileURL(url);
      }
    }
  }, [postData, userData]);

  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center">
      <Stack direction="row" gap={1} alignItems="center">
        <span
          style={{ width: "40px", height: "40px", cursor: "pointer" }}
          onClick={() => navigate(profileURL)}
        >
          <img
            src={postOwnerData?.imgURL || noImagePlaceholder}
            alt=""
            style={{
              objectFit: "cover",
              borderRadius: "12px",
              width: "100%",
              height: "100%",
            }}
          />
        </span>
        <div>
          <span
            style={{
              fontWeight: "bold",
              display: "block",
            }}
          >
            {userFullName}
            {/* {postData?.name} */}
          </span>
          <small>
            {postData?.cdate?.toDate().toLocaleDateString("CR", {
              year: "numeric",
              month: "long",
              day: "numeric",
            })}
          </small>
        </div>
      </Stack>

      <BiDotsVerticalRounded color="#8F92A1" size={22} />
    </Stack>
  );
};

export default PostHeader;
