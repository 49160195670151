import React, { useEffect, useState } from "react";
import { Stack, Typography } from "@mui/material";

const DreamTimer = ({ time, cdate,general,transformDream }) => {
  const calculateTimeLeft = () => {
    const currentDate = new Date();

    if (!cdate || !cdate.seconds) {
      // If cdate is undefined or cdate.seconds is undefined, return expired
      return { days: 0, hours: 0, minutes: 0, seconds: 0 };
    }

    const targetDate = new Date(
      cdate.seconds * 1000 + (cdate.nanoseconds || 0) / 1000000
    );
    targetDate.setFullYear(targetDate.getFullYear() + Math.floor(time / 12));
    targetDate.setMonth(targetDate.getMonth() + (time % 12));

    const timeDifference = targetDate - currentDate;

    if (timeDifference <= 0) {
      // The time has already passed
      return { days: 0, hours: 0, minutes: 0, seconds: 0 };
    }

    // Convert milliseconds to days, hours, minutes, and seconds
    const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor(
      (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
    );
    const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

    return { days, hours, minutes, seconds };
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    // Cleanup the interval on component unmount
    return () => clearInterval(interval);
  }, [cdate, time]);

  const isExpired =
    timeLeft.days === 0 &&
    timeLeft.hours === 0 &&
    timeLeft.minutes === 0 &&
    timeLeft.seconds === 0;

  return (
    <Stack>
      <Typography variant="body1">{transformDream?.timeAvailable}</Typography>
      <Stack direction="row" gap={3}>
        {isExpired ? (
          <Typography variant="body1">Expirado</Typography>
        ) : (
          <>
            <Stack>
              <Typography fontWeight="bold" fontSize="24px">
                {String(timeLeft.days).padStart(2, "0")}
              </Typography>
              <Typography>{transformDream?.days}</Typography>
            </Stack>
            <Stack>
              <Typography fontWeight="bold" fontSize="24px">
                {String(timeLeft.hours).padStart(2, "0")}
              </Typography>
              <Typography>{transformDream?.hours}</Typography>
            </Stack>
            <Stack>
              <Typography fontWeight="bold" fontSize="24px">
                {String(timeLeft.minutes).padStart(2, "0")}
              </Typography>
              <Typography>{transformDream?.minutes}</Typography>
            </Stack>
            <Stack>
              <Typography fontWeight="bold" fontSize="24px">
                {String(timeLeft.seconds).padStart(2, "0")}
              </Typography>
              <Typography>{transformDream?.seconds}</Typography>
            </Stack>
          </>
        )}
      </Stack>
    </Stack>
  );
};

export default DreamTimer;
