import { Box, Stack, Typography } from "@mui/material";

import "./truncate.scss";

const VideoDescription = ({ videoData }) => {
  const calculateTimeSinceVideo = () => {
    const today = new Date();
    const videoDate = videoData.cdate;
    const timeReference = videoDate?.toDate() || new Date();
    const timeDifference = today - timeReference;

    if (timeDifference < 1000) {
      return "Justo ahora";
    } else if (timeDifference < 60 * 1000) {
      return Math.floor(timeDifference / 1000) + " segundos"; // Seconds ago
    } else if (timeDifference < 60 * 60 * 1000) {
      return Math.floor(timeDifference / (60 * 1000)) + " minutos"; // Minutes ago
    } else if (timeDifference < 24 * 60 * 60 * 1000) {
      return Math.floor(timeDifference / (60 * 60 * 1000)) + " horas"; // Hours ago
    } else {
      return Math.floor(timeDifference / (24 * 60 * 60 * 1000)) + " días"; // Days ago
    }
  };

  const timeSinceVideo = calculateTimeSinceVideo();

  return (
    <Stack minWidth={{ xs: "100px", sm: "200px" }} justifyContent="center">
      {/* <Typography variant="h6" fontWeight="bold">
        {videoData.name}
      </Typography> */}
      <Box>
        <Typography className="content" variant="h6" fontWeight="bold">
          {videoData.description}
        </Typography>
      </Box>

      <Typography variant="body1" color="#8F92A1">
        Hace {timeSinceVideo}
      </Typography>
    </Stack>
  );
};

export default VideoDescription;
