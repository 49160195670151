import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";

import CauseCard from "../causeCard/CauseCard";

import causesBg from "../../../assets/img/causes-bg.png";

import ShelterCause from "../../../assets/img/shelter-causes.png";
import HealthCause from "../../../assets/img/health-causes.png";
//import AnimalProtectionCause from "../../../assets/img/animal-protection-causes.svg";
import AnimalProtectionCause from "../../../assets/img/animal-protection-causes.png";
import EnviromentCause from "../../../assets/img/environment-causes.png";
import ArtCause from "../../../assets/img/art-causes.png";
import SportsCause from "../../../assets/img/sport-cause.png";
import Morpho from "../../../assets/img/morpho.png";
import { useEffect, useState } from "react";

const CausesSection = ({ landing }) => {
  const theme = useTheme();
  //const [spacingEnhancer, setSpacingEnhacer] = useState(1);
  const isDown1490 = useMediaQuery("(max-width:1490px)", { noSsr: true });
  const isXXL = useMediaQuery("(min-width:1490px)", { noSsr: true });
  const isDown1400 = useMediaQuery("(max-width:1400px)", { noSsr: true });
  const isDown1300 = useMediaQuery("(max-width:1300px)", { noSsr: true });
  const isDown1200 = useMediaQuery("(max-width:1200px)", { noSsr: true });
  const isDown1100 = useMediaQuery("(max-width:1100px)", { noSsr: true });
  const isDown1000 = useMediaQuery("(max-width:1000px)", { noSsr: true });
  const isDown950 = useMediaQuery("(max-width:950px)", { noSsr: true });
  const isDown750 = useMediaQuery("(max-width:750px)", { noSsr: true });
  const isDown720 = useMediaQuery("(max-width:720px)", { noSsr: true });
  const isDown700 = useMediaQuery("(max-width:700px)", { noSsr: true });
  const isUp701 = useMediaQuery("(min-width:701px)", { noSsr: true });

  const [spacingEnhancer, setSpacingEnhacer] = useState(() => {
    const storedSpacing = localStorage.getItem("spacingEnhancer");
    return storedSpacing ? parseInt(storedSpacing, 10) : 1;
  });

  const cardsFirstGrid = [
    {
      title: landing?.causeSection.tags.shelters.name,
      num: 12,
      component: <img src={ShelterCause} alt="" />,
    },
    {
      title: landing?.causeSection.tags.wellness.name,
      num: 72,
      component: <img src={HealthCause} alt="" />,
    },
    {
      title: landing?.causeSection.tags.animals.name,
      num: 81,
      component: <img src={AnimalProtectionCause} alt="" />,
    },
  ];

  const cardsFirst950Grid = [
    {
      title: landing?.causeSection.tags.shelters.name,
      num: 12,
      component: <img src={ShelterCause} alt="" />,
    },
    {
      title: landing?.causeSection.tags.wellness.name,
      num: 72,
      component: <img src={HealthCause} alt="" />,
    },
    {
      title: landing?.causeSection.tags.animals.name,
      num: 81,
      component: <img src={AnimalProtectionCause} alt="" />,
    },

    {
      title: landing?.causeSection.tags.environment.name,
      num: 98,
      component: <img src={EnviromentCause} alt="" />,
    },
  ];

  const cardsSecond950Grid = [
    {
      title: landing?.causeSection.tags.arts.name,
      num: 83,
      component: <img src={ArtCause} alt="" />,
    },
    {
      title: landing?.causeSection.tags.sports.name,
      num: 23,
      component: <img src={SportsCause} alt="" />,
    },
  ];

  const cardsSecondGrid = [
    {
      title: landing?.causeSection.tags.environment.name,
      num: 98,
      component: <img src={EnviromentCause} alt="" />,
    },
    {
      title: landing?.causeSection.tags.arts.name,
      num: 83,
      component: <img src={ArtCause} alt="" />,
    },
    {
      title: landing?.causeSection.tags.sports.name,
      num: 23,
      component: <img src={SportsCause} alt="" />,
    },
    {
      title: landing?.causeSection.tags.education.name,
      num: 23,
      component: <img src={SportsCause} alt="" />,
    },
  ];

  const cardsWholeGrid = [
    {
      title: landing?.causeSection.tags.shelters.name,
      num: 12,
      component: <img src={ShelterCause} alt="" />,
    },
    {
      title: landing?.causeSection.tags.wellness.name,
      num: 72,
      component: <img src={HealthCause} alt="" />,
    },
    {
      title: landing?.causeSection.tags.animals.name,
      num: 81,
      component: <img src={AnimalProtectionCause} alt="" />,
    },

    {
      title: landing?.causeSection.tags.arts.name,
      num: 83,
      component: <img src={ArtCause} alt="" />,
    },
    {
      title: landing?.causeSection.tags.sports.name,
      num: 23,
      component: <img src={SportsCause} alt="" />,
    },
  ];

  useEffect(() => {
    const handleWindowResize = () => {
      //console.log("refresh");
      const windowWidth = window.innerWidth;
      let newSpacing = 1;

      if (windowWidth < 1550) {
        newSpacing = 18;
      } else if (windowWidth < 1650) {
        newSpacing = 18;
      } else if (windowWidth < 1725) {
        newSpacing = 15;
      } else if (windowWidth < 1820) {
        newSpacing = 10;
      }

      setSpacingEnhacer(newSpacing);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  useEffect(() => {
    localStorage.setItem("spacingEnhancer", spacingEnhancer.toString());
  }, [spacingEnhancer]);

  return (
    <Box
      component="section"
      sx={{
        backgroundImage: `url(${causesBg})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        height: isDown1400
          ? isDown950
            ? isDown700
              ? "2500px"
              : "1600px"
            : "1200px"
          : isXXL
          ? "100%"
          : "100%",

        // minHeight: "85vh",
        position: "relative",
        zIndex: 400,
        marginBottom: 0,
        flexGrow: 1,
        paddingLeft: isDown1490 ? (isDown1400 ? "90px" : 0) : "",

        [theme.breakpoints.up("lg")]: {
          pb: 10,
        },
      }}
    >
      <Box
        component={"img"}
        src={Morpho}
        style={{ position: "absolute", top: "-150px" }}
      />

      <Box
        sx={{
          width: "100%",
          paddingTop: isDown1400 ? "90px" : "140px",
          position: "relative",
          left: isDown1400
            ? isDown1300
              ? isDown1100
                ? isDown1000
                  ? isDown750
                    ? isDown720
                      ? isDown700
                        ? 0
                        : "-17%"
                      : "-10%"
                    : "-3%"
                  : "-1%"
                : "2%"
              : "5%"
            : "",
        }}
      >
        {!isDown1400 && (
          <Box sx={{ position: "absolute", left: "5%" }}>
            <Typography variant="h3" color="#fff" className="py-4 fw-bold">
              {landing?.causeSection?.text1}
            </Typography>
            <Typography variant="body1" color="#fff" sx={{ width: "50%" }}>
              {landing?.causeSection?.text2}
            </Typography>
          </Box>
        )}

        {isDown1400 && (
          <Box
            sx={{
              mt: "30px",
              width: "90%",
              mb: isDown950 ? (isDown750 ? "40%" : "20%") : "5%",
              ml: isDown1400 ? (isDown1100 ? "60px" : "30px") : "45px",
              //ml: isDown1100 ? "45px" : "60px",
            }}
          >
            <Typography
              variant="h3"
              color="#fff"
              className="fw-bold"
              textAlign="center"
            >
              {landing?.causeSection.text1}
            </Typography>
            <Typography variant="body1" color="#fff" textAlign="center">
              {landing?.causeSection.text2}
            </Typography>
          </Box>
        )}

        <Grid
          container
          //direction="row"
          //alignItems="end"
          justifyContent="center" //end
          display={"flex"}
          spacing={isDown1400 ? (isDown750 ? (isDown720 ? 30 : 20) : 10) : 36}
          marginBottom={3}
        >
          {!isDown1400 && <Grid item lg="2"></Grid>}
          {!isDown950 &&
            cardsFirstGrid.map((cardDetail, index) => {
              return (
                <Grid
                  item
                  lg={isDown1400 ? 4 : 2}
                  md={isDown950 ? 5 : 4}
                  sm={isDown700 ? 12 : 5}
                  key={index}
                >
                  <CauseCard
                    key={cardDetail.num}
                    title={cardDetail.title}
                    causesNum={cardDetail.num}
                    illustration={cardDetail.component}
                  />
                </Grid>
              );
            })}

          {isDown950 &&
            isUp701 &&
            cardsFirst950Grid.map((cardDetail, index) => {
              return (
                <Grid
                  item
                  lg={isDown1400 ? 4 : 2}
                  md={isDown950 ? 5 : 4}
                  sm={isDown700 ? 12 : 5}
                  //sm={12}
                  marginBottom={"40px"}
                  sx={{ paddingTop: "0 !important" }}
                  //sx={index > 1 && index < 4 && { paddingTop: "0 !important" }}
                >
                  <CauseCard
                    key={cardDetail.num}
                    title={cardDetail.title}
                    causesNum={cardDetail.num}
                    illustration={cardDetail.component}
                  />
                </Grid>
              );
            })}

          {isDown700 &&
            cardsFirst950Grid.map((cardDetail, index) => {
              return (
                <Grid
                  item
                  lg={isDown1400 ? 4 : 2}
                  md={isDown950 ? 5 : 4}
                  sm={isDown700 ? 12 : 5}
                  display="flex"
                  justifyContent="center"
                  marginBottom={"40px"}
                  key={index}
                  sx={index > 0 && { paddingTop: "0 !important" }}
                >
                  <CauseCard
                    key={cardDetail.num}
                    title={cardDetail.title}
                    causesNum={cardDetail.num}
                    illustration={cardDetail.component}
                  />
                </Grid>
              );
            })}
        </Grid>

        <Grid
          container
          display={"flex"}
          justifyContent="center"
          spacing={isDown1400 ? (isDown750 ? (isDown720 ? 30 : 20) : 10) : 36}
        >
          {!isDown950 &&
            cardsSecondGrid.map((cardDetail, index) => {
              return (
                <Grid
                  item
                  lg={isDown1400 ? 4 : 2}
                  md={isDown950 ? 5 : 4}
                  sm={isDown700 ? 12 : 5}
                  key={index}
                >
                  <CauseCard
                    key={cardDetail.num}
                    title={cardDetail.title}
                    causesNum={cardDetail.num}
                    illustration={cardDetail.component}
                  />
                </Grid>
              );
            })}

          {isDown950 &&
            isUp701 &&
            cardsSecond950Grid.map((cardDetail, index) => {
              return (
                <Grid
                  item
                  lg={isDown1400 ? 4 : 2}
                  md={isDown950 ? 5 : 4}
                  sm={isDown700 ? 12 : 5}
                  key={index}
                >
                  <CauseCard
                    key={cardDetail.num}
                    title={cardDetail.title}
                    causesNum={cardDetail.num}
                    illustration={cardDetail.component}
                  />
                </Grid>
              );
            })}

          {isDown700 &&
            cardsSecond950Grid.map((cardDetail, index) => {
              return (
                <Grid
                  item
                  lg={isDown1400 ? 4 : 2}
                  md={isDown950 ? 5 : 4}
                  sm={isDown700 ? 12 : 5}
                  sx={
                    index === cardsSecond950Grid.length - 1 && {
                      paddingTop: "40px !important",
                    }
                  }
                  display="flex"
                  justifyContent="center"
                  key={index}
                >
                  <CauseCard
                    key={cardDetail.num}
                    title={cardDetail.title}
                    causesNum={cardDetail.num}
                    illustration={cardDetail.component}
                  />
                </Grid>
              );
            })}

          {/* <Grid
            item
            lg={isDown1400 ? 4 : 2}
            md={isDown950 ? 5 : 4}
            sm={isDown700 ? 12 : 5}
            //sm={12}
            display={isDown700 && "flex"}
            justifyContent={isDown700 && "center"}
            //marginTop={isDown1000 && "40px"}
            sx={
              isDown1400 ? { paddingTop: "40px !important" } : {} //{ paddingTop: "160px !important" }
            }
          >
            <Card
              sx={{
                width: "270px",
                height: "270px",
                background: "#C08DFF",
                borderRadius: "15px",
              }}
            >
              <CardContent
                sx={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexWrap: "wrap",
                }}
              >
                <div>
                  <Typography color="#fff">
                    <span style={{ fontSize: "22px" }}>50</span>
                    {landing?.causeSection.linkText}
                  </Typography>{" "}
                </div>
              </CardContent>
            </Card>
          </Grid> */}
        </Grid>
      </Box>
    </Box>
  );
};

export default CausesSection;
