import { Box, Card, Typography } from "@mui/material";
import CardContent from "@mui/material/CardContent";

const FinancialCard = ({ iconSource, title, content }) => {
  return (
    <Card variant="outline" sx={{ width: "286px", borderRadius: "20px" }}>
      <CardContent>
        <Box display="flex" justifyContent="center">
          <Box
            sx={{
              backgroundColor: "#F8F2FF",
              borderRadius: "50%",
              width: "72px",
              height: "72px",
              padding: "15px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            {" "}
            <img src={iconSource} alt="" />
          </Box>
        </Box>

        <Typography textAlign="center" fontWeight="bold">
          {title}
        </Typography>
        <Typography textAlign="center">{content}</Typography>
      </CardContent>
    </Card>
  );
};

export default FinancialCard;
