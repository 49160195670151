import { Box, Stack, Typography } from "@mui/material";
import PopupProfileConfig from "../dreamerPage/popupProfileConfig/PopupProfileConfig";
import { Animated } from "react-animated-css";

import CloseIcon from "@mui/icons-material/Close";
import NotificationItem from "../dreamerPage/notificationItem/NotificationItem";

import noImagePlaceholder from "../../assets/img/avatar-no-image.png";
import { useSelector } from "react-redux";

const RightNotificationMenu = ({
  openPopupMenu,
  setOpenPopupMenu,
  setNotificationsMod,
}) => {
  const { general } = useSelector((state) => state.loggedUser.language);

  // const notifications = [
  //   { type: "like", isRead: true },
  //   { type: "friendRequest", isRead: false },
  //   { type: "like", isRead: false },
  //   { type: "like", isRead: true },
  // ];

  const userData = useSelector((state) => state.loggedUser.data);
  const { notifications } = useSelector((state) => state.loggedUser);
  return (
    <Box
      sx={{
        backgroundColor: "#79d0f1",
        height: "100vh",
        width: "100%",
        borderTopLeftRadius: "32px",
        borderBottomLeftRadius: "32px",
        //padding: "20px",
        overflow: "scroll",
      }}
    >
      <Stack direction="row" padding="20px 10px">
        <Box ml="auto" my={1}>
          <Stack direction="row" justifyContent="flex-end" gap={2}>
            <span
              onClick={() => setNotificationsMod(false)}
              style={{
                width: "48px",
                height: "48px",
                backgroundColor: "#1e1f20",
                borderRadius: "12px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: "22px",
                color: "#fff",
                fontWeight: "bold",
                cursor: "pointer",
              }}
            >
              <CloseIcon />
            </span>
            <div
              style={{
                width: "48px",
                height: "48px",
                borderRadius: "12px",
                position: "relative",
              }}
            >
              <img
                src={userData?.imgURL || noImagePlaceholder}
                onClick={() => setOpenPopupMenu((prevValue) => !prevValue)}
                alt=""
                style={{
                  objectFit: "cover",
                  borderRadius: "12px",
                  width: "100%",
                  height: "100%",
                  cursor: "pointer",
                }}
              />

              <Animated
                animationIn="fadeInDown"
                animationOut="fadeOutUp"
                isVisible={openPopupMenu}
                animationInDuration={500}
                animationOutDuration={500}
                animateOnMount={false}
                style={{ position: "relative", zIndex: "100" }}
              >
                <PopupProfileConfig />
              </Animated>
            </div>
          </Stack>
        </Box>
      </Stack>

      <Box maxWidth="320px" margin="0 auto">
        <Stack direction="row" alignItems="center" gap={2} my={3}>
          <span
            style={{
              backgroundColor: "transparent",
              borderRadius: "50%",
              width: "10px",
              height: "10px",
            }}
          ></span>
          <Typography variant="h5" sx={{ color: "white" }}>
            {general?.notifications}
          </Typography>
          <span
            style={{
              width: "35px",
              height: "20px",
              backgroundColor: "#ff4e4e",
              borderRadius: "4px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: "12px",
              color: "#fff",
              fontWeight: "bold",
            }}
          >
            {userData.unreadNotificationsNum}
          </span>
        </Stack>

        {notifications?.map((notification) => {
          return (
            <Box>
              <NotificationItem item={notification} userId={userData.id} />
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default RightNotificationMenu;
