import { Button, List, ListItem, Typography } from "@mui/material";

import faqData from "./mockData.json";

const FaqTopics = ({ setTopic, lastTopic, setLastTopic, faqText }) => {
  const topics = faqData.map((item) => item.topicTranslate);
  const setFromTopics = new Set(topics);
  const topicsArr = Array.from(setFromTopics);
  topicsArr.push("Todos");

  const handleClick = (topic) => {
    if (topic === "Todos") {
      setTopic("all");
      setLastTopic("Todos");
      return;
    }
    const item = faqData.find((item) => item.topicTranslate === topic);
    setTopic(item.topic);
    setLastTopic(item.topicTranslate);
  };

  return (
    <div style={{ width: "100%" }}>
      <Typography variant="h4" mb={2}>
        {faqText?.title}
      </Typography>
      <Typography mb={4}>{faqText?.text}</Typography>

      {/* <List>
        {topicsArr.map((topic) => {
          return (
            <ListItem sx={{ paddingLeft: 0 }}>
              <Button
                sx={{
                  backgroundColor:
                    lastTopic === topic ? "#0659fd" : "transparent",
                  color: lastTopic === topic ? "#ffffff" : "#556cd6",
                }}
                onClick={() => handleClick(topic)}
              >
                {topic}
              </Button>{" "}
            </ListItem>
          );
        })}
      </List> */}
    </div>
  );
};

export default FaqTopics;
