import {
  Box,
  Container,
  Grid,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

import { debounce } from "lodash";

import { BiComment } from "react-icons/bi";
import { FaShare } from "react-icons/fa";

import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  getPostImages,
  getRecentComments,
} from "../../../store/actions/dreamers";
import {
  createPostLike,
  deletePostLike,
} from "../../../store/actions/userActions";
import LikeHeartBtn from "../../likeHeartBtn/LikeHeartBtn";
import Visualizer from "../../visualizer/Visualizer";
import PostCommentSection from "../postCommentSection/PostCommentSection";
import PostHeader from "../postHeader/PostHeader";
import { timestampCreador } from "../../../firebase/firebase-config";
import Colors from "../../../utils/Colors";
import ShareModal from "../../share/ShareModal";

const PhotoCollectionPostCard = ({ data, elementIndex }) => {
  const theme = useTheme();
  const [postData, setPostData] = useState({ postImages: [] });
  const [postImages, setPostImages] = useState([]);
  const [postFirstComments, setPostFirstComments] = useState([]);
  const [openVisualizer, setOpenVisualizer] = useState(false);
  const [commentsNumber, setCommentsNumber] = useState(0);
  const [likesNumber, setLikesNumber] = useState(0);
  const [hasLike, setHasLike] = useState(false);
  const [newPostLikeId, setNewPostLikeId] = useState(false);
  const [postOwnerData, setPostOwnerData] = useState(null);
  const [isShareModalOpen, setShareModalOpen] = useState(false);
  const { general } = useSelector((state) => {
    // console.log(state); // Log the entire state
    return state.loggedUser.language;
  });
  const currentUser = useSelector((state) => state.loggedUser.data);

  const isCreatingImages = useSelector(
    (state) => state.dreamers.isCreatingImages
  );

  useEffect(() => {
    const getImages = async () => {
      const images = await getPostImages(data.userOwnerId, data.id);
      setPostImages(images);
    };

    if (!!isCreatingImages === false) {
      getImages();
    }
  }, [isCreatingImages]);

  // useEffect(() => {
  //   const getComments = async () => {
  //     const comments = await getPostComments(data.parentId, data.id, 1);
  //     setPostFirstComments(comments);
  //   };

  //   if (data) {
  //     getComments();
  //   }
  // }, [data]);

  useEffect(() => {
    setPostData(data);
    setCommentsNumber(data?.commentsCount || 0);
    setLikesNumber(data?.likesCount || 0);

    const { postLikes } = data;

    if (postLikes?.length) {
      setHasLike(true);
    } else {
      setHasLike(false);
    }
  }, [data]);

  useEffect(() => {
    const postId = data.id;
    const setNewComments = (comments) => {
      // comments.sort(
      //   (firstValue, secondValue) => secondValue.cdate - firstValue.cdate
      // );
      setPostFirstComments(comments);
    };

    const unsubscribe = getRecentComments(
      postId,
      setNewComments,
      data.userOwnerId,
      2,
      currentUser.id
    );

    return () => {
      unsubscribe();
    };
  }, [postData]);

  const handleLike = debounce(async () => {
    const postId = postData.id;
    const userId = postData.userOwnerId;
    const today = new Date();
    let likeId;

    if (hasLike) {
      if (postData.postLikes.length) {
        likeId = postData.postLikes[0].id;
      } else {
        likeId = newPostLikeId;
      }

      deletePostLike(userId, postId, likeId);
      setHasLike(false);
      setLikesNumber((prevValue) => prevValue - 1);
    } else {
      const cdate = timestampCreador.fromDate(today);
      const likeData = { userRef: currentUser.id, cdate };
      let fullName = `${currentUser.name} `;

      if (currentUser.lastname) fullName = fullName + currentUser.lastname;

      const newPostLikeId = await createPostLike(
        userId,
        postId,
        likeData,
        fullName
      );

      setNewPostLikeId(newPostLikeId);
      setHasLike(true);
      setLikesNumber((prevValue) => prevValue + 1);
    }
  }, 500);
  const handleOpenShareModal = () => {
    setShareModalOpen(true);
  };

  // Close the modal
  const handleCloseShareModal = () => {
    setShareModalOpen(false);
  };
  //Removes scroll on mobile to avoid weird behaviors

  useEffect(() => {
    const htmlElement = document.documentElement;

    if (openVisualizer) {
      htmlElement.style.overflow = "hidden";
    } else {
      htmlElement.style.overflow = "auto";
    }

    return () => {
      htmlElement.style.overflow = "auto";
    };
  }, [openVisualizer]);

  const createGrid = (postImages) => {
    if (postImages.length === 5) {
      return [6, 6, 6, 6];
    }

    if (postImages.length === 4) {
      return [12, 6, 6];
    }

    if (postImages.length === 3) {
      return [12, 12];
    }

    if (postImages.length === 2) {
      return 6;
    }
  };
  const shareUrl = `https://foundation-smileup.web.app/home/dreamer/profile/${postOwnerData?.id}`;
  // useEffect(() => {
  //   console.log(postOwnerData);
  // }, [postOwnerData]);

  return (
    <Box
      sx={{
        backgroundColor: "#fff",
        marginTop: data?.type === "image" ? "40px" : "20px",
        padding: { md: "40px", xs: "25px" },
        borderRadius: "12px",
      }}
    >
      {data?.isLoading && elementIndex === 0 ? (
        <Stack direction="row" justifyContent="center">
          <CircularProgress />
        </Stack>
      ) : (
        <>
          <PostHeader
            postData={data}
            postOwnerData={postOwnerData}
            setPostOwnerData={setPostOwnerData}
          />
          <p style={{ color: "#8F92A1", fontWeight: "bold", margin: "21px 0" }}>
            {postData?.description}
          </p>
          <Grid container spacing={2}>
            <Grid item xs={postImages?.length > 1 ? 6 : 12}>
              {postImages?.length > 1 ? (
                <img
                  src={postImages[0]?.imgURL}
                  onClick={() => setOpenVisualizer(true)}
                  alt=""
                  style={{
                    borderRadius: "8px",
                    width: "100%",
                    height: "100%",
                    maxWidth: "600px",
                    objectFit: "cover",
                  }}
                />
              ) : (
                <Container>
                  <img
                    src={postImages[0]?.imgURL}
                    onClick={() => setOpenVisualizer(true)}
                    alt=""
                    style={{
                      borderRadius: "8px",
                      display: "block",
                      width: "100%",
                      objectFit: "cover",
                      height: postImages[0]?.imgURL ? "auto" : 0,
                      maxWidth: "600px",
                      margin: "0 auto",
                    }}
                  />
                </Container>
              )}
            </Grid>
            <Grid item xs={6}>
              <Grid container spacing={2}>
                {postImages.map((img, index) => {
                  const gridItemLength = createGrid(postImages);

                  if (index === 0) return;
                  return (
                    <Grid item xs={gridItemLength[index - 1]} key={index}>
                      <img
                        src={img.imgURL}
                        onClick={() => setOpenVisualizer(true)}
                        alt=""
                        style={{
                          borderRadius: "8px",
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                        }}
                      />
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          </Grid>

          <Stack
            direction="row"
            justifyContent="space-between"
            marginTop="40px"
          >
            <Stack direction="row" gap={3}>
              <Stack direction="row" alignItems="center">
                <Box onClick={handleLike} component={"span"}>
                  <LikeHeartBtn hasLike={hasLike} />
                </Box>

                <Typography fontWeight="bold" ml={1}>
                  {likesNumber}
                </Typography>
              </Stack>
              <Stack direction="row" alignItems="center">
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setOpenVisualizer(true);
                  }}
                >
                  <BiComment />
                </span>
                <Typography fontWeight="bold" ml={1}>
                  {commentsNumber}
                </Typography>
              </Stack>
            </Stack>

            <Stack direction="row" alignItems="center">
              <Typography
                component={"a"}
                fontWeight="bold"
                sx={{ color: Colors.secondary, cursor: "pointer" }}
                onClick={handleOpenShareModal}
              >
                {general?.share}
              </Typography>
              <FaShare color={Colors.secondary} />
            </Stack>
          </Stack>
        </>
      )}
      <PostCommentSection
        postFirstComments={postFirstComments}
        postId={data.id}
        postOwner={data.userOwnerId}
      />
      <ShareModal
        isOpen={isShareModalOpen}
        onClose={handleCloseShareModal}
        shareUrl={shareUrl}
        shareTitle="Check out this awesome content!"
      />

      {openVisualizer && (
        <Visualizer
          openVisualizer={openVisualizer}
          handleClose={setOpenVisualizer}
          slides={postImages}
          post={postData}
          setCommentsNumber={setCommentsNumber}
          commentsNum={commentsNumber}
        />
      )}
    </Box>
  );
};

export default PhotoCollectionPostCard;
