import MenuIcon from "@mui/icons-material/Menu";
import { useMediaQuery, useTheme } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import SignUpModal from "../../SignUp/SignUpModal";
import LogInModal from "../../modals/LogInModal";
import PasswordRecoveryModal from "../../modals/PasswordRecoveryModal";
import RegisterModal from "../../modals/RegisterModal";
import SignInModal from "../../signIn/SignInModal";
import LanguageDropdown from "../../general/LanguageDropdown";
import { useSelector } from "react-redux";

const NavigationBar = ({ setOpenRegister, setOpenLogIn }) => {
  const { navbar, login, register, footer, contactPage } = useSelector(
    (state) => {
      // console.log(state); // Log the entire state
      return state.loggedUser.language;
    }
  );
  const navigate = useNavigate();
  const location = useLocation();

  const [anchorElNav, setAnchorElNav] = useState(null);
  const [isWhite, setIsWhite] = useState();
  // const [openLogIn, setOpenLogIn] = useState(false);
  // const [openRegister, setOpenRegister] = useState(false);
  // const [openPassRecovery, setOpenPassRecovery] = useState(false);
  // const [openSignUpSteps, setOpenSignUpSteps] = useState(false);
  // const [openSignInSteps, setOpenSignInSteps] = useState(false);

  const isDown1200 = useMediaQuery("(max-width:1200px)");
  const isDown1000 = useMediaQuery("(max-width:1000px)");
  const isDown600 = useMediaQuery("(max-width:600px)");

  const theme = useTheme();
  const pages = [
    { label: navbar?.home, url: "/" },
    { label: navbar?.about, url: "/about-us" },
    { label: navbar?.contact, url: "/contact" },
    { label: navbar?.faq, url: "/frequently-ask" },
    // { label: "Blog", url: "#" },
  ];
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  useEffect(() => {
    const val =
      location.pathname.includes("frequently") ||
      location.pathname.includes("contact");
    setIsWhite(val);
  }, []);

  return (
    <AppBar
      position="static"
      sx={{ background: "transparent", boxShadow: 0, paddingY: 2 }}
    >
      {/* <Container maxWidth="xl"> */}
      <Toolbar disableGutters>
        <Box sx={{ display: { xs: "none", md: "flex" }, mr: 1 }}>
          <img
            src={require("../../../assets/img/logoSmile.png")}
            width={isDown1200 ? "170px" : "250px"}
            className="d-inline-block align-center me-4"
            alt="Smile up logo"
          />
        </Box>

        <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleOpenNavMenu}
            color="inherit"
          >
            <MenuIcon />
          </IconButton>
          <Box sx={{ marginLeft: "auto" }}>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page.label} onClick={() => navigate(page.url)}>
                  <Typography
                    textAlign="center"
                    sx={{
                      color:
                        location.pathname !== "/about-us"
                          ? theme.palette.primary.main
                          : "#C08DFF",
                    }}
                    color={
                      location.pathname === "/about-us" ? "#C08DFF" : "#fff"
                    }
                  >
                    {page.label}
                  </Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Box>
        <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
          {pages.map((page) => (
            <Button
              key={page.label}
              onClick={() => navigate(page.url)}
              sx={{
                my: 2,
                color: location.pathname === "/about-us" ? "#C08DFF" : "#fff",
                display: "block",
              }}
            >
              {page.label}
            </Button>
          ))}
        </Box>
        <Box sx={{ position: "relative", right: 20, top: 0 }}>
          <LanguageDropdown color={"black"} borderColor={"white"} />
        </Box>

        <Box sx={{ flexGrow: 0 }}>
          <Button
            variant="text"
            onClick={() => setOpenLogIn(true)}
            sx={{
              color: isDown600 || isWhite ? "#fff" : "#556cd6",
            }}
          >
            {isDown1000 ? "Iniciar" : "Iniciar sesión"}
          </Button>
          <Button
            variant="outlined"
            onClick={() => setOpenRegister(true)}
            sx={{
              borderColor: "#fff",
              color: isDown600 || isWhite ? "#fff" : "#556cd6",
            }}
          >
            Registrate
          </Button>
        </Box>
      </Toolbar>

      {/* </Container> */}
      {/* <LogInModal
        open={openLogIn}
        handleOpen={setOpenLogIn}
        handleOpenRegister={setOpenRegister}
        handleOpenPassRecovery={setOpenPassRecovery}
        handleOpenSingInStep={setOpenSignInSteps}
      />
      <RegisterModal
        open={openRegister}
        handleOpenLogIn={setOpenLogIn}
        handleOpenPassRecovery={setOpenPassRecovery}
        handleOpenSignUpSteps={setOpenSignUpSteps}
        handleOpenSignIn={setOpenLogIn}
        handleOpenSignInStep={setOpenSignInSteps}
        handleOpenSignUp={setOpenRegister}
        // open={openRegister}
        // handleOpen={setOpenRegister}
        // handleOpenLogIn={setOpenLogIn}
        // handleOpenPassRecovery={setOpenPassRecovery}
        // handleOpenSignUpSteps={setOpenSignUpSteps}
      />

      <PasswordRecoveryModal
        open={openPassRecovery}
        handleOpen={setOpenPassRecovery}
        handleOpenLogIn={setOpenLogIn}
      />

      <SignUpModal open={openSignUpSteps} handleOpen={setOpenSignUpSteps} />
      <SignInModal open={openSignInSteps} handleOpen={setOpenSignInSteps} /> */}
    </AppBar>
  );
};

export default NavigationBar;
