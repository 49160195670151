import { Box, Card, CardContent, Grid, Typography } from "@mui/material";
import rocket from "./../../assets/img/rocket.png";
import { useEffect, useState } from "react";

const DreamerCard = ({ selected, setSelected, userData, signIn }) => {
  const [isOnlyMaker, setIsOnlyMaker] = useState(false);

  useEffect(() => {
    if (userData) {
      const { isMaker, isDreamer } = userData;

      let isAllowedAsDreamer = false;

      if (isMaker) {
        if (isDreamer) {
          //Is it dreamer and maker?
          isAllowedAsDreamer = true;
        }
      } else if (isDreamer) {
        //Is it just a dreamer?
        isAllowedAsDreamer = true;
      }

      setIsOnlyMaker(isAllowedAsDreamer);
    }
  }, [userData]);

  return (
    <Card
      onClick={() => {
        isOnlyMaker && setSelected("dreamer");
      }}
      variant="outline"
      sx={{
        backgroundColor: "#18ACFE",
        filter: !isOnlyMaker ? "grayscale(80%)" : "",
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 100%",
        marginBottom: "20px",
        transform: selected === "dreamer" ? "scale(1.1)" : "scale(1)",
        transition: "transform 0.3s",
        cursor: !isOnlyMaker ? "not-allowed" : "pointer",
        borderRadius: "32px",
      }}
    >
      <CardContent>
        <Grid container>
          <Grid
            item
            sm={5}
            xs={12}
            textAlign="center"
            display="flex"
            alignItems="center"
          >
            <img src={rocket} alt="" width="90%" />
          </Grid>
          <Grid
            item
            sm={7}
            xs={12}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Box>
              <Typography
                sx={{ color: "#fff" }}
                fontWeight="bold"
                mb={1}
                variant="h4"
                textAlign="center"
              >
                {signIn?.dreamer}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default DreamerCard;
