import { Button, Stack, Typography } from "@mui/material";

import { FiMap } from "react-icons/fi";

const LocationDetails = () => {
  return (
    <Stack direction="row" color="#fff">
      <Stack direction="row" gap={1}>
        <span
          style={{
            padding: "10px 15px",
            backgroundColor: "#4b4c4d",
            borderRadius: "12px",
          }}
        >
          <FiMap color="#fff" />
        </span>

        <Stack>
          <Typography fontWeight="bold">San Jose</Typography>
          <Typography>La Carpio</Typography>
        </Stack>
      </Stack>

      <Button
        variant="outlined"
        size="small"
        sx={{ borderRadius: "12px", display: "block", marginLeft: "auto" }}
      >
        Direcciones
      </Button>
    </Stack>
  );
};

export default LocationDetails;
