import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import Grid from "@mui/material/Grid";

import testimonialBg from "../../../assets/img/testimonial-bg.png";
import hummingbird from "../../../assets/img/testimonial-colibri.svg";

import "react-alice-carousel/lib/alice-carousel.css";

import TestimonialResponsiveCarousel from "./TestimonialResponsiveCarousel";
import TestimonialCarousel from "./TestimonialCarousel";

const TestimonialSection = ({ landing }) => {
  const theme=useTheme();
  const isDown1100 = useMediaQuery("(max-width:1100px)");
  const isDown899 = useMediaQuery("(max-width:899px)");
  const isDown900 = useMediaQuery("(max-width:900px)");
  const isDown750 = useMediaQuery("(max-width:750px)");
  const isDown600 = useMediaQuery("(max-width:600px)");
  const isDown550 = useMediaQuery("(max-width:550px)");

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      component="section"
      sx={{
        backgroundImage: {
          xs: `radial-gradient(circle, rgba(193,115,255,1) 46%, rgba(193,188,255,1) 79%, rgba(222,181,255,1) 100%, rgba(255,217,221,1) 100%, rgba(170,235,255,1) 100%)`,
          md: `url(${testimonialBg})`,
        },
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 100%",
        paddingLeft: 0,
        paddingRight: 0,

        height: "90vh",
        minHeight: "620px",
        //height: isDown600 ? "auto" : "90vh",
        backgroundColor: "#f7f1ff",
        marginBottom: 0,

        [theme.breakpoints.down("md")]: {
          height: "50vh",
          pr:2
  
        },
        [theme.breakpoints.down("sm")]: {
          height: "65vh",
    
  
        }
      }}
    >
      <Grid
        container
        marginBottom={isDown900 ? 0 : "140px"}
        sx={{ transform: { xs: "none", md: "translateY(60%)" } }}
      >
        <Grid
          item
          md={6}
          paddingRight={isDown550 ? "20px" : "40px"}
          paddingLeft={isDown550 ? "20px" : "40px"}
        >
          <Box width={isDown1100 ? "100%" : "75%"} paddingTop={7}>
            <img
              src={hummingbird}
              alt="Colibri sobre rosal"
              style={{
                position: "absolute",
                display: isDown900 ? "none" : "",
                top: "-580px",
                left: isDown750 ? -100 : -250,
              }}
            />
            <Typography variant="h4" color="#fff">
              {landing?.testimonialSection.title}
            </Typography>
            <Typography variant="body1" color="#fff" marginTop="30px">
              {landing?.testimonialSection.text}
            </Typography>
          </Box>
        </Grid>
        {isDown899 ? (
          <TestimonialResponsiveCarousel />
        ) : (
          <Grid item md={6}>
            <TestimonialCarousel />
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default TestimonialSection;
