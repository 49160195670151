import { Box, Stack, Typography } from "@mui/material";

import { BiHeart, BiComment } from "react-icons/bi";
import { FaHeart } from "react-icons/fa";

import noImagePlaceholder from "../../../assets/img/avatar-no-image.png";
import LikeHeartBtn from "../../likeHeartBtn/LikeHeartBtn";
import { useEffect, useState } from "react";

import { debounce } from "lodash";
import {
  createCommentLike,
  deleteCommentLike,
} from "../../../store/actions/userActions";
import { useSelector } from "react-redux";
import { timestampCreador } from "../../../firebase/firebase-config";

const PostComment = ({ postOwnerId, data, postId }) => {
  const [commentData, setCommentData] = useState({});
  const [newCommentLikeId, setNewCommentLikeId] = useState(false);
  const [hasLike, setHasLike] = useState(false);

  const currentUser = useSelector((state) => state.loggedUser.data);

  const calculateTimeSinceComment = () => {
    const today = new Date();
    const offsetTime = commentData.cdate?.toDate() || new Date();
    const timeDifference = today - offsetTime;

    if (timeDifference < 1000) {
      return "Justo ahora";
    } else if (timeDifference < 60 * 1000) {
      return Math.floor(timeDifference / 1000) + " segundos"; // Seconds ago
    } else if (timeDifference < 60 * 60 * 1000) {
      return Math.floor(timeDifference / (60 * 1000)) + " minutos"; // Minutes ago
    } else if (timeDifference < 24 * 60 * 60 * 1000) {
      return Math.floor(timeDifference / (60 * 60 * 1000)) + " horas"; // Hours ago
    } else {
      return Math.floor(timeDifference / (24 * 60 * 60 * 1000)) + " días"; // Days ago
    }
  };

  const timeSinceComment = calculateTimeSinceComment();

  useEffect(() => {
    setCommentData(data);

    const { commentLikes } = data;

    if (commentLikes?.length) {
      setHasLike(true);
    } else {
      setHasLike(false);
    }
  }, [data]);

  const handleLike = debounce(async () => {
    const commentId = commentData.id;
    const userId = postOwnerId;
    const today = new Date();
    let likeId;

    if (hasLike) {
      if (commentData.commentLikes.length) {
        likeId = commentData.commentLikes[0].id;
      } else {
        likeId = newCommentLikeId;
      }
      //const likeId = commentData.commentLikes[0].id;

      deleteCommentLike(userId, postId, commentId, likeId);
      setHasLike(false);
      //setLikesNumber((prevValue) => prevValue - 1);
    } else {
      const cdate = timestampCreador.fromDate(today);
      const likeData = { userRef: currentUser.id, cdate };
      let fullName = `${currentUser.name} `;

      if (currentUser.lastname) fullName = fullName + currentUser.lastname;

      const newCommentLikeId = await createCommentLike(
        userId,
        postId,
        commentData,
        likeData,
        fullName
      );
      setHasLike(true);
      setNewCommentLikeId(newCommentLikeId);
      //setLikesNumber((prevValue) => prevValue + 1);
    }
  }, 500);

  return (
    <Box>
      <Stack direction="row" alignItems="center" margin="20px 0">
        <span style={{ width: "32px", height: "32px" }}>
          <img
            src={commentData?.commentOwnerData?.imgURL || noImagePlaceholder}
            width="100%"
            height="100%"
            style={{
              borderRadius: "12px",
              objectFit: "cover",
            }}
          />
        </span>

        <span
          style={{ fontWeight: "bold", display: "block", marginLeft: "5px" }}
        >
          {commentData?.commentOwnerData?.name}
        </span>
        <span style={{ marginLeft: "auto", color: "#8F92A1" }}>
          {timeSinceComment}
        </span>
      </Stack>

      <Typography color="#8F92A1" fontSize="14px">
        {commentData.content}
      </Typography>

      <Stack direction="row" gap={1} marginTop="20px" alignItems="center">
        <Box onClick={handleLike} component={"span"}>
          <LikeHeartBtn hasLike={hasLike} />
        </Box>

        <BiComment size={22} />
      </Stack>
    </Box>
  );
};

export default PostComment;
