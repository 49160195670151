import { Box } from "@mui/material";

import "./loading.scss";

const Loading = () => {
  return (
    <Box
      width="100vw"
      height="100vh"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      position="relative"
    >
      <h1
        style={{
          position: "absolute",
          top: "40%",
          left: "50%",
          transform: "translateX(-50%)",
        }}
      >
        Cargando...
      </h1>
      <div className="loader"></div>
    </Box>
  );
};

export default Loading;
