import { Navigate, useNavigate } from "react-router-dom";
import {
  auth,
  db,
  facebookProvider,
  googleProvider,
} from "../../firebase/firebase-config";
import { types } from "../types/types";
import {
  getUserData,
  getUserNotifications,
  resetUserData,
  resetUserNotifications,
  setIsLoadingUserData,
} from "./userActions";

export const getUserByEmail = async (email) => {
  const hasAccount = await db
    .collection("admins")
    .where("email", "==", email)
    .get();

  if (hasAccount.empty) {
    return false;
  }

  return true;
};

export const updateUserPassword = async (newPassword) => {
  try {
    const result = await auth.currentUser.updatePassword(newPassword);
  } catch (error) {
    console.log(error);
  }
};

export const getOneUser = async (id) => {
  try {
    const user = await db.collection("users").doc(id).get();

    return user.data();
  } catch (error) {
    console.log(error);
  }
};

export const login = (email, password, executeAction) => {
  return async (dispatch) => {
    let skipFinally = false;
    let userData;
    let userAuth;
    let initialized = false;

    try {
      dispatch(setIsLoadingAuth(true));
      const { user } = await auth.signInWithEmailAndPassword(email, password);
      userAuth = user;

      dispatch(setIsLoadingUserData(true));
      const unsubscribe = db
        .collection("users")
        .doc(user.uid)
        .onSnapshot((snapshot) => {
          if (snapshot.exists) {
            userData = snapshot.data();
            if (userData.isActive) {
              dispatch(getUserData({ id: user.uid, ...userData }));
              dispatch(setAuthError("no-error"));
            } else {
              dispatch(setAuthError("auth/deactivated-account"));
              skipFinally = true;
            }
          } else {
            console.log("Document does not exist");
          }

          if (userData.isActive) {
            dispatch(logInUser(userAuth.toJSON(), initialized));
          }

          if (!initialized) {
            initialized = true;
          }

          dispatch(setIsLoadingUserData(false));

          if (executeAction && !skipFinally) {
            executeAction();
          }
        });

      dispatch(setIsLoadingAuth(false));
      //dispatch(getUserNotifications(user.uid));
    } catch (error) {
      console.log(error);
      skipFinally = true;
      dispatch(setIsLoadingAuth(false));
      dispatch(setAuthError(error.code));
    }
  };
};

export const registerWithEmailAndPassword = (
  email,
  password,
  executeAction,
  userData
) => {
  return async (dispatch) => {
    let skipFinally = false;

    try {
      dispatch(setIsLoadingAuth(true));
      const { user } = await auth.createUserWithEmailAndPassword(
        email,
        password
      );

      //dispatch(setIsDoingRegister(true));

      dispatch(logInUser(user.toJSON(), false));
      dispatch(setIsLoadingAuth(false));
      dispatch(setAuthError("no-error"));
      createUserProfileDocument(user, userData);
    } catch (error) {
      console.log(error);
      skipFinally = true;
      dispatch(setIsLoadingAuth(false));
      dispatch(setAuthError(error.code));
    } finally {
      if (executeAction && !skipFinally) {
        //dispatch(getOneUserData(user.uid));
        executeAction();
      }
    }
  };
};

// export const initWithGoogle = (executeAction, isDoingLogin) => {
//   let skipFinally = false;
//   let userData;
//   let userAuth;
//   let initialized = false;

//   return async (dispatch) => {
//     try {
//       dispatch(setIsLoadingAuth(true));
//       const { user, additionalUserInfo } = await auth.signInWithPopup(
//         googleProvider
//       );

//       const unsubscribe = db
//         .collection("users")
//         .doc(user.uid)
//         .onSnapshot((snapshot) => {
//           if (snapshot.exists) {
//             userData = snapshot.data();
//             if (userData.isActive) {
//               dispatch(getUserData({ id: user.uid, ...userData }));
//               dispatch(setAuthError("no-error"));
//             } else {
//               dispatch(setAuthError("auth/deactivated-account"));
//               skipFinally = true;
//             }
//           } else {
//             console.log("Document does not exist");
//             createUserProfileDocument(user, { isActive: true });
//             dispatch(logInUser(user.toJSON(), initialized));
//             dispatch(setAuthError("no-error"));
//             //return;
//           }

//           if (userData?.isActive) {
//             //dispatch(logInUser(userAuth.toJSON(), initialized));

//             if (isDoingLogin) {
//               dispatch(logInUser(user.toJSON(), initialized));
//               dispatch(setAuthError("no-error"));
//             } else {
//               if (additionalUserInfo.isNewUser) {
//                 dispatch(logInUser(user.toJSON(), initialized));
//                 dispatch(setAuthError("no-error"));
//               } else {
//                 dispatch(setAuthError("auth/email-already-in-use"));
//                 skipFinally = true;
//               }
//             }
//           }

//           if (!initialized) {
//             initialized = true;
//           }

//           dispatch(setIsLoadingUserData(false));

//           if (executeAction && !skipFinally) {
//             executeAction();
//           }
//         });

//       dispatch(setIsLoadingAuth(false));
//     } catch (error) {
//       console.log(error);
//       skipFinally = true;
//       dispatch(setIsLoadingAuth(false));
//       dispatch(setAuthError(error.code));
//     }
//   };
// };

export const initWithGoogle = (handleAction) => {
  let skipFinally = false;
  return async (dispatch) => {
    try {
      dispatch(setIsLoadingAuth(true));
      const { user, additionalUserInfo } = await auth.signInWithPopup(
        googleProvider
      );

      const result = await db.collection("users").doc(user.uid).get();
      const userResult = result.data();

      if (!additionalUserInfo.isNewUser) {
        if (userResult.isActive) {
          dispatch(logInUser(user.toJSON(), false));
        } else {
          dispatch(setAuthError("auth/deactivated-account"));
          skipFinally = true;
        }
      } else {
        createUserProfileDocument(user, { name: user.displayName });
        dispatch(logInUser(user.toJSON(), false));
      }
    } catch (error) {
      dispatch(setIsLoadingAuth(false));
      skipFinally = true;
      console.log(error);
    } finally {
      if (handleAction && !skipFinally) {
        handleAction();
      }
    }
  };
};

export const initWithFacebook = () => {
  return async (dispatch) => {
    try {
      const { user, additionalUserInfo } = await auth.signInWithPopup(
        facebookProvider
      );

      if (!additionalUserInfo.isNewUser) {
        dispatch(logInUser(user.toJSON(), true));
      }
    } catch (error) {
      dispatch(setIsLoadingAuth(false));
      console.log(error);
    }
  };
};

export const resetPassword = async (recoveryEmail) => {
  try {
    await auth.sendPasswordResetEmail(recoveryEmail);
  } catch (error) {
    console.log(error);
  }
};

export const setAuthError = (errorMsg) => ({
  type: types.errorMsg,
  payload: {
    code: errorMsg,
  },
});

export const setUserSelection = (userSelection) => ({
  type: types.setUserSelection,
  payload: {
    userSelection,
  },
});

export const createUserProfileDocument = async (userAuth, additionalData) => {
  if (!userAuth) return;

  const userRef = db.collection("users").doc(`${userAuth.uid}`);
  const snapShot = await userRef.get();

  if (!snapShot.exists) {
    const { email } = userAuth;
    const cdate = new Date();

    try {
      await userRef.set({
        email,
        cdate,
        ...additionalData,
      });
    } catch (error) {
      console.log("Error creating user: ", error.message);
    }
  }

  return userRef;
};

export const logInUser = (currentUser, loggedIn) => ({
  type: types.logInUser,
  payload: {
    user: currentUser,
    loggedIn,
  },
});

export const setIsLoadingAuth = (isLoading) => ({
  type: types.isLoadingAuth,
  payload: {
    isLoadingAuth: isLoading,
  },
});

export const logoutUser = (navigateTo) => {
  return async (dispatch) => {
    try {
      dispatch(setIsLoadingAuth(true));
      await auth.signOut();
      dispatch(logout());
      dispatch(resetUserData());
      dispatch(resetUserNotifications());
      dispatch(setIsLoadingAuth(false));
    } catch (error) {
      console.error(error);
      dispatch(setIsLoadingAuth(false));
    }
  };
};

export const logout = () => ({
  type: types.logout,
  payload: {},
});

export const ReCaptcha = (token) => {
  // https://www.google.com/recaptcha/api/siteverify
};
