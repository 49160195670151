import { Box, Button, Grid, Stack, Typography } from "@mui/material";

import { FiChevronRight } from "react-icons/fi";

const MyPhotos = ({ images }) => {
  return images.length ? (
    <Stack gap={3} bgcolor="#fff" borderRadius="12px" padding="30px">
      <Typography variant="h6" fontWeight="bold">
        Fotos
      </Typography>
      <Grid container spacing={2}>
        {images.slice(0, 9).map((item) => {
          return (
            <Grid
              item
              xs={6}
              sm={3}
              xl={3}
              lg={4}
              display="flex"
              justifyContent="center"
            >
              <Box
                width={{ xs: "120px", sm: "90px", xl: "110px" }}
                height={{ xs: "120px", sm: "90px", xl: "110px" }}
              >
                <img
                  src={item.imgURL}
                  alt=""
                  style={{
                    borderRadius: "12px",
                    width: "100%",
                    height: "100%",
                  }}
                />
              </Box>
            </Grid>
          );
        })}
      </Grid>
      <Button
        variant="outlined"
        sx={{
          borderRadius: "4px",
          color: "#000",
          borderColor: "rgba(143, 146, 161, 0.2)",
          width: "120px",
        }}
      >
        Ver más <FiChevronRight />
      </Button>
    </Stack>
  ) : (
    <Stack gap={3} bgcolor="#fff" borderRadius="12px" padding="30px">
      <Typography variant="h6" fontWeight="bold">
        Fotos
      </Typography>
      <Box
        minHeight="400px"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <h3>No hay ninguna imágen por aquí ಠ_ಠ</h3>
      </Box>
    </Stack>
  );
};

export default MyPhotos;
