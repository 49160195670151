import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from "@mui/material";
import Chip from "@mui/material/Chip";

import { Provinces as provinces } from "../../../utils/province";
import { categories } from "../../../utils/categories";

const DreamDetails = ({
  handleBlur,
  handleChange,
  values,
  errors,
  touched,
  dreamForm,
}) => {
  const categories = [
    {
      id: 1,
      label: dreamForm?.categoriesList?.c1,
      value: "Transport",
    },
    {
      id: 2,
      label: dreamForm?.categoriesList?.c2,
      value: "health",
    },
    {
      id: 3,
      label: dreamForm?.categoriesList?.c3,
      value: "enviroment",
    },
    {
      id: 4,
      label: dreamForm?.categoriesList?.c4,
      value: "shelter",
    },
    {
      id: 5,
      label: dreamForm?.categoriesList?.c5,
      value: "arts",
    },
    {
      id: 6,
      label: dreamForm?.categoriesList?.c6,
      value: "sports",
    },
    {
      id: 7,
      label: dreamForm?.categories?.categoriesList?.c7,
      value: "education",
    },
    {
      id: 8,
      label: dreamForm?.categories?.categoriesList?.c8,
      value: "others",
    },
  ];

  return (
    <>
      <Typography fontWeight="bold" variant="h6" component="h2" my={2}>
        {dreamForm?.dreamDetails}
      </Typography>

      <Grid container spacing={3}>
        <Grid item lg={4} xs={12}>
          <FormControl fullWidth variant="outlined" sx={{ padding: 0 }}>
            <label htmlFor="outlined-dreamName" style={{ fontWeight: "bold" }}>
              {dreamForm?.name}
            </label>
            <OutlinedInput
              id="outlined-dreamName"
              name="dreamName"
              onChange={handleChange}
              value={values.dreamName}
              onBlur={handleBlur}
              error={touched.dreamName && Boolean(errors.dreamName)}
              type={"text"}
              sx={{ borderRadius: "24px", backgroundColor: "#fff" }}
            />

            <FormHelperText error id="outlined-dreamName">
              {touched.dreamName && errors.dreamName ? errors.dreamName : " "}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item lg={4} xs={12}>
          <FormControl variant="outlined" fullWidth sx={{ padding: 0 }}>
            <label htmlFor="outlined-typeOfHelp" style={{ fontWeight: "bold" }}>
              {dreamForm?.typeOfHelp}
            </label>
            <OutlinedInput
              id="outlined-typeOfHelp"
              name="typeOfHelp"
              placeholder={dreamForm?.short}
              onChange={handleChange}
              value={values.typeOfHelp}
              onBlur={handleBlur}
              error={touched.typeOfHelp && Boolean(errors.typeOfHelp)}
              type={"text"}
              sx={{ borderRadius: "24px", backgroundColor: "#fff" }}
            />

            <FormHelperText error id="outlined-typeOfHelp">
              {touched.typeOfHelp && errors.typeOfHelp
                ? errors.typeOfHelp
                : " "}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item lg={4} xs={12}>
          <FormControl variant="outlined" fullWidth sx={{ padding: 0 }}>
            <label
              htmlFor="outlined-deliveryPlace"
              style={{ fontWeight: "bold" }}
            >
              {dreamForm?.delivery}
            </label>
            <Select
              id="outlined-deliveryPlace"
              name="deliveryPlace"
              onChange={handleChange}
              value={values.deliveryPlace}
              onBlur={handleBlur}
              error={touched.deliveryPlace && Boolean(errors.deliveryPlace)}
              type={"text"}
              sx={{ borderRadius: "24px", backgroundColor: "#fff" }}
            >
              {provinces.map((province) => {
                return (
                  <MenuItem
                    key={province.provinceId}
                    value={province.provinceName}
                  >
                    {province.label}
                  </MenuItem>
                );
              })}
            </Select>

            <FormHelperText error id="outlined-description">
              {touched.deliveryPlace && errors.deliveryPlace
                ? errors.deliveryPlace
                : " "}
            </FormHelperText>
          </FormControl>
        </Grid>

        <Grid item lg={12} xs={12}>
          <FormControl variant="outlined" fullWidth sx={{ padding: 0 }}>
            <label
              htmlFor="outlined-description"
              style={{ fontWeight: "bold" }}
            >
              {dreamForm?.categories}
            </label>
            <Select
              id="outlined-categories"
              name="categories"
              onChange={handleChange}
              value={values.categories}
              onBlur={handleBlur}
              error={touched.categories && Boolean(errors.categories)}
              type={"text"}
              multiple
              sx={{ borderRadius: "24px", backgroundColor: "#fff" }}
              input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
              renderValue={(selected) => (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {selected.map((value) => {
                    const { label: spanishValue } = categories.find(
                      (category) => category.value === value
                    );
                    return <Chip key={value} label={spanishValue} />;
                  })}
                </Box>
              )}
            >
              {categories.map((category) => {
                return (
                  <MenuItem key={category.id} value={category.value}>
                    {category.label}
                  </MenuItem>
                );
              })}
            </Select>

            <FormHelperText error id="outlined-categories">
              {touched.categories && errors.categories
                ? errors.categories
                : " "}
            </FormHelperText>
          </FormControl>
        </Grid>
      </Grid>
    </>
  );
};

export default DreamDetails;
