import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  CircularProgress,
  Stack,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useEffect } from "react";

import { FiMoreVertical, FiCheck } from "react-icons/fi";
import { useSelector } from "react-redux";

const DreamCard = ({ dreamData }) => {
  const { general } = useSelector((state) => state.loggedUser.language);
  const [dreamDay, setDreamDay] = useState("");
  const [dreamWeekDay, setDreamWeekDay] = useState("");
  const [dreamMonthAndYear, setDreamMonthAndYear] = useState("");
  const [dreamTime, setDreamTime] = useState("");
  const [dreamCurrentState, setDreamCurrentState] = useState(general?.pending);
  useEffect(() => {
    if (dreamData) {
      const dateJSformat = new Date(
        dreamData.cdate.seconds * 1000 + dreamData.cdate.nanoseconds / 100000
      );
      //const dateJSformat = eventData.eventDate.toDate();
      setDreamDay(dateJSformat.getDate());
      setDreamWeekDay(
        dateJSformat.toLocaleDateString("CR", {
          weekday: "long",
        })
      );
      setDreamMonthAndYear(
        dateJSformat.toLocaleDateString("CR", {
          month: "short",
          year: "numeric",
        })
      );

      setDreamTime(
        dateJSformat.toLocaleTimeString("es-CR", {
          hour12: true,
          hour: "2-digit",
        })
      );

      if (dreamData.state === "active") {
        setDreamCurrentState(general?.active);
      } else if (dreamData.state === "rejected") {
        setDreamCurrentState(general?.rejected);
      }
    }
  }, [dreamData]);

  return (
    <Card
      variant="outlined"
      sx={{ border: "none", borderRadius: "12px", width: "100%" }}
    >
      <CardMedia sx={{ padding: { xs: "20px", md: "40px" } }}>
        <Box height="180px">
          <img
            src={dreamData.imgURL}
            alt="Evento"
            width="100%"
            height="100%"
            style={{ objectFit: "cover" }}
          />
        </Box>
      </CardMedia>

      <CardContent sx={{ padding: "0 40px" }}>
        <Stack direction="row">
          <Stack direction="row" gap={1} mr="auto" alignItems="center">
            <span
              style={{
                border: "2px solid rgba(143, 146, 161, 0.2)",
                borderRadius: "15px",
                padding: "10px 15px",
              }}
            >
              <Typography variant="h5">{dreamDay}</Typography>
            </span>
            <Stack>
              <Typography textTransform="capitalize">{dreamWeekDay}</Typography>
              <Typography textTransform="capitalize">
                {dreamMonthAndYear}
              </Typography>
            </Stack>
          </Stack>

          {/* <span>
            <FiMoreVertical size={22} color="#8F92A1" />
          </span> */}
        </Stack>

        <Stack gap={1} my={4}>
          {/* <Link
            to={`/home/dreamer/event/${dreamData.id}`}
            style={{ textDecoration: "none" }}
          > */}
          <Typography
            variant="h4"
            fontWeight="bold"
            sx={{ cursor: "pointer", color: "#000" }}
          >
            {dreamData.name}
          </Typography>
          {/* </Link> */}
          {/* <Typography sx={{ color: "#8F92A1" }}>
            {`Inicia a las ${dreamTime}, online`}
          </Typography> */}
        </Stack>

        <Stack direction="row" mb={3}>
          {/* <CardActions> */}
          <Button
            variant="outlined"
            sx={{
              borderRadius: "4px",
              color: "#000",
              borderColor: "rgba(143, 146, 161, 0.2)",
            }}
          >
            <FiCheck />
            {dreamCurrentState}
          </Button>
          {/* </CardActions> */}
          <Stack direction="row" ml="auto">
            <span style={{ width: "28px", height: "28px" }}>
              <img
                src="https://picsum.photos/id/96/200/300"
                width="100%"
                height="100%"
                alt="Usuario participante"
                style={{ borderRadius: "8px" }}
              />
            </span>
            <span style={{ width: "28px", height: "28px" }}>
              <img
                src="https://picsum.photos/id/96/200/300"
                width="100%"
                height="100%"
                alt="Usuario participante"
                style={{ borderRadius: "8px" }}
              />
            </span>
            <span
              style={{
                width: "28px",
                height: "28px",
                borderRadius: "8px",
                backgroundColor: "#1E1F20",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography fontSize="12px" sx={{ color: "#fff" }}>
                +9
              </Typography>
            </span>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default DreamCard;
