import { Box, Card, CardContent, Grid, Typography } from "@mui/material";
import rocket from "../../../assets/img/rocket.png";

const DreamerCard = ({ selected, setSelected, signUp }) => {
  return (
    <Card
      onClick={() => setSelected("dreamer")}
      variant="outline"
      sx={{
        //backgroundImage: `url(${bgDreamerCard})`,
        backgroundColor: "#18ACFE",
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 100%",
        marginBottom: "20px",
        transform: selected === "dreamer" ? "scale(1.1)" : "scale(1)",
        transition: "transform 0.3s",
        cursor: "pointer",
        borderRadius: "32px",
      }}
    >
      <CardContent>
        <Grid container>
          <Grid
            item
            sm={5}
            xs={12}
            textAlign="center"
            display="flex"
            alignItems="center"
          >
            <img src={rocket} alt="" width="90%" />
          </Grid>
          <Grid
            item
            sm={7}
            xs={12}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Box>
              <Typography sx={{ color: "#fff" }} fontWeight="bold" mb={1}>
                {signUp?.dreamerTitle}
              </Typography>
              <Typography sx={{ color: "#fff" }}>
                {signUp?.dreamerText}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default DreamerCard;
