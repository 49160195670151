import { Grid } from "@mui/material";
import CreateDreamForm from "../../../components/dreamerPage/createDreamForm/CreateDreamForm";

const CreateDreamPage = () => {
  return (
    <Grid
      xl={11}
      lg={11}
      md={12}
      xs={12}
      item
      minHeight="100vh"
      sx={{ padding: { md: "20px 60px", xs: "20px 25px" } }}
    >
      <CreateDreamForm />
    </Grid>
  );
};

export default CreateDreamPage;
