import NavigationBar from "../../landingPage/navbar/NavigationBar";
import Hero from "../hero/Hero";

import aboutUsBg from "../../../assets/img/about-us-page-bg.png";
import semicircle from "../../../assets/img/semicircle-blue.png";

import Fundation from "../fundation/Fundation";
import FundationNumbers from "../fundationNumbers/FundationNumbers";
import FinancialSection from "../financial/FinancialSection";
import InitialSection from "../initial/InitialSection";

import { Box, useMediaQuery } from "@mui/material";
import LetsTalkSection from "../letsTalk/LetsTalkSection";
import Footer from "../../footer/Footer";
import { useState } from "react";
import LogInModal from "../../modals/LogInModal";
import RegisterModal from "../../modals/RegisterModal";
import PasswordRecoveryModal from "../../modals/PasswordRecoveryModal";
import SignUpModal from "../../SignUp/SignUpModal";
import SignInModal from "../../signIn/SignInModal";
import { useSelector } from "react-redux";

const AboutUs = () => {
  const isDown1390 = useMediaQuery("(max-width:1390px)");
  const isDown1199 = useMediaQuery("(max-width:1199px)");
  const isDown980 = useMediaQuery("(max-width:980px)");
  const isDown600 = useMediaQuery("(max-width:600px)");

  const [openLogIn, setOpenLogIn] = useState(false);
  const [openRegister, setOpenRegister] = useState(false);
  const [openPassRecovery, setOpenPassRecovery] = useState(false);
  const [openSignUpSteps, setOpenSignUpSteps] = useState(false);
  const [openSignInSteps, setOpenSignInSteps] = useState(false);
  const { aboutPage, login, register, footer } = useSelector((state) => {
    // console.log(state); // Log the entire state
    return state.loggedUser.language;
  });
  return (
    <>
      <Box
        component="section"
        sx={{
          background: isDown600 ? "#c07ee1" : `url(${aboutUsBg})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "100% 100%",
          paddingLeft: { xs: "20px", sm: "40px", md: "90px" },
          paddingRight: { xs: "20px", sm: "40px", md: "90px" },
          //paddingLeft: isDown980 ? (isDown400 ? "20px" : "40px") : "",
          //paddingRight: isDown980 ? (isDown400 ? "20px" : "40px") : "",
        }}
      >
        <NavigationBar
          setOpenLogIn={setOpenLogIn}
          setOpenRegister={setOpenRegister}
        />
        <Hero aboutPage={aboutPage} />
      </Box>
      <Fundation aboutPage={aboutPage} />
      <FundationNumbers aboutPage={aboutPage} />
      <Box
        sx={{
          backgroundImage: !isDown1199 && `url(${semicircle})`,
          backgroundRepeat: "no-repeat",
          backgroundPositionY: "bottom 40%",
          backgroundPositionX: "right",
          backgroundSize: isDown1390 ? "11% auto" : "",
          // backgroundColor:
          //   "radial-gradient(circle, rgba(255,255,255,1) 0%, rgba(246,243,252,1) 85%, rgba(255,255,255,1) 100%)",
        }}
      >
        <FinancialSection
          setOpenRegister={setOpenRegister}
          aboutPage={aboutPage}
        />
        <InitialSection
          setOpenRegister={setOpenRegister}
          aboutPage={aboutPage}
        />
      </Box>
      <LetsTalkSection aboutPage={aboutPage} />
      <Footer footer={footer} />

      {/* Modals */}
      <LogInModal
        open={openLogIn}
        handleOpen={setOpenLogIn}
        handleOpenRegister={setOpenRegister}
        handleOpenPassRecovery={setOpenPassRecovery}
        handleOpenSingInStep={setOpenSignInSteps}
        login={login}
      />
      <RegisterModal
        open={openRegister}
        handleOpenLogIn={setOpenLogIn}
        handleOpenPassRecovery={setOpenPassRecovery}
        handleOpenSignUpSteps={setOpenSignUpSteps}
        handleOpenSignIn={setOpenLogIn}
        handleOpenSignInStep={setOpenSignInSteps}
        handleOpenSignUp={setOpenRegister}
        register={register}
        // open={openRegister}
        // handleOpen={setOpenRegister}
        // handleOpenLogIn={setOpenLogIn}
        // handleOpenPassRecovery={setOpenPassRecovery}
        // handleOpenSignUpSteps={setOpenSignUpSteps}
      />

      <PasswordRecoveryModal
        open={openPassRecovery}
        handleOpen={setOpenPassRecovery}
        handleOpenLogIn={setOpenLogIn}
      />

      <SignUpModal
        open={openSignUpSteps}
        handleOpen={setOpenSignUpSteps}
        login={login}
      />
      <SignInModal
        open={openSignInSteps}
        handleOpen={setOpenSignInSteps}
        register={register}
      />
    </>
  );
};

export default AboutUs;
