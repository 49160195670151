import { Box, Modal, useMediaQuery, Dialog } from "@mui/material";
import CreateDreamForm from "../dreamerPage/createDreamForm/CreateDreamForm";

const CreateDreamModal = ({ openModal, handleOpen }) => {
  const isDown1200 = useMediaQuery("(max-width:1200px)");

  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      open={openModal}
      onClose={() => handleOpen(false)}
    >
      <Box
        className="d-flex justify-content-center align-items-center"
        sx={{
          //width: isDown1200 ? "50%" : "100%",
          maxWidth: 1300,
          //minWidth: 325,
          // position: "absolute",
          // top: "50%",
          // left: "50%",
          // transform: "translate(-50%, -50%)",
          padding: "20px",
          borderRadius: "20px",
          backgroundColor: "#fff",
          //backgroundColor: "#f5f5f5",
        }}
      >
        <CreateDreamForm handleOpen={handleOpen} />
      </Box>
    </Dialog>
  );
};

export default CreateDreamModal;
