import { Box, Button, Modal, useMediaQuery } from "@mui/material";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import passwordRecoveryBg from "../../assets/img/reset-password-turtle.png";

import { LogInForm } from "../loginForm/LogInForm";
import { PasswordRecoveryForm } from "../passwordRecovery/PasswordRecoveryForm";

const PasswordRecoveryModal = ({ handleOpen, open, handleOpenLogIn }) => {
  const isDown1350 = useMediaQuery("(max-width:1350px)");
  const isDown1250 = useMediaQuery("(max-width:1250px)");
  const isDown1200 = useMediaQuery("(max-width:1200px)");

  return (
    <Modal open={open} onClose={() => handleOpen(false)}>
      <div
        className="d-flex align-items-center bg-white"
        style={{
          position: "absolute",
          maxWidth: 1200,
          width: isDown1200 ? "50%" : "85%",
          minWidth: 325,
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          borderRadius: "20px",
          minHeight: "770px",
        }}
      >
        <Grid container sx={{ height: "100%" }}>
          <Grid
            item
            lg={7}
            sx={{
              background: `url(${passwordRecoveryBg})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "auto 100%",
              borderRadius: "20px",
              position: "relative",
              minHeight: "770px",
            }}
          >
            <Box
              position="absolute"
              sx={{
                top: "50%",
                //left: "45%",
                left: isDown1350 ? (isDown1250 ? "52%" : "50%") : "45%",
                transform: "translate(-50%, -45%)",
                textAlign: "center",
                display: isDown1200 && "none",
              }}
            >
              <Typography sx={{ color: "#fff", mb: 2 }} variant="h5">
                Hola!
              </Typography>
              <Typography sx={{ color: "#fff", mb: 1 }} variant="body1">
                ¿Recuperar contraseña?
              </Typography>
              <Button
                variant="contained"
                onClick={() => {
                  handleOpen(false);
                  handleOpenLogIn(true);
                }}
                sx={{
                  borderRadius: "8px",
                  padding: "10px 15px",
                  width: "100%",
                  backgroundColor: "#0659fd",
                }}
              >
                Login
              </Button>
            </Box>
          </Grid>
          <Grid
            item
            lg={5}
            xs={12}
            sx={{
              padding: "60px 20px",
            }}
          >
            <PasswordRecoveryForm
              handleOpen={handleOpen}
              handleOpenLogIn={handleOpenLogIn}
            />
          </Grid>
        </Grid>
      </div>
    </Modal>
  );
};

export default PasswordRecoveryModal;
