import ShelterCause from "../../../assets/img/shelter-causes.png";
import HealthCause from "../../../assets/img/health-causes.png";
//import AnimalProtectionCause from "../../../assets/img/animal-protection-causes.svg";
import AnimalProtectionCause from "../../../assets/img/animal-protection-causes.png";
import EnviromentCause from "../../../assets/img/environment-causes.png";
import ArtCause from "../../../assets/img/art-causes.png";
import SportsCause from "../../../assets/img/sport-cause.png";

export const cardsFirstGrid = [
  { title: "Alberges", num: 12, component: <img src={ShelterCause} alt="" /> },
  {
    title: "Bienestar y Salud",
    num: 72,
    component: <img src={HealthCause} alt="" />,
  },
  {
    title: "Proteccion Animal",
    num: 81,
    component: <img src={AnimalProtectionCause} alt="" />,
  },
];

export const cardsFirst950Grid = [
  { title: "Alberges", num: 12, component: <img src={ShelterCause} alt="" /> },
  {
    title: "Bienestar y Salud",
    num: 72,
    component: <img src={HealthCause} alt="" />,
  },
  {
    title: "Proteccion Animal",
    num: 81,
    component: <img src={AnimalProtectionCause} alt="" />,
  },

  {
    title: "Recursos Naturales",
    num: 98,
    component: <img src={EnviromentCause} alt="" />,
  },
];

export const cardsSecond950Grid = [
  {
    title: "Arte",
    num: 83,
    component: <img src={ArtCause} alt="" />,
  },
  { title: "Deporte", num: 23, component: <img src={SportsCause} alt="" /> },
];

export const cardsSecondGrid = [
  {
    title: "Recursos Naturales",
    num: 98,
    component: <img src={EnviromentCause} alt="" />,
  },
  {
    title: "Arte",
    num: 83,
    component: <img src={ArtCause} alt="" />,
  },
  { title: "Deporte", num: 23, component: <img src={SportsCause} alt="" /> },
];

export const cardsWholeGrid = [
  { title: "Alberges", num: 12, component: <img src={ShelterCause} alt="" /> },
  {
    title: "Bienestar y Salud",
    num: 72,
    component: <img src={HealthCause} alt="" />,
  },
  {
    title: "Proteccion Animal",
    num: 81,
    component: <img src={AnimalProtectionCause} alt="" />,
  },

  {
    title: "Arte",
    num: 83,
    component: <img src={ArtCause} alt="" />,
  },
  { title: "Deporte", num: 23, component: <img src={SportsCause} alt="" /> },
];
