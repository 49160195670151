import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";

import MobileStepper from "@mui/material/MobileStepper";
import {
  FormControl,
  FormGroup,
  Checkbox,
  FormControlLabel,
} from "@mui/material";

import bgLeftCard from "../../../assets/img/bg-left-card.png";
import toledo from "../../../assets/img/toledo.png";

import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import { FaRegCircle, FaCheckCircle } from "react-icons/fa";
import { useEffect, useState } from "react";
import {
  createOneUserInnerCollection,
  getOneUserData,
  updateOneUser,
} from "../../../store/actions/userActions";
import { useDispatch, useSelector } from "react-redux";
import { logInUser, setIsDoingRegister } from "../../../store/actions/auth";
import { useNavigate } from "react-router-dom";
import LeftCard from "./LeftCard";

const SignUpSecondStep = ({ handleBack, handleClose }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { interests } = useSelector((state) => {
    // console.log(state); // Log the entire state
    return state.loggedUser.language;
  });
  const [activeStep, setActiveStep] = useState(1);
  const [selected, setSelected] = useState("");
  const [checkboxes, setCheckboxes] = useState([
    {
      id: 1,
      label: interests?.interests?.link1,
      name: "shelter",
      isCheck: false,
    },
    {
      id: 2,
      label: interests?.interests?.link2,
      name: "animalProtection",
      isCheck: false,
    },
    {
      id: 3,
      label: interests?.interests?.link3,
      name: "art",
      isCheck: false,
    },
    {
      id: 4,
      label: interests?.interests?.link4,
      name: "sport",
      isCheck: false,
    },
  ]);

  const currentUser = useSelector((state) => state.auth.user);

  const handleCheckboxChange = (id) => {
    setCheckboxes((prevCheckboxes) => {
      return prevCheckboxes.map((checkbox) => {
        if (checkbox.id === id) {
          return { ...checkbox, isCheck: !checkbox.isCheck };
        }
        return checkbox;
      });
    });
  };

  useEffect(() => {
    const checkInterestSelection = () => {
      const hasSelection = checkboxes.some((interest) => {
        return interest.isCheck === true;
      });

      setSelected(hasSelection);
    };

    checkInterestSelection();
  }, [checkboxes]);

  const handleSumbit = (event) => {
    event.preventDefault();

    const interests = checkboxes
      .filter((interest) => {
        return interest.isCheck;
      })
      .map((item) => {
        return item.label;
      });

    const metadata = {
      interests,
    };

    const userData = {
      infoCompleted: true,
      isActive: true,
    };

    dispatch(logInUser(currentUser, true));
    sessionStorage.setItem("loggedIn", JSON.stringify(true));
    createOneUserInnerCollection(currentUser.uid, metadata, "metadata");
    updateOneUser(currentUser.uid, userData);
    dispatch(getOneUserData(currentUser.uid));
    navigate("/redirect");
    handleClose(false);
  };

  return (
    <form onSubmit={(event) => handleSumbit(event)}>
      <Container
        maxWidth="xl"
        sx={{ boxShadow: "none", height: "auto", borderRadius: "20px" }}
      >
        <Grid container>
          <Grid item md={5} padding="40px">
            <LeftCard />
          </Grid>
          <Box width="70px"></Box>
          <Grid
            item
            xl={6}
            xs={12}
            padding={{ lg: "40px", xs: "20px 5px 40px 5px" }}
            display="flex"
            justifyContent="center"
          >
            <Box width={{ xl: "100%", md: "70%", sm: "100%" }}>
              <Stack
                direction="row"
                //width={{ md: "70%", sm: "70%" }}
                gap={{ sm: "", xs: 2 }}
                textAlign="center"
                justifyContent={{ lg: "space-between", md: "" }}
              >
                <span
                  onClick={handleBack}
                  style={{
                    borderRadius: "50%",
                    cursor: "pointer",
                    border: "1px solid #EAEAEA",
                    width: "30px",
                    height: "30px",
                    fontSize: "20px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    position: "absolute",
                    //marginRight: isDown900 ? "" : "auto",
                  }}
                >
                  <FiChevronLeft />
                </span>

                <Typography
                  variant="h5"
                  fontWeight="bold"
                  textAlign="center"
                  sx={{
                    display: "flex",
                    //flexGrow: 1,
                    width: "95%",
                    flexDirection: "column",
                    justifyContent: "end",
                    margin: "auto",
                  }}
                >
                  {interests?.header}
                </Typography>
              </Stack>

              <Typography
                textAlign="center"
                width={{ lg: "45%", sm: "100%" }}
                margin="10px auto"
                sx={{ color: "#6D6D78" }}
              >
                {interests?.title}
              </Typography>

              <Box sx={{ display: "flex" }}>
                <FormControl component="fieldset" variant="standard" fullWidth>
                  <FormGroup>
                    {checkboxes.map((interest) => {
                      return (
                        <Box
                          border="1px solid rgba(234, 234, 234, 1)"
                          borderRadius="20px"
                          padding="10px"
                          sx={{ my: 2 }}
                        >
                          <FormControlLabel
                            key={interest.id}
                            id={interest.id}
                            control={
                              <Checkbox
                                icon={
                                  <FaRegCircle
                                    size={30}
                                    color="rgba(234, 234, 234, 1)"
                                  />
                                }
                                checkedIcon={
                                  <FaCheckCircle color="#52c3ff" size={30} />
                                }
                                checked={interest.isCheck}
                                onChange={() =>
                                  handleCheckboxChange(interest.id)
                                }
                                name={interest.name}
                              />
                            }
                            label={interest.label}
                          />
                        </Box>
                      );
                    })}
                  </FormGroup>
                </FormControl>
              </Box>

              <Stack mt="20px">
                <MobileStepper
                  variant="dots"
                  itemProp=""
                  steps={2}
                  position="static"
                  activeStep={activeStep}
                  sx={{ maxWidth: 400, flexGrow: 1, margin: "0 auto" }}
                />
                <Button
                  type="submit"
                  disabled={!selected}
                  variant="contained"
                  sx={{
                    background: "#9168FB",
                    width: "100%",
                    padding: "10px",
                    marginTop: "20px",
                  }}
                >
                  {interests?.button} <FiChevronRight />
                </Button>
              </Stack>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </form>
  );
};

export default SignUpSecondStep;
