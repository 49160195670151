import { Box, Grid } from "@mui/material";

import { Outlet } from "react-router-dom";

import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import CloseSessionModal from "../../modals/CloseSessionModal";
import NavigationMenu from "../../navigationMenu/NavigationMenu";
import { BiHomeAlt2, BiUser, BiExit } from "react-icons/bi";
import { FiSettings } from "react-icons/fi";
import { BsColumnsGap } from "react-icons/bs";

const MakersHome = () => {
  const { general, makersPage } = useSelector((state) => {
    // console.log(state); // Log the entire state
    return state.loggedUser.language;
  });
  const [openEndSessionModal, setOpenEndSessionModal] = useState(false);

  const userData = useSelector((state) => state.loggedUser.data);

  useEffect(() => {
    const shouldOpenEndSessionModal = () => {
      setOpenEndSessionModal(true);
    };

    if (!userData.isActive) {
      shouldOpenEndSessionModal();
    } else {
      iconsList[2].url = `/home/maker/account/profile-info/${userData.id}`;
    }
  }, [userData]);
  const iconsList = [
    {
      icon: <BiHomeAlt2 size={24} />,
      url: "/home/maker",
      caption: makersPage?.navbar?.link1,
    },
    {
      icon: <BsColumnsGap size={24} />,
      url: "/home/maker/social",
      caption: makersPage?.navbar?.link2,
    },
    {
      icon: <BiUser size={24} />,
      url: `/home/maker/account/profile-info/${userData.id}`,
      caption: makersPage?.navbar?.link3,
    },
    {
      icon: <FiSettings size={24} />,
      url: "/home/maker/notifications-config",
      caption: makersPage?.navbar?.link4,
    },
    {
      icon: <BiExit size={24} />,
      url: "#",
      caption: makersPage?.navbar?.link5,
    },
  ];
  return (
    <Box bgcolor="#f7f7f7">
      {/* <MakersNavbar openSidebar={openSidebar} /> */}
      {/* <Drawer
        anchor="left"
        open={openSidebar}
        onClose={() => dispatch(toggleLeftMenu())}
        PaperProps={{
          sx: { width: { lg: "30%", md: "40%", sm: "60%", xs: "85%" } },
        }}
      >
        <Box width="100%" padding="30px">
          <Sidebar />
        </Box>
      </Drawer> */}

      <Box
        component="header"
        sx={{
          display: { lg: "none", xs: "block" },
          paddingLeft: "25px",
          paddingRight: "25px",
          background: "#9140f2",
        }}
      >
        <NavigationMenu iconsList={iconsList} mode="header" />
      </Box>
      <Grid container>
        <Grid item xl={1} md={1} xs={0}></Grid>

        <Grid
          item
          xl={1}
          md={1}
          sx={{ display: { md: "block", xs: "none" } }}
          width="200px"
          height="100vh"
          position="fixed"
        >
          <Box height="100vh" sx={{ display: { lg: "block", md: "none" } }}>
            <NavigationMenu iconsList={iconsList} bgColor="#9140f2" />
          </Box>
        </Grid>

        <Outlet />
      </Grid>
      {/* <NewsSection newsList={newsList} />
      <About />
      <Footer /> */}

      <CloseSessionModal
        open={openEndSessionModal}
        handleOpen={setOpenEndSessionModal}
      />
    </Box>
  );
};

export default MakersHome;
