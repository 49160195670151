import { Box, CircularProgress, Stack, Typography } from "@mui/material";
import TopUserActions from "../topUserActions/TopUserActions";
import PostComment from "../postComment/PostComment";
import { useState } from "react";
import { useEffect } from "react";
import {
  createComment,
  getOneComment,
  getPostComments,
} from "../../../store/actions/dreamers";
import InfiniteScroll from "react-infinite-scroll-component";
import CommentsInput from "../commentsInput/CommentsInput";
import { useFormik } from "formik";
import { timestampCreador } from "../../../firebase/firebase-config";
import { useSelector } from "react-redux";

const CommentsRightSection = ({
  setCommentsNumber,
  commentsNum,
  post,
  setNotificationsMod,
}) => {
  const { comments } = useSelector((state) => {
    // console.log(state); // Log the entire state
    return state.loggedUser.language;
  });
  const [lastComment, setLastComment] = useState(null);
  const [batchCommentsNumber, setBatchCommentsNumber] = useState(100);
  const [postComments, setPostComments] = useState([]);
  const [commentCreatedId, setCommentCreatedId] = useState(0);
  //const [commentsNumber, setCommentsNumber] = useState(0);
  const [openPopupMenu, setOpenPopupMenu] = useState(false);
  const [hasLike, setHasLike] = useState(false);

  const currentUserData = useSelector((state) => state.loggedUser.data);

  const BATCH_SIZE = 6;

  const { getFieldProps, handleSubmit, values, setFieldValue } = useFormik({
    initialValues: {
      comment: "",
    },
    onSubmit: async (values) => {
      const commentData = {
        cdate: timestampCreador.fromDate(new Date()),
        content: values.comment,
        userRef: currentUserData.id,
        isActive: true,
      };

      const newDocID = await createComment(
        post.userOwnerId,
        post.id,
        commentData
      );
      setCommentCreatedId(newDocID);
    },
  });

  const getComments = async () => {
    const comments = await getPostComments(
      post.userOwnerId,
      post.id,
      BATCH_SIZE,
      lastComment
    );
    setPostComments((prevComments) => [...prevComments, ...comments]);
    setLastComment(comments[comments.length - 1]);
    setBatchCommentsNumber(comments.length);
  };

  useEffect(() => {
    if (batchCommentsNumber >= BATCH_SIZE) {
      getComments();
    }
  }, []);

  useEffect(() => {
    const getRecentlyCreatedComment = async () => {
      const data = await getOneComment(
        post.userOwnerId,
        post.id,
        commentCreatedId
      );

      setPostComments((prevComments) => {
        prevComments.unshift({ ...data, commentOwnerData: currentUserData });

        return prevComments.slice();
      });

      setCommentsNumber((prevValue) => prevValue + 1);
    };

    if (commentCreatedId) {
      getRecentlyCreatedComment();
    }
  }, [commentCreatedId]);

  return (
    <Box
      bgcolor="#ffffff"
      height={{ xs: "100%", lg: "100vh" }}
      padding="25px 40px"
      fontSize="14px"
      sx={{
        borderTopLeftRadius: "32px",
        borderBottomLeftRadius: { xs: 0, lg: "32px" },
        borderTopRightRadius: { xs: "32px", lg: 0 },
      }}
    >
      <Stack>
        <Box marginLeft="auto" my={2}>
          <TopUserActions
            openPopupMenu={openPopupMenu}
            setOpenPopupMenu={setOpenPopupMenu}
            setNotificationsMod={setNotificationsMod}
          />
        </Box>
      </Stack>

      <Typography variant="h5">
        {comments?.comments} ({commentsNum})
      </Typography>
      <Stack id="scrollableStack" overflow="scroll" maxHeight="75vh">
        <InfiniteScroll
          scrollableTarget="scrollableStack"
          style={{ scrollbarWidth: "none" }}
          dataLength={postComments.length} //This is important field to render the next data
          next={getComments}
          hasMore={batchCommentsNumber >= BATCH_SIZE}
          loader={
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              height="100px"
            >
              <CircularProgress />
            </Stack>
          }
          endMessage={
            <p style={{ textAlign: "center", marginTop: "20px" }}>
              <b>{comments?.noMoreComments} 🙃 </b>
            </p>
          }
        >
          {postComments.map((comment) => {
            const img = comment?.commentOwnerData?.imgURL;
            const name = comment?.commentOwnerData?.name;
            return (
              <PostComment
                data={comment}
                postId={post.id}
                key={comment.id}
                hasLike={hasLike}
                setHasLike={setHasLike}
                // commentOwner={{ img, name }}
                // commentContent={comment.content}
                // commentDate={comment.cdate}
              />
            );
          })}
        </InfiniteScroll>
      </Stack>

      <Box mt={2}>
        <CommentsInput
          handleSubmit={handleSubmit}
          getFieldProps={getFieldProps}
          setFieldValue={setFieldValue}
          values={values}
        />
      </Box>
    </Box>
  );
};

export default CommentsRightSection;
