import {
  Box,
  CircularProgress,
  Grid,
  Stack,
  Tabs,
  Typography,
} from "@mui/material";

import { FiMenu } from "react-icons/fi";
import CustomTab from "../userContent/CustomTab";
import TabPanel from "../../makerPage/tabPanel/TabPanel";
import { useState } from "react";
import EventsCard from "../eventCard/EventCard";
import InfiniteScroll from "react-infinite-scroll-component";

const EventsMainContent = ({
  allEvents,
  nextEventsAllTab,
  eventsNumer,
  monthEvents,
  nextEventsMonthTab,
  monthlyEventsNumber,
  weekEvents,
  nextEventsWeekTab,
  weekEventsNumber,
  setOpenRightSidebar,
}) => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const BATCH_SIZE = 5;

  return (
    <Box component="main" bgcolor="#f7f7f7">
      <Stack direction="row" alignItems="center">
        <Typography variant="h4" fontWeight="bold" mr="auto">
          Eventos
        </Typography>

        <Box
          component="span"
          display={{ xs: "block", md: "none" }}
          sx={{ cursor: "pointer" }}
          onClick={() => setOpenRightSidebar(true)}
        >
          <FiMenu size={21} />
        </Box>
      </Stack>

      <Stack direction="row" alignItems="center" height="110px">
        <Tabs
          value={value}
          variant="scrollable"
          allowScrollButtonsMobile
          onChange={handleChange}
          indicatorColor="none" // Remove the default indicator
        >
          <CustomTab label="Todo" value={0} style={{ marginLeft: 0 }} />
          <CustomTab label="Mensual" value={1} />
          <CustomTab label="Semanal" value={2} />
        </Tabs>
      </Stack>

      <TabPanel value={value} index={0} panelWidth={{ lg: "100%", xs: "100%" }}>
        <InfiniteScroll
          style={{ scrollbarWidth: "none" }}
          dataLength={allEvents.length} //This is important field to render the next data
          next={nextEventsAllTab}
          hasMore={eventsNumer >= BATCH_SIZE}
          loader={
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              height="200px"
            >
              <CircularProgress />
            </Stack>
          }
          endMessage={
            <p style={{ textAlign: "center", marginTop: "20px" }}>
              <b>Parece que no hay más eventos 😐 </b>
            </p>
          }
        >
          <Grid container spacing={4}>
            {allEvents.map((event) => {
              return (
                <Grid item sm={12} md={6} key={event.id}>
                  <EventsCard eventData={event} />
                </Grid>
              );
            })}
          </Grid>
        </InfiniteScroll>
      </TabPanel>

      <TabPanel value={value} index={1} panelWidth={{ lg: "100%", xs: "100%" }}>
        <InfiniteScroll
          style={{ scrollbarWidth: "none" }}
          dataLength={monthEvents.length} //This is important field to render the next data
          next={nextEventsMonthTab}
          hasMore={monthlyEventsNumber >= BATCH_SIZE}
          loader={
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              height="200px"
            >
              <CircularProgress />
            </Stack>
          }
          endMessage={
            <p style={{ textAlign: "center", marginTop: "20px" }}>
              <b>Parece que no hay más eventos 😐 </b>
            </p>
          }
        >
          <Grid container spacing={4}>
            {monthEvents.map((event) => {
              return (
                <Grid item sm={12} md={6} key={event.id}>
                  <EventsCard eventData={event} />
                </Grid>
              );
            })}
          </Grid>
        </InfiniteScroll>
      </TabPanel>

      <TabPanel value={value} index={2} panelWidth={{ lg: "100%", xs: "100%" }}>
        <InfiniteScroll
          style={{ scrollbarWidth: "none" }}
          dataLength={weekEvents.length} //This is important field to render the next data
          next={nextEventsWeekTab}
          hasMore={weekEventsNumber >= BATCH_SIZE}
          loader={
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              height="200px"
            >
              <CircularProgress />
            </Stack>
          }
          endMessage={
            <p style={{ textAlign: "center", marginTop: "20px" }}>
              <b>Parece que no hay más eventos 😐 </b>
            </p>
          }
        >
          <Grid container spacing={4}>
            {weekEvents.map((event) => {
              return (
                <Grid item sm={12} md={6} key={event.id}>
                  <EventsCard eventData={event} />
                </Grid>
              );
            })}
          </Grid>
        </InfiniteScroll>
      </TabPanel>
    </Box>
  );
};

export default EventsMainContent;
