import { Box, Button, Grid, Typography, useMediaQuery } from "@mui/material";

import people from "../../../assets/img/initial-section-people.jpg";

import InitialCard from "./initialCard";

const InitialSection = ({ setOpenRegister, aboutPage }) => {
  const isDown1399 = useMediaQuery("(max-width:1399px)");
  const isDown980 = useMediaQuery("(max-width:980px)");
  const isDown590 = useMediaQuery("(max-width:590px)");
  const isDown400 = useMediaQuery("(max-width:400px)");

  return (
    <Box
      component={"section"}
      sx={{
        paddingLeft: isDown980 ? (isDown400 ? "20px" : "40px") : "",
        paddingRight: isDown980 ? (isDown400 ? "20px" : "40px") : "",
      }}
    >
      <Grid container gap={isDown1399 ? 15 : 8}>
        <Grid item lg="5" xs={12}>
          <Box
            position="relative"
            width={isDown590 ? "95%" : "505px"}
            margin={{ xs: "auto", lg: "0" }}
          >
            <InitialCard />
            <img src={people} alt="" width="100%" />
          </Box>
        </Grid>
        <Grid item lg="5">
          <Typography variant={isDown590 ? "h4" : "h3"} fontWeight="bold">
            {aboutPage?.section5.title}
          </Typography>
          <Typography
            variant="body1"
            sx={{ color: "#666666" }}
            lineHeight="28px"
            my={3}
          >
            {aboutPage?.section4.text}
          </Typography>

          <Button
            variant="contained"
            sx={{ backgroundColor: "#8828ff" }}
            onClick={() => setOpenRegister(true)}
          >
            {aboutPage?.section4.button}
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default InitialSection;
