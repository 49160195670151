import { Grid, Stack, Typography } from "@mui/material";
import DreamerCard from "../dreamerCard/DreamerCard";
import PhotoCollectionPostCard from "../photoCollectionPost/PhotoCollectionPostCard";
import VideoPostCard from "../videoPost/VideoPostCard";

const SearchResults = ({ searchResults }) => {
  const { dreamers, posts } = searchResults;
  const isAnyDreamer = dreamers.length;
  const isAnyPost = posts.length;

  return (
    <div style={{ minHeight: "100vh" }}>
      <Stack>
        <Typography variant="h4" fontWeight="bold" my={4}>
          Soñadores
        </Typography>
        {isAnyDreamer ? (
          dreamers.map((dreamer) => {
            return (
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} xl={4}>
                  <DreamerCard dreamerData={dreamer} />
                </Grid>
              </Grid>
            );
          })
        ) : (
          <Stack direction="row">
            <h5>No hay soñador video por aquí ಠ_ಠ</h5>
          </Stack>
        )}
      </Stack>

      <Stack>
        <Typography variant="h4" fontWeight="bold" my={4}>
          Publicaciones
        </Typography>
        <Grid container>
          {isAnyPost ? (
            posts.map((post) => {
              return post.type === "image" ? (
                <Grid item xs={12} md={6}>
                  <PhotoCollectionPostCard
                    likesNumber={326}
                    data={post}
                    key={post.id}
                  />
                </Grid>
              ) : (
                <Grid item xs={12} md={6}>
                  <VideoPostCard likesNumber={326} data={post} key={post.id} />
                </Grid>
              );
            })
          ) : (
            <Stack direction="row">
              <h5>No hay publicaciones por aquí ಠ_ಠ</h5>
            </Stack>
          )}
        </Grid>
      </Stack>
    </div>
  );
};

export default SearchResults;
