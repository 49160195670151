import { useState } from "react";
import { useEffect } from "react";

export const useDateValue = (filterType) => {
  const [dateValue, setDateValue] = useState(null);

  // switch (filterType) {
  //   case "all":
  //     return null;
  //   // setDateValue(null);
  //   // break;

  //   case "lastHour":
  //     const oneHourAgo = new Date();
  //     oneHourAgo.setHours(oneHourAgo.getHours() - 1);
  //     return oneHourAgo;
  //   // setDateValue(oneHourAgo);
  //   // break;

  //   case "today":
  //     const today = new Date();
  //     return today;
  //   // setDateValue(today);
  //   // break;

  //   case "thisWeek":
  //     const firstWeekDay = new Date(
  //       today.setDate(today.getDate() - today.getDay())
  //     );

  //     return firstWeekDay;
  //   // setDateValue(firstWeekDay);
  //   // break;

  //   case "thisMonth":
  //     const firstMonthDay = new Date(today.getFullYear(), today.getMonth(), 1);

  //     return firstMonthDay;
  //   // setDateValue(firstMonthDay);
  //   // break;

  //   case "thisYear":
  //     const firstYearDay = new Date(new Date().getFullYear(), 0, 1);

  //     return firstYearDay;
  //   // setDateValue(firstYearDay);
  //   // break;

  //   default:
  //     break;
  // }

  //const today = new Date();

  // useEffect(() => {
  switch (filterType) {
    case "all":
      return null;
      //setDateValue(null);
      break;

    case "lastHour":
      const oneHourAgo = new Date();
      oneHourAgo.setHours(oneHourAgo.getHours() - 1);
      return oneHourAgo;
      //setDateValue(oneHourAgo);
      break;

    case "today":
      const today = new Date();
      return today;
      //setDateValue(today);
      break;

    case "thisWeek":
      const weekAgo = new Date();
      const firstWeekDay = new Date(
        weekAgo.setDate(weekAgo.getDate() - weekAgo.getDay())
      );

      return firstWeekDay;
      //setDateValue(firstWeekDay);
      break;

    case "thisMonth":
      const monthAgo = new Date();
      const firstMonthDay = new Date(
        monthAgo.getFullYear(),
        monthAgo.getMonth(),
        1
      );

      return firstMonthDay;
      //setDateValue(firstMonthDay);
      break;

    case "thisYear":
      const yearAgo = new Date();
      const firstYearDay = new Date(yearAgo.getFullYear(), 0, 1);

      return firstYearDay;
      //setDateValue(firstYearDay);
      break;

    default:
      break;
  }
  // }, [filterType]);

  return dateValue;
};
