import { useState } from "react";
import React from "react";
import IconButton from "@mui/material/IconButton";
import { MdDelete } from "react-icons/md";
import { styles } from "./styles";

const Preview = ({ files = [], removeAll, removeOne }) => {
  const randomIntFromInterval = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1) + min);
  };

  const randomKeyIconBtn = randomIntFromInterval(1, 20);
  const randomKeyDeleteBtn = randomIntFromInterval(1, 20);

  const filepath = files.map(
    (file, index) =>
      // <li key={file.path}>
      (file?.preview || file) && (
        <li key={index}>
          {file?.path ?? "Imagen " + (index + 1)} - {file?.size ?? ""} bytes{" "}
          {removeOne && (
            <IconButton
              color="error"
              onClick={() => removeOne(index)}
              //key={randomKeyIconBtn}
            >
              <MdDelete size={30} key={randomKeyDeleteBtn} />
            </IconButton>
          )}
        </li>
      )
  );
  const thumbs = files.map(
    (file, index) =>
      (file?.preview || file) && (
        <div style={styles.thumb} key={index}>
          <div style={styles.thumbInner}>
            <img
              src={file.preview ? file.preview : file}
              style={styles.img}
              alt="upload"
            />
          </div>
        </div>
      )
  );
  return (
    <div className="w-50 center flex-column mt-3">
      <aside style={styles.modalThumbsContainer}>
        <ul className="w-100 center flex-column" style={{ paddingLeft: 0 }}>
          {thumbs} {filepath}
        </ul>
      </aside>
    </div>
  );
};

export default Preview;
