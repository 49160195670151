import { Box, Stack } from "@mui/material";

import noImagePlaceholder from "../../../assets/img/avatar-no-image.png";
import EventUserActions from "../eventUserActions/EventUserActions";

const EventHeader = ({ userData, eventData }) => {
  return (
    <>
      <Stack
        direction={{ xs: "column", sm: "row" }}
        alignItems="center"
        width="100%"
        my={3}
      >
        <Stack
          direction="row"
          gap={1}
          alignItems="center"
          mb={2}
          width={{ xs: "100%", sm: "auto" }}
        >
          <span
            style={{ width: "40px", height: "40px", cursor: "pointer" }}
            onClick={() => {}}
          >
            <img
              src={userData?.img || noImagePlaceholder}
              alt=""
              style={{
                objectFit: "cover",
                borderRadius: "12px",
                width: "100%",
                height: "100%",
              }}
            />
          </span>
          <div>
            <span
              style={{
                fontWeight: "bold",
                display: "block",
              }}
            >
              {userData?.name}
            </span>
            <small>
              {eventData?.cdate?.toDate().toLocaleDateString("CR", {
                year: "numeric",
                month: "long",
                day: "numeric",
              })}
            </small>
          </div>
        </Stack>

        <Box ml={{ xs: 0, sm: "auto" }} width={{ xs: "100%", sm: "auto" }}>
          <EventUserActions />
        </Box>
      </Stack>
    </>
  );
};

export default EventHeader;
