import React from "react";
import { useState } from "react";
import { Box, Button, Menu, MenuItem, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setLanguage } from "../../store/actions/userActions";
import Colors from "../../utils/Colors";
import { FlagIcon } from "react-flag-kit";

export const LanguageDropdown = ({ color, borderColor }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useDispatch();
  const language = useSelector((state) => state.loggedUser.language);

  const { english, spanish, lang } = language;

  const handleLanguageClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleLanguageClose = (lang) => {
    setAnchorEl(null);
    dispatch(setLanguage(lang));
  };

  return (
    <div>
      <Button
        aria-controls="language-menu"
        aria-haspopup="true"
        onClick={handleLanguageClick}
        sx={{
          color: color ? color : "#fff",
          backgroundColor: "#00045",
          border: borderColor ? `solid 1px ${borderColor}` : "solid 1px white", // Use template literals for dynamic values

          px: 2,
          fontSize: 14,
        }}
      >
        {/* Use the Flag component to display flags */}
        {lang === "en" ? (
          <FlagIcon code="GB-ENG" size={22} />
        ) : (
          <FlagIcon code="ES" size={22} />
        )}
        {lang === "en" ? (
          <Typography variant="body1" sx={{ fontSize: 14 }} ml={2}>
            {english}
          </Typography>
        ) : (
          <Typography variant="body1" sx={{ fontSize: 14 }} ml={2}>
            {spanish}
          </Typography>
        )}
      </Button>
      <Menu
        id="language-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => handleLanguageClose(lang)}
        sx={{ backgroundColor: "#00045 !important" }}
      >
        {lang === "es" && (
          <MenuItem onClick={() => handleLanguageClose("en")}>
            <FlagIcon code="GB-ENG" size={22} />
            <Box ml={2}>{english}</Box>
          </MenuItem>
        )}
        {lang === "en" && (
          <MenuItem onClick={() => handleLanguageClose("es")}>
            <FlagIcon code="ES" size={22} />
            <Box ml={2}>{spanish}</Box>
          </MenuItem>
        )}
      </Menu>
    </div>
  );
};

export default LanguageDropdown;
