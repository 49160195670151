import {
  Box,
  Button,
  Container,
  FormControl,
  FormHelperText,
  Grid,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { createImagePostSchema } from "../../../schema/createImagePostSchema/createImagePostSchema";
import Dragndrop from "../dropzone/DropZone";
import Preview from "../dropzone/Preview";

const acceptedFileType = { "image/*": [".jpeg", ".png"] };

const UploadImageForm = ({
  handleOpen,
  setImgFiles,
  setPostType,
  imgLimit,
  title,
}) => {
  const [files, setFiles] = useState([]);

  const {
    handleChange,
    handleSubmit,
    values,
    errors,
    touched,
    handleBlur,
    setFieldError,
  } = useFormik({
    initialValues: {
      postName: "",
      description: "",
      currentDreamId: "",
      file: files,
    },
    validationSchema: createImagePostSchema,
    onSubmit: async (values) => {
      setPostType(true);
      setImgFiles(files);
      handleOpen(false);
    },
    validate: (values) => {
      const errors = {};
      if (files.length === 0) {
        errors.file = "Ninguna imágen ha sido seleccionada";
      }
      return errors;
    },
  });

  const onDrop = useCallback(
    (acceptedFiles) => {
      const imagesArray = acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: typeof file === "string" ? file : URL.createObjectURL(file),
        })
      );

      setFiles(files.concat(imagesArray));
    },
    [setFiles, files]
  );

  const removeItem = (index) => {
    const newFileList = [...files];

    newFileList.splice(index, 1);
    setFiles(newFileList);
  };

  // useEffect(() => {
  //   const getDreamList = () => {
  //     const userDreamList = dreams.filter(
  //       (dream) => dream.userOwnerId === currenUser.id
  //     );

  //     setUserDreamList(userDreamList);
  //   };

  //   if (dreams) {
  //     getDreamList();
  //   }
  // }, [dreams, currenUser]);

  // useEffect(() => {
  //   if (files.length === 0) {
  //     setFieldError("file", "Ninguna imágen a sido seleccionada");
  //   }
  // }, [files]);

  return (
    <Container>
      <form
        onSubmit={(event) => {
          event.preventDefault(event);
          handleSubmit();
        }}
      >
        <Grid container>
          <Grid item xs={12}>
            <Typography fontWeight="bold" variant="h5" component="h1" mb={7}>
              {title}
            </Typography>

            <FormControl
              variant="outlined"
              sx={{ width: "100%", padding: 0, mt: 5 }}
            >
              {files.length < imgLimit && (
                <Dragndrop
                  onDrop={onDrop}
                  accept={acceptedFileType}
                  maxFiles={imgLimit}
                />
              )}

              <div className="d-flex justify-content-center">
                <Preview files={files} removeOne={removeItem} />
              </div>

              <FormHelperText error id="outlined-images">
                {touched.file && errors.file ? errors.file : " "}
              </FormHelperText>
            </FormControl>

            <Box>
              <Button
                type="submit"
                variant="contained"
                sx={{ margin: "auto", display: "block" }}
              >
                Cargar archivo
              </Button>
            </Box>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
};

export default UploadImageForm;
