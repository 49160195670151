import { Drawer, Grid } from "@mui/material";
import Searchbar from "../../components/dreamerPage/searchbar/SearchBar";
import Feed from "../../components/dreamerPage/Feed/Feed";
import RightMenu from "../../components/RightMenu/RightMenu";
import RightNotificationMenu from "../../components/RightMenu/RightNotificationMenu";
import { Animated } from "react-animated-css";
import { useState } from "react";

import noImagePlaceholder from "../../assets/img/avatar-no-image.png";
import { useEffect } from "react";
import { searchQuery } from "../../store/actions/dreamers";
import { useSelector } from "react-redux";
import SearchResults from "../../components/dreamerPage/searchResults/SearchResults";
import { useDateValue } from "../../components/dreamerPage/searchbar/useDateValue";

const DreamersHomePage = () => {
  const currentUser = useSelector((state) => state.loggedUser.data);
  const { general, dreamersPage } = useSelector((state) => {
    // console.log(state); // Log the entire state
    return state.loggedUser.language;
  });
  const [openNotificationsMod, setNotificationsMod] = useState(false);
  const [openPopupMenu, setOpenPopupMenu] = useState(false);
  const [openRightSidebar, setOpenRightSidebar] = useState(false);

  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState(null);
  const [searchTrigger, setSearchTrigger] = useState(false);

  //const filterType = useSelector((state) => state.dreamers.filterType);
  const [filterType, setFilterType] = useState("all");

  const dateValue = useDateValue(filterType);

  useEffect(() => {
    if (!searchTerm) {
      setSearchResults(null);
    }
  }, [searchTerm]);

  useEffect(() => {
    const searchData = async () => {
      const results = await searchQuery(searchTerm, currentUser.id);
      setSearchResults(results);
    };

    if (searchTerm) {
      searchData();
    }

    return () => setSearchResults(null);
  }, [searchTrigger]);

  const handleChange = (event) => {
    const val = event.target.value;

    setSearchTerm(val);
  };

  return (
    <>
      <Grid
        item
        xl={9}
        lg={8}
        md={12}
        xs={12}
        sx={{ padding: { md: "20px 40px", xs: "20px 25px" } }}
      >
        <form
          onSubmit={(event) => {
            event.preventDefault();
            setSearchTrigger((prevValue) => !prevValue);
          }}
        >
          <Searchbar
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            handleChange={handleChange}
            btnAction={() => setOpenRightSidebar(true)}
            filterType={filterType}
            setFilterType={setFilterType}
            general={general}
            dreamersPage={dreamersPage}
          />
        </form>

        {searchResults && searchTerm ? (
          <SearchResults searchResults={searchResults} />
        ) : (
          <Feed
            dateValue={dateValue}
            filterType={filterType}
            general={general}
            dreamersPage={dreamersPage}
          />
        )}
      </Grid>
      <Grid item xl={2} lg={3} position="fixed" sx={{ right: 0 }}>
        <Drawer
          anchor="right"
          open={openRightSidebar}
          onClose={() => setOpenRightSidebar(false)}
          PaperProps={{
            sx: {
              borderTopLeftRadius: "32px",
              borderBottomLeftRadius: "32px",
              width: { lg: "30%", md: "40%", sm: "60%", xs: "85%" },
            },
          }}
        >
          {openNotificationsMod ? (
            <RightNotificationMenu
              userPhoto={noImagePlaceholder}
              openPopupMenu={openPopupMenu}
              setNotificationsMod={setNotificationsMod}
              setOpenPopupMenu={setOpenPopupMenu}
            />
          ) : (
            <RightMenu
              userPhoto={noImagePlaceholder}
              openPopupMenu={openPopupMenu}
              setOpenPopupMenu={setOpenPopupMenu}
              setNotificationsMod={setNotificationsMod}
              setOpenRightSidebar={setOpenRightSidebar}
            />
          )}
        </Drawer>

        <Grid
          item
          xl={2}
          lg={3}
          position="fixed"
          sx={{ right: 0, width: "100%" }}
          display={{ xs: "none", lg: "block" }}
        >
          {openNotificationsMod && (
            <Animated
              animationIn="fadeInRight"
              animationOut="fadeOutLeft"
              isVisible={true}
              animationInDuration={500}
              animationOutDuration={500}
              //animateOnMount={false}
            >
              <RightNotificationMenu
                userPhoto={noImagePlaceholder}
                openPopupMenu={openPopupMenu}
                setNotificationsMod={setNotificationsMod}
                setOpenPopupMenu={setOpenPopupMenu}
              />
            </Animated>
          )}

          {!openNotificationsMod && (
            <Animated
              animationIn="fadeInRight"
              animationOut="fadeOutLeft"
              isVisible={true}
              animationInDuration={500}
              animationOutDuration={500}
              //animateOnMount={false}
            >
              <RightMenu
                userPhoto={noImagePlaceholder}
                openPopupMenu={openPopupMenu}
                setOpenPopupMenu={setOpenPopupMenu}
                setNotificationsMod={setNotificationsMod}
              />
            </Animated>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default DreamersHomePage;
