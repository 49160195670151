import { db, timestampCreador } from "../../firebase/firebase-config";
import { types } from "../types/types";

export const getEventBatch = (lastEvent, BATCH_SIZE) => {
  return async (dispatch) => {
    try {
      const eventsRef = db.collection("events");
      let query = eventsRef.orderBy("cdate", "desc").limit(BATCH_SIZE);

      if (lastEvent) {
        query = query.startAfter(lastEvent.cdate);
      }

      const snapshot = await query.get();
      const eventDocs = snapshot.docs;
      const events = [];

      for (const event of eventDocs) {
        const docId = event.id;

        events.push({
          id: docId,
          ...event.data(),
        });
      }

      dispatch(getEvents(events));
    } catch (error) {
      console.log(error);
    }
  };
};

export const getEventsFromCurrentMonth = (lastEvent, BATCH_SIZE) => {
  return async (dispatch) => {
    try {
      const today = new Date();

      const firstDay = new Date(today.getFullYear(), today.getMonth(), 1);
      const lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0);

      const eventsRef = db.collection("events");
      let query = eventsRef
        .orderBy("eventDate", "desc")
        .where("eventDate", ">=", firstDay)
        .where("eventDate", "<=", lastDay)
        .limit(BATCH_SIZE);

      if (lastEvent) {
        query = query.startAfter(lastEvent.eventDate);
      }

      const snapshot = await query.get();
      const eventDocs = snapshot.docs;
      const events = [];

      for (const event of eventDocs) {
        const docId = event.id;

        events.push({
          id: docId,
          ...event.data(),
        });
      }

      dispatch(getEventsByMonth(events));
    } catch (error) {
      console.log(error);
    }
  };
};

export const getEventsFromCurrentWeek = (lastEvent, BATCH_SIZE) => {
  return async (dispatch) => {
    try {
      const today = new Date();

      const firstDay = new Date(
        today.setDate(today.getDate() - today.getDay())
      );

      const lastDay = new Date(
        today.setDate(today.getDate() - today.getDay() + 7)
      );

      lastDay.setHours(23);
      lastDay.setMinutes(59);
      lastDay.setSeconds(59);

      const eventsRef = db.collection("events");
      let query = eventsRef
        .orderBy("eventDate", "desc")
        .where("eventDate", ">=", firstDay)
        .where("eventDate", "<=", lastDay)
        .limit(BATCH_SIZE);

      if (lastEvent) {
        query = query.startAfter(lastEvent.eventDate);
      }

      const snapshot = await query.get();
      const eventDocs = snapshot.docs;
      const events = [];

      for (const event of eventDocs) {
        const docId = event.id;

        events.push({
          id: docId,
          ...event.data(),
        });
      }

      dispatch(getEventsByWeek(events));
    } catch (error) {
      console.log(error);
    }
  };
};

export const getOneEvent = async (eventId) => {
  try {
    const event = await db.collection("events").doc(eventId).get();

    return event.data();
  } catch (error) {
    console.log(error);
  }
};

export const isLoading = (isLoading) => ({
  type: types.isLoadingEvents,
  payload: {
    isLoading,
  },
});

export const getEvents = (events) => ({
  type: types.getEvents,
  payload: {
    events,
  },
});

export const getEventsByMonth = (events) => ({
  type: types.getEventsByMonth,
  payload: {
    events,
  },
});

export const getEventsByWeek = (events) => ({
  type: types.getEventsByWeek,
  payload: {
    events,
  },
});
