import { Box, Button, Grid, Stack, useMediaQuery } from "@mui/material";

import { FiChevronLeft } from "react-icons/fi";
import UserProfileCard from "../userProfileCard/UserProfileCard";

import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getOneUser } from "../../../store/actions/userActions";
import TopUserActions from "../topUserActions/TopUserActions";
import UserContent from "../userContent/UserContent";
import { useSelector } from "react-redux";

const Profile = () => {
  const { general, makersPage } = useSelector(
    (state) => state.loggedUser.language
  );
  const isDown1440 = useMediaQuery("(max-width:1439px)");
  const isDown1200 = useMediaQuery("(max-width:1200px)");

  const navigation = useNavigate();
  const { id } = useParams();

  const [openPopupMenu, setOpenPopupMenu] = useState(false);
  const [userData, setUserData] = useState(null);
  const [openRightSidebar, setOpenRightSidebar] = useState(false);

  useEffect(() => {
    const getUserData = async () => {
      const data = await getOneUser(id);
      setUserData(data);
    };

    if (id) {
      getUserData();
    }
  }, [id]);

  const friends = [
    { id: 1 },
    { id: 2 },
    { id: 3 },
    { id: 4 },
    { id: 5 },
    { id: 6 },
    { id: 7 },
    { id: 8 },
    { id: 9 },
    { id: 10 },
  ];
  return (
    <Box bgcolor="#f7f7f7" overflow="hidden" width="100%">
      <Grid container display="flex" justifyContent="center">
        <Grid item lg={1} xs={0} height={{ md: "100vh", xs: 0 }}></Grid>
        <Grid
          item
          md={11}
          xs={12}
          sx={{
            padding: { lg: "20px 0 20px 40px", xs: "20px 32px 20px 32px" },
            //padding: { xs: "20px 32px 20px 32px" },
          }}
        >
          <Grid container rowGap={5}>
            <Grid
              item
              md={isDown1440 ? 12 : 3}
              xs={12}
              paddingRight={{ xs: 0, md: "20px" }}
            >
              {/* <Box maxWidth={isDown1440 ? "90%" : "320px"} margin="0 auto"> */}
              <Box
                margin={{ lg: "auto" }}
                maxWidth={isDown1440 ? (isDown1200 ? "100%" : "90%") : "320px"}
              >
                <Stack direction="row" alignItems="center">
                  <Button
                    variant="contained"
                    disableElevation
                    onClick={() => navigation(-1)}
                    sx={{
                      borderRadius: "12px",
                      backgroundColor: "#e2e3e6",
                      color: "#8F92A1",
                      width: "103px",
                      fontWeight: "bold",
                      my: 3,
                      mr: "auto",
                      py: 2,
                    }}
                  >
                    <FiChevronLeft />
                    {general?.goBack}
                  </Button>
                  <Box display={{ md: "none", xs: "block" }}>
                    <TopUserActions
                      openPopupMenu={openPopupMenu}
                      setOpenPopupMenu={setOpenPopupMenu}
                      setNotificationsMod={setOpenRightSidebar}
                      general={general}
                    />
                  </Box>
                </Stack>
                <Box>
                  <UserProfileCard
                    friends={friends}
                    userData={userData}
                    general={general}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid
              item
              md={isDown1440 ? 12 : 9}
              xs={12}
              paddingRight={{ xs: 0, md: "20px", lg: !isDown1440 ? 0 : "20px" }}
            >
              <Box
                margin="auto"
                //maxWidth={isDown1440 ? (!isDown1200 ? "100%" : "90%") : "auto"}
                //</Grid>maxWidth={!isDown1200 ? "90%" : "auto"}
                //margin={isDown1440 ? "0 auto" : "0"}
                maxWidth={isDown1440 ? (!isDown1200 ? "90%" : "auto") : "auto"}
              >
                <Box
                  bgcolor="#fff"
                  height="100vh"
                  width="100%"
                  padding={{ xs: "0 20px", sm: "0 60px" }}
                  //maxWidth={isDown1440 ? "90%" : "auto"}
                  // margin={isDown1440 ? "0 auto" : "0"}
                  sx={{
                    borderTopLeftRadius: "32px",
                    borderBottomLeftRadius: "32px",
                  }}
                >
                  <UserContent
                    userId={id}
                    openRightSidebar={openRightSidebar}
                    setOpenRightSidebar={setOpenRightSidebar}
                    general={general}
                    makersPage={makersPage}
                  />
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Profile;
