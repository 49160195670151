import React, { useMemo } from "react";
import IconButton from "@mui/material/IconButton";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import { useDropzone } from "react-dropzone";
import { styles } from "./styles";

import { FiUpload } from "react-icons/fi";
import { Stack } from "@mui/material";
import { useSelector } from "react-redux";

const Dragndrop = ({ onDrop, accept, maxFiles }) => {
  const {
    getRootProps,
    getInputProps,
    open,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept,
    onDrop,
    maxFiles,
    noClick: true,
  });
  const style = useMemo(
    () => ({
      ...styles.baseStyle,
      ...(isDragActive ? styles.activeStyle : {}),
      ...(isDragAccept ? styles.acceptStyle : {}),
      ...(isDragReject ? styles.rejectStyle : {}),
      flexDirection: "column",
    }),
    [isDragActive, isDragReject]
  );
  const { imgPicker } = useSelector((state) => {
    // console.log(state); // Log the entire state
    return state.loggedUser.language;
  });
  return (
    <>
      <Stack
        className="drag-n-drop w-100"
        direction="column"
        {...getRootProps({ style })}
      >
        <input {...getInputProps()} />
        <IconButton
          color="primary"
          aria-label="upload picture"
          component="span"
          sx={{ textIndent: 30 }}
          onClick={open}
        >
          <FiUpload />
        </IconButton>

        {isDragActive ? <p>{imgPicker?.drag}</p> : <p>{imgPicker?.dragb}</p>}
      </Stack>
    </>
  );
};

export default Dragndrop;
