import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";

import bgLeftCard from "../../assets/img/bg-left-card.png";
import tucan from "../../assets/img/about-us-page-tucan.png";

import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import { useSelector } from "react-redux";

const LeftCard = () => {
  const isDown1560 = useMediaQuery("(max-width:1560px)");
  const { landing, signUp } = useSelector((state) => {
    // console.log(state); // Log the entire state
    return state.loggedUser.language;
  });
  return (
    <Card
      variant="outline"
      sx={{
        backgroundImage: `url(${bgLeftCard})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 100%",
        width: "100%",
        height: "700px",
        padding: "15px",
        display: { xl: "flex", xs: "none" },
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <Grid
        container
        minHeight="350px"
        display="flex"
        alignItems="center"
        overflow="visible"
      >
        <Grid item xl="7">
          <CardContent>
            <Typography variant="h4" fontWeight="bold" sx={{ color: "#fff" }}>
              {signUp?.connect}
            </Typography>
          </CardContent>
        </Grid>

        <Grid item xl="5" position="relative">
          <img
            src={tucan}
            alt=""
            width="320px"
            style={{
              position: "absolute",
              top: -210,
              right: "-30px",
              zIndex: 100,
            }}
          />
        </Grid>
      </Grid>

      <Box
        sx={{
          color: "#fff",
          display: { xl: "flex", xs: "none" },
          flexDirection: "column",
          textAlign: "center",
          justifyContent: "flex-end",
        }}
      >
        <Stack height="175px" padding="0 90px">
          <Typography variant="h5" fontWeight="bold" mb={3} position="relative">
            {signUp?.possib}
            <div
              style={{
                position: "absolute",
                left: isDown1560 ? "30%" : "55%",
                backgroundColor: "#36fb90",
                height: "2px",
                width: "120px",
              }}
            ></div>
          </Typography>
          <Typography> {signUp?.start}</Typography>
        </Stack>
        <Stack
          direction="row"
          height="100px"
          justifyContent="center"
          spacing={2}
          marginTop="30px"
        >
          <span
            style={{
              borderRadius: "50%",
              border: "1px solid #EAEAEA",
              width: "50px",
              height: "50px",
              fontSize: "24px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "#36FB90",
            }}
          >
            <FiChevronLeft />
          </span>
          <span
            style={{
              borderRadius: "50%",
              border: "1px solid #EAEAEA",
              width: "50px",
              height: "50px",
              fontSize: "24px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "#36FB90",
            }}
          >
            <FiChevronRight />
          </span>
        </Stack>
      </Box>
    </Card>
  );
};

export default LeftCard;
