import { Stack } from "@mui/material";

import logo from "../../assets/img/logoSmile.png";

import facebookLogo from "../../assets/img/Facebook.svg";
import twitterLogo from "../../assets/img/twitter.svg";
import linkedinLogo from "../../assets/img/linkedin.svg";
import instagramLogo from "../../assets/img/instagram.svg";

const SmileUpDetails = ({ footer }) => {
  return (
    <>
      <div style={{ width: "180px", marginTop: "10px" }} className="align-top">
        <img src={logo} alt="smile up logo" width="100%" />
      </div>
      <p style={{ color: "#9b9ca8" }}>{footer?.title}</p>

      <Stack
        direction="row"
        gap={4}
        style={{ color: "#9b9ca8", marginTop: "50px" }}
      >
        <a
          href="https://www.facebook.com"
          target="_blank"
          className="social-icons"
        >
          <img src={facebookLogo} alt="facebook logo" />
        </a>
        <a
          href="https://www.twitter.com"
          target="_blank"
          className="social-icons"
        >
          <img src={twitterLogo} alt="twitter logo" />
        </a>
        <a
          href="https://www.instagram.com"
          target="_blank"
          className="social-icons"
        >
          <img src={instagramLogo} alt="Instagram logo" />
        </a>
        <a
          href="https://www.linkedin.com"
          target="_blank"
          className="social-icons"
        >
          <img src={linkedinLogo} alt="Linkedin logo" />
        </a>
      </Stack>
    </>
  );
};

export default SmileUpDetails;
