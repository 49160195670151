import { useEffect } from "react";

import { Navigate, Route, Routes } from "react-router";

import { LogIn } from "./../views/logIn/LogIn";
import { NoMatch } from "./../views/NoMatch";

import { Grid, useMediaQuery } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import DreamersHome from "../components/dreamerPage/dreamersHome/DreamersHome";
import Profile from "../components/dreamerPage/profile/Profile";
import DreamDetails from "../components/makerPage/dreamDetails/DreamDetails";
import Dreams from "../components/makerPage/dreams/Dreams";
import MakersHome from "../components/makerPage/makersHome/MakersHome";
import NotificationConfig from "../components/makerPage/notificationConfig/NotificationConfig";
import ProfileConfigOptions from "../components/makerPage/profileConfigOptions/ProfileConfigOptions";
import Support from "../components/makerPage/support/Support";
import ProfileInfo from "../components/profileInfo/ProfileInfo";
import { auth } from "../firebase/firebase-config";
import { logoutUser, setIsLoadingAuth } from "../store/actions/auth";
import {
  getOneUserData,
  getUserNotifications,
} from "../store/actions/userActions";
import AboutUsPage from "../views/AboutUsPage";
import ContactView from "../views/contact/Contact";
import Account from "../views/dreamers/account/Account";
import CreateDreamPage from "../views/dreamers/createDream/CreateDreamPage";
import DreamersHomePage from "../views/dreamers/DreamersHomePage";
import { Dreams as DreamerUserDreams } from "../views/dreamers/dreams/Dreams";
import EventDetails from "../views/dreamers/events/EventDetails";
import Events from "../views/dreamers/events/Events";
import FaqView from "../views/faq/FaqView";
import LandingPage from "../views/LandingPage";
import EditProfileInfo from "../views/makers/editProfileInfo/EditProfileInfo";
import Social from "../views/makers/social/Social";
import { MicrositePage } from "../views/MicrositePage";
import UserRedirect from "../views/userRedirect/UserRedirect";
import { PrivateRoute } from "./PrivateRoute";

const Router = () => {
  const isLoadingDreams = useSelector(
    (state) => state.dreamers.isLoadingDreams
  );
  const loggedIn = useSelector((state) => state.auth.loggedIn);

  const dispatch = useDispatch();

  const isDown700 = useMediaQuery("(max-width:700px)");
  const isDown580 = useMediaQuery("(max-width:580px)");

  useEffect(() => {
    auth.onAuthStateChanged(async (user) => {
      if (user) {
        dispatch(getOneUserData(user.uid));
        dispatch(getUserNotifications(user.uid));
        sessionStorage.setItem("user", JSON.stringify(user.toJSON()));
      } else {
        sessionStorage.removeItem("user");
        dispatch(logoutUser());
        dispatch(setIsLoadingAuth(false));
      }
    });
  }, [dispatch]);

  return (
    <Routes>
      <Route
        path=""
        element={
          loggedIn ? (
            <Navigate replace to="/redirect" />
          ) : (
            //<Navigate replace to="/home" />
            <Navigate replace to="/landing" />
          )
        }
      />
      <Route path="/microsite" element={<MicrositePage />} />
      <Route path="/redirect" element={<UserRedirect />} />
      <Route
        //path="/home/*"
        path="/home/maker/*"
        element={
          <PrivateRoute isLoggedIn={loggedIn}>
            <Routes>
              <Route path="/" element={<MakersHome />}>
                <Route
                  index
                  element={
                    <Dreams
                      //dreams={filteredDreams}
                      isLoadingDreams={isLoadingDreams}
                    />
                  }
                />
                <Route
                  path="dream-details/:userId/:dreamId"
                  element={<DreamDetails />}
                />

                <Route path="profile/:id" element={<Profile />} />
                <Route path="social" element={<Social />} />
                <Route path="account">
                  <Route path="profile-info/:id" element={<Profile />} />
                  <Route path="edit/:id" element={<EditProfileInfo />} />
                </Route>

                <Route
                  path="profile-config"
                  element={<ProfileConfigOptions />}
                />
                <Route
                  path="notifications-config"
                  element={<NotificationConfig />}
                />
                <Route path="support" element={<Support />} />
                <Route
                  path="notifications-config"
                  element={<NotificationConfig />}
                />
              </Route>

              {/* <Route path="dreamer" element={<DreamersHome />} /> */}

              {/* </Route> */}
            </Routes>
          </PrivateRoute>
        }
      />
      <Route
        //path="/dreamer/*"
        path="/home/dreamer/*"
        element={
          <PrivateRoute isLoggedIn={loggedIn}>
            <Routes>
              <Route path="/" element={<DreamersHome />}>
                <Route index element={<DreamersHomePage />} />
                <Route path="profile/:id" element={<Profile />} />
                <Route path="events" element={<Events />} />
                <Route path="event/:id" element={<EventDetails />} />
                <Route path="account" element={<Account />} />
                <Route path="create-dream" element={<CreateDreamPage />} />
                <Route path="my-dreams" element={<DreamerUserDreams />} />
                <Route path="account">
                  <Route index element={<Account />} />
                  <Route
                    path="profile-info"
                    element={
                      <Grid
                        xl={11}
                        lg={11}
                        md={11}
                        xs={12}
                        item
                        minHeight="100vh"
                        sx={{
                          padding: isDown700
                            ? isDown580
                              ? "30px 20px"
                              : "30px 40px"
                            : "70px 40px",
                        }}
                        //padding: isDown700 ? (isDown580 ? "0 20px" : "0 40px") : "0 40px",
                        //sx={{ padding: { md: "20px 60px", xs: "20px 25px" } }}
                      >
                        <ProfileInfo />
                      </Grid>
                    }
                  />
                  <Route
                    path="profile-config"
                    element={
                      <Grid
                        xl={9}
                        lg={8}
                        md={11}
                        xs={12}
                        item
                        minHeight="100vh"
                        sx={{ padding: { md: "20px 60px", xs: "20px 25px" } }}
                      >
                        <ProfileConfigOptions />
                      </Grid>
                    }
                  />
                  <Route
                    path="notifications-config"
                    element={<NotificationConfig />}
                  />
                </Route>
              </Route>
            </Routes>
          </PrivateRoute>
        }
      />
      {!loggedIn ? (
        <>
          <Route path="auth/login" element={<LogIn />} />
          <Route path="/landing" element={<LandingPage />} />
          <Route path="/about-us" element={<AboutUsPage />} />
          <Route path="/contact" element={<ContactView />} />
          <Route path="/frequently-ask" element={<FaqView />} />
        </>
      ) : null}

      <Route path="/landing" element={<UserRedirect />} />
      <Route path="/about-us" element={<UserRedirect />} />
      <Route path="/contact" element={<UserRedirect />} />
      <Route path="/frequently-ask" element={<UserRedirect />} />
      <Route path="*" element={<NoMatch />} />
    </Routes>
  );
};

export default Router;
