import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import CardActions from "@mui/material/CardActions";
import Card from "@mui/material/Card";
import { Typography, Box, useMediaQuery, useTheme } from "@mui/material";

const UserTypeCard = ({
  imgSource,
  title,
  content,
  openRegister,
  landing,
  general,
}) => {
  const isDown600 = useMediaQuery("(max-width:600px)");
  const theme = useTheme();
  return (
    <Card
      sx={{
        maxWidth: 550,
        minHeight: "75vh",
        width: "100%",
        zIndex: 200,
        position: "relative",
        px: "28px",
        pt: "28px",
        borderRadius: "16px",
        boxShadow: "0px 14px 18px -10px rgba(0,0,0,0.37)",
        backgroundColor:
          title === landing.makerSection.text2 ? "#8828ff" : "#18acfe",
        [theme.breakpoints.down("sm")]: {
          width: "100%",
        },
      }}
    >
      <CardHeader
        titleTypographyProps={{
          fontWeight: "bold",
          color: "#fff",
          fontSize: isDown600 ? "24px" : "36px",
        }}
        title={title}
      />
      <CardContent>
        <Typography variant="body1" color="#fff" sx={{ lineHeight: "28px" }}>
          {content}
        </Typography>
      </CardContent>

      <CardActions sx={{ my: 3 }}>
        <Button
          variant="contained"
          onClick={() => openRegister(true)}
          sx={{
            backgroundColor: "#fff",
            color: title === "Transformador" ? "#8828ff" : "#18acfe",
          }}
        >
          {landing.makerSection.registerBtn}
        </Button>
      </CardActions>
      <Box sx={{ p: 2 }}>
        <div style={{ borderRadius: "18px" }}>
          <div
            style={{
              objectFit: "cover",
              width: "250px",
              height: "250px",
              margin: "0 auto",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <img src={imgSource} alt="" />
          </div>
        </div>
      </Box>
    </Card>
  );
};

export default UserTypeCard;
