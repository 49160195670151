import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";
import { Box, Typography, Container } from "@mui/material";

const CauseCard = ({ title, causesNum, illustration }) => {
  return (
    <Card
      sx={{
        // width: "100%",
        // height: "100%",
        width: 270,
        height: 270,
        background: "rgba(136, 40, 255, 0.25)",
        borderRadius: "15px",
      }}
    >
      <CardContent>
        <Container>
          <div
            style={{
              objectFit: "cover",
              width: "170px",
              height: "170px",
              margin: "0 auto",
              display: "flex",
              justifyContent: "center",
            }}
          >
            {illustration}
          </div>
        </Container>
        <Typography color="#fff" fontWeight="bold" variant="h5">
          {title}
        </Typography>
        <Typography color="rgba(255, 255, 255, 0.79)">
          {causesNum} causas
        </Typography>
      </CardContent>
    </Card>
  );
};

export default CauseCard;
