import React from "react";
import {
  Box,
  Button,
  Dialog,
  Paper,
  useMediaQuery,
  useTheme,
  Grid,
  Typography,
} from "@mui/material";

import loginBg from "../../assets/img/login-sloth.png";
import { LogInForm } from "../loginForm/LogInForm";

const LogInModal = ({
  handleOpen,
  open,
  handleOpenRegister,
  handleOpenPassRecovery,
  handleOpenSingInStep,
  login,
}) => {
  const theme = useTheme();
  const isDown1350 = useMediaQuery("(max-width:1350px)");
  const isDown1250 = useMediaQuery("(max-width:1250px)");
  const isDown1200 = useMediaQuery("(max-width:1200px)");

  return (
    <Dialog
      open={open}
      onClose={() => handleOpen(false)}
      fullWidth
      maxWidth="lg"
      PaperComponent={(props) => (
        <Paper {...props} sx={{ borderRadius: "20px" }} />
      )}
      sx={{
        overflow: "scroll",
        background: "transparent",
        borderRadius: "36px !important",
        overflow: "hidden",
      }}
    >
      <Grid
        justifyContent="space-between"
        container
        sx={{
          width: "100%",
          padding: "0px!important",
          [theme.breakpoints.up("sm")]: {
            height: "800px",
          },
        }}
      >
        <Grid
          item
          lg={6}
          sx={{
            background: `url(${loginBg})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: isDown1250 ? "100% 100%" : "cover",
            borderRadius: "20px",
            position: "relative",
            display: isDown1200 && "none",
          }}
        >
          <Box
            position="absolute"
            sx={{
              top: "50%",
              left: isDown1350 ? (isDown1250 ? "52%" : "50%") : "50%",
              transform: "translate(-50%, -50%)",
              textAlign: "center",
              display: isDown1200 && "none",
            }}
          >
            <Typography sx={{ color: "#fff", mb: 2 }} variant="h5">
              {login?.hello}
            </Typography>
            <Typography sx={{ color: "#fff", mb: 1 }} variant="body1">
              {login?.registerText}
            </Typography>
            <Button
              variant="contained"
              onClick={() => {
                handleOpen(false);
                handleOpenRegister(true);
              }}
              sx={{
                borderRadius: "8px",
                padding: "10px 15px",
                width: "100%",
                backgroundColor: "#79d0f1",
              }}
            >
              {login?.registerBtn}
            </Button>
          </Box>
        </Grid>
        <Grid
          item
          lg={5}
          md={12}
          sx={{
            padding: "60px 20px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              width: "80%",
            }}
          >
            <Typography fontWeight="bold" variant="h5" component="h1">
              {login?.welcome}
            </Typography>
            <Typography
              variant="body1"
              sx={{ color: "rgba(143, 146, 161, 1)" }}
            >
              {login?.title + " "}
              {isDown1200 && (
                <>
                  <span>o </span>
                  <span
                    onClick={() => {
                      handleOpen(false);
                      handleOpenRegister(true);
                    }}
                    style={{ textDecoration: "underline", cursor: "pointer" }}
                  >
                    {login?.registerBtn}
                  </span>
                </>
              )}
            </Typography>
            <Box>
              <LogInForm
                login={login}
                handleOpen={handleOpen}
                handleOpenPassRecovery={handleOpenPassRecovery}
                handleOpenSingInStep={handleOpenSingInStep}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default LogInModal;
