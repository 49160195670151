import React, { Fragment, useState } from "react";

import Container from "@mui/material/Container";
import { Box, Stack, Typography, useTheme } from "@mui/material";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";

import IntroSection from "../introSection/introSection";
import FirstSpeechBubble from "../speechBubble/FirstSpeechBubbles";
import SecondSpeechBubble from "../speechBubble/SecondSpeechBubble";

import whoSectionTurtle from "../../../assets/img/who-section-turtle.png";
import semiCircle from "../../../assets/img/semi-circle-bg.png";

import ThirdSpeechBubble from "../speechBubble/ThirdSpeechBubble";
import WhoYouAreSection from "../whoYouAreSection/WhoYouAreSection";
import CausesSection from "../causesSection/CausesSection";
import BlogSection from "../blogSection/BlogSection";
import TestimonialSection from "../testimonialSection/TestimonialSection";
import Footer from "../../footer/Footer";
import RegisterModal from "../../modals/RegisterModal";
import LogInModal from "../../modals/LogInModal";
import PasswordRecoveryModal from "../../modals/PasswordRecoveryModal";
import SignUpModal from "../../SignUp/SignUpModal";

import { useMediaQuery } from "@mui/material";
import CausesResponsiveSection from "../causesSection/CausesResponsiveSection";
import HomeNavBar from "../navbar/HomeNavBar";

import SignInModal from "../../signIn/SignInModal";
import { useSelector } from "react-redux";
import Colors from "../../../utils/Colors";
import LeftBubble from "../speechBubble/LeftBubble";
import RightBubble from "../speechBubble/RightBubble";
import { comentaries } from "../../../utils/otherData";

const FirstPage = ({ styles }) => {
  const theme = useTheme();
  // Destructure lang and language from the Redux state
  const { login, register, landing, footer, aboutPage } = useSelector(
    (state) => {
      // console.log(state); // Log the entire state
      return state.loggedUser.language;
    }
  );
  const language = useSelector((state) => state.loggedUser.language);

  const { english, spanish, lang } = language;
  const [openRegister, setOpenRegister] = useState(false);
  const [openPassRecovery, setOpenPassRecovery] = useState(false);
  const [openLogIn, setOpenLogIn] = useState(false);
  const [openSignUpSteps, setOpenSignUpSteps] = useState(false);
  const [openSignInSteps, setOpenSignInSteps] = useState(false);

  const isDown1390 = useMediaQuery("(max-width:1390px)");
  const isDown1350 = useMediaQuery("(max-width:1350px)");
  const isDown1200 = useMediaQuery("(max-width:1200px)");
  const isUp1000 = useMediaQuery("(min-width:1000px)");
  const isDown900 = useMediaQuery("(max-width:900px)");
  const isDown950 = useMediaQuery("(max-width:950px)");
  const isDown800 = useMediaQuery("(max-width:800px)");
  const isDown700 = useMediaQuery("(max-width:700px)");
  const isDown680 = useMediaQuery("(max-width:680px)");
  const isDown550 = useMediaQuery("(max-width:550px)");
  const isDown490 = useMediaQuery("(max-width:490px)");
  const isDown400 = useMediaQuery("(max-width:400px)");
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <>
      <Box
        className={styles.pageOneContainer}
        id="main"
        style={
          isDown1390
            ? isDown550
              ? { padding: "10px 20px" }
              : { padding: "10px 40px" }
            : {}
        }
      >
        {/* <NavigationBar handleOpenSignUpSteps={setOpenSignUpSteps} /> */}
        <HomeNavBar />

        <Grid container sx={{ position: "relative", height: "100%" }}>
          <Grid
            item
            //lg={6}
            display="flex"
            alignItems="center"
            textAlign="center"
            xs={12}
            sx={{
              height: isDown1200 ? "auto" : "80vh",
            }}
          >
            <Box
              sx={{
                margin: "auto",
                [theme.breakpoints.down("md")]: {
                  height: "100%",

                  mt: 4,
                },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  margin: "auto",
                  [theme.breakpoints.down("md")]: {
                    marginLeft: "auto",
                    margin: "0",
                    justifyContent: "flex-start",
                    mb: 2,
                  },
                }}
              >
                <Box
                  component={"img"}
                  src={require("../../../assets/img/logoSmile.png")}
                  sx={{
                    width: "275px",
                    [theme.breakpoints.down("md")]: {
                      width: "325px",
                    },
                  }}
                  alt="Smile up logo"
                />
              </Box>{" "}
              <Box margin="auto" sx={{ width: { xs: "100%", md: "75%" } }}>
                <Typography
                  variant="h4"
                  sx={{
                    fontWeight: "bold",
                    color: "#fff",
                    [theme.breakpoints.down("lg")]: {
                      fontSize: 22,
                    },
                    [theme.breakpoints.down("md")]: {
                      fontSize: 26,
                      textAlign: "left",
                    },
                  }}
                >
                  {landing.title}
                </Typography>
                {/* <Typography
                  variant="h4"
                  sx={{
                    fontWeight: "bold",
                    color: "#fff",
                    [theme.breakpoints.down("lg")]: {
                      fontSize: 22,
                    },
                    [theme.breakpoints.down("md")]: {
                      fontSize: 22,
                      textAlign: "left",
                    },
                  }}
                >
                  {landing.title2}
                </Typography> */}
              </Box>
              <Typography
                variant="h6"
                color={"#fff"}
                sx={{
                  mb: 4,
                  mt: 3,
                  width: { xs: "100%", md: "35%" },
                  ml: "auto",
                  mr: "auto",
                  [theme.breakpoints.down("md")]: {
                    fontSize: 18,
                    textAlign: "left",
                  },
                }}
              >
                {landing.description}
              </Typography>
              <Stack
                direction="row"
                sx={{ marginTop: "60px", marginBottom: "40px" }}
                spacing={4}
                justifyContent="center"
              >
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "#8828ff",
                    [theme.breakpoints.down("md")]: {
                      width: "100%",
                    },
                  }}
                  onClick={() => setOpenRegister(true)}
                >
                  {landing.registerBtn}
                </Button>
                <Button
                  variant="outlined"
                  onClick={() => setOpenLogIn(true)}
                  sx={{
                    color: "white !important",
                    borderColor: "white !important",
                    [theme.breakpoints.down("md")]: {
                      width: "100%",
                    },
                  }}
                >
                  {landing.loginBtn}
                </Button>
              </Stack>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box
        sx={{
          backgroundImage: isDown680 ? "" : `url(${semiCircle})`,
          backgroundRepeat: "no-repeat",
          backgroundPositionY: isDown1350 ? "bottom 70%" : "bottom 65%",
          backgroundPositionX: "right",
          backgroundSize: isDown1350 ? "15%" : "15%",
          borderRadius: "24px",
          mb: 10,
        }}
      >
        <IntroSection setOpenRegister={setOpenRegister} landing={landing} />
        <Container maxWidth={"xl"}>
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="space-around"
            sx={{
              position: "relative",
              height: isDown1350 ? "100%" : "100%",
              backgroundSize: isDown550 ? "100% 90%" : "90% 90%",
              backgroundPosition: isDown550 ? "center" : "center",

              alignItems: "center",
              borderRadius: 10,
              background:
                "linear-gradient(90deg, rgba(85,132,234,1) 0%, rgba(87,132,234,1) 15%, rgba(162,166,223,1) 41%, rgba(144,198,249,1) 68%, rgba(115,200,249,1) 87%, rgba(9,214,255,1) 100%)",

              // [theme.breakpoints.down("sm")]: {
              //   width: "100%",
              //   margin: "0 auto !important",
              //   pb: 16,
              //   height: "auto",
              // },
              // [theme.breakpoints.down("lg")]: {
              //   width: "100%",
              //   margin: "0 auto !important",
              //   pb: 20,
              //   height: "auto",
              // },
            }}
          >
            <Grid
              item
              container
              lg={6}
              md={6}
              sx={{
                height: "100%",
                p: 4,
              }}
            >
              <Grid item md={12} mb={4} container justifyContent={"center"}>
                <Box
                  component={"img"}
                  src={whoSectionTurtle}
                  alt="Tortuga marina"
                  sx={{
                    width: "80%",
                    height: "auto",
                    [theme.breakpoints.down("md")]: {
                      width: "60%",
                    },
                  }}
                />
              </Grid>
              <Grid item md={12} pb={4}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    flexDirection: "column",
                    [theme.breakpoints.down("md")]: {
                      flexDirection: "column",
                    },
                  }}
                >
                  <Typography
                    variant="h3"
                    component="h2"
                    color="#fff"
                    sx={{
                      fontWeight: "800",
                      [theme.breakpoints.down("lg")]: {
                        fontSize: 28,
                        fontWeight: "bold",
                      },
                      [theme.breakpoints.up("xl")]: {
                        mr: 1,
                        width: "70%",
                      },
                    }}
                  >
                    {landing?.section2?.text1}
                  </Typography>
                  <Typography
                    variant="h3"
                    color="#fff"
                    sx={{
                      fontWeight: "800",
                      [theme.breakpoints.down("lg")]: {
                        fontSize: "2.2rem",

                        fontWeight: "bold",
                      },
                    }}
                  >
                    {landing?.section2?.text2}
                  </Typography>
                </Box>

                <Typography
                  variant="h5"
                  color="#28ff4b"
                  sx={{
                    fontSize: 24,
                    fontWeight: "800",
                    mb: 4,
                    [theme.breakpoints.down("md")]: {
                      fontSize: "1.9rem",
                      mb: 3,
                      color: "#E6FFE9",
                      fontWeight: "bold",
                    },
                  }}
                >
                  {landing?.section2?.text4}
                </Typography>

                <Typography
                  variant="body1"
                  color="#fff"
                  sx={{
                    width: isDown1350 ? "100%" : "500px",
                    //marginLeft: "40px",
                    lineHeight: "28px",
                    textAlign: "left",

                    [theme.breakpoints.down("lg")]: {
                      fontSize: 14,
                      lineHeight: "25px",
                    },
                  }}
                >
                  {landing?.section2?.text3
                    .split("\n")
                    .map((paragraph, index) => (
                      <Fragment key={index}>
                        {paragraph.includes("SmileUP+") ? (
                          <>
                            {paragraph
                              .split("SmileUP+")
                              .map((part, partIndex) => (
                                <Fragment key={partIndex}>
                                  {partIndex > 0 && (
                                    <span
                                      style={{
                                        color: "#3D3D3D",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      Smile
                                      <span
                                        style={{
                                          color: Colors.smileUpYellow,
                                          fontWeight: "bold",
                                        }}
                                      >
                                        UP+
                                      </span>
                                    </span>
                                  )}
                                  {part}
                                </Fragment>
                              ))}
                          </>
                        ) : (
                          paragraph
                        )}
                        <br />
                      </Fragment>
                    ))}
                </Typography>
                <Typography
                  variant="body1"
                  color="#fff"
                  sx={{
                    width: isDown1350 ? "100%" : "500px",
                    //marginLeft: "40px",
                    lineHeight: "28px",
                    textAlign: "left",

                    mt: 0,
                    [theme.breakpoints.down("lg")]: {
                      fontSize: 14,
                      lineHeight: "25px",
                    },
                  }}
                >
                  {landing?.section2?.text3Second &&
                    landing?.section2?.text3Second
                      .split("\n")
                      .map((paragraph, index) => (
                        <Fragment key={index}>
                          {paragraph.includes("SmileUP+") ? (
                            <>
                              {paragraph
                                .split("SmileUP+")
                                .map((part, partIndex) => (
                                  <Fragment key={partIndex}>
                                    {partIndex > 0 && (
                                      <span
                                        style={{
                                          color: "#3D3D3D",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        Smile
                                        <span
                                          style={{
                                            color: Colors.smileUpYellow,
                                            fontWeight: "bold",
                                          }}
                                        >
                                          UP+
                                        </span>
                                      </span>
                                    )}
                                    {part}
                                  </Fragment>
                                ))}
                            </>
                          ) : (
                            paragraph
                          )}
                          <br />
                        </Fragment>
                      ))}
                </Typography>
                <Typography
                  variant="body1"
                  color="#fff"
                  sx={{
                    width: isDown1350 ? "100%" : "500px",
                    fontWeight: "800",
                    mt: 2,
                    lineHeight: "28px",
                    textAlign: "left",
                    [theme.breakpoints.down("lg")]: {
                      fontSize: 14,
                      lineHeight: "22px",
                    },
                  }}
                >
                  {landing.section2.text5}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              item
              container
              lg={4}
              md={6}
              sm={12}
              xs={12}
              rowGap={4}
              columnGap={4}
              justifyContent={{ sm: "center", md: "space-between" }}
              mb={{ sm: 6, xs: 6, md: 0 }}
              // spacing={2}
            >
              <Grid
                item
                sm={7}
                md={12}
                xs={10}
                sx={{
                  [theme.breakpoints.down("md")]: {
                    marginRight: "auto",
                    ml: 6,
                  },
                  [theme.breakpoints.down("sm")]: {
                    margin: "auto",
                  },
                }}
              >
                <LeftBubble
                  name={comentaries[0].name}
                  subtitle={comentaries[0].subtitle}
                  text={
                    lang === "eng"
                      ? comentaries[0].text
                      : comentaries[0].textEng
                  }
                />
              </Grid>

              <Grid
                item
                sm={7}
                xs={10}
                md={12}
                sx={{
                  [theme.breakpoints.down("md")]: {
                    marginLeft: "auto",
                    mr: 6,
                  },
                }}
              >
                <RightBubble
                  name={comentaries[1].name}
                  text={
                    lang === "eng"
                      ? comentaries[1].text
                      : comentaries[1].textEng
                  }
                />
              </Grid>

              <Grid
                item
                sm={6}
                md={12}
                xs={10}
                sx={{
                  [theme.breakpoints.down("md")]: {
                    marginRight: "auto",
                    ml: 6,
                  },
                  [theme.breakpoints.down("sm")]: {
                    margin: "auto",
                  },
                }}
              >
                <LeftBubble
                  name={comentaries[2].name}
                  text={
                    lang === "eng"
                      ? comentaries[2].text
                      : comentaries[2].textEng
                  }
                />
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Box>

      <WhoYouAreSection openRegister={setOpenRegister} landing={landing} />
      {isDown950 ? (
        <CausesResponsiveSection landing={landing} />
      ) : (
        <CausesSection landing={landing} />
      )}

      <BlogSection landing={landing} />
      <TestimonialSection landing={landing} />
      <Footer footer={footer} />

      <SignUpModal open={openSignUpSteps} handleOpen={setOpenSignUpSteps} />
      <SignInModal
        open={openSignInSteps}
        handleOpen={setOpenSignInSteps}
        handleClose={() => setOpenSignInSteps(false)}
      />

      <RegisterModal
        open={openRegister}
        handleOpenLogIn={setOpenLogIn}
        handleOpenPassRecovery={setOpenPassRecovery}
        handleOpenSignUpSteps={setOpenSignUpSteps}
        handleOpenSignIn={setOpenLogIn}
        handleOpenSignInStep={setOpenSignInSteps}
        handleOpenSignUp={setOpenRegister}
        register={register}
        // handleOpen={setOpenRegister}
        // open={openRegister}
        // handleOpenLogIn={setOpenLogIn}
        // handleOpenPassRecovery={setOpenPassRecovery}
        // handleOpenSignUpSteps={setOpenSignUpSteps}
      />

      <PasswordRecoveryModal
        open={openPassRecovery}
        handleOpen={setOpenPassRecovery}
        handleOpenLogIn={setOpenLogIn}
      />

      <LogInModal
        open={openLogIn}
        handleOpen={setOpenLogIn}
        handleOpenRegister={setOpenRegister}
        handleOpenPassRecovery={setOpenPassRecovery}
        handleOpenSingInStep={setOpenSignInSteps}
        login={login}
      />
    </>
  );
};

export default FirstPage;
