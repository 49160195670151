import { types } from "../types/types";
// import { auth } from '../../firebase/firebase-config';
import * as EN from "../../../languages/en.json";
const initialState = {
  data: null,
  followedUsers: [],
  notifications: [],
  selectedCategory: "all",
  isLoadingUserData: false,
  language: EN, // Set an empty object as the default language
};

export const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.getAllUsers:
      return {
        ...state,
        users: action.payload.users,
      };

    case types.getUserData:
      return {
        ...state,
        data: action.payload.data,
      };
    case types.setLanguage:
      const newLanguageData = action.payload.langData;

      return {
        ...state,
        language: newLanguageData,
      };

    case types.getFollows:
      return {
        ...state,
        followedUsers: action.payload.follows,
      };

    case types.getOneFollow:
      return {
        ...state,
        followedUsers: [...state.followedUsers, action.payload.follow],
      };

    case types.getUserNotifications:
      return {
        ...state,
        notifications: action.payload.notifications,
      };

    case types.cleanFollows:
      return {
        ...state,
        followedUsers: [],
      };

    case types.resetUserData:
      return {
        ...state,
        data: null,
      };

    case types.resetUserNotifications:
      return {
        ...state,
        notifications: [],
      };

    case types.setUserCategory:
      return {
        ...state,
        selectedCategory: action.payload.selectedCategory,
      };

    case types.isLoadingUserData:
      return {
        ...state,
        isLoadingUserData: action.payload.isLoadingUserData,
      };

    case types.isLoading:
      return {
        ...state,
        isLoading: action.payload.isLoading,
      };

    case types.isLoadingUserData:
      return {
        ...state,
        isLoadingUserData: action.payload.isLoadingUserData,
      };

    default:
      return state;
  }
};
