import { Typography } from "@mui/material";

const SocialMediaLink = ({ item }) => {
  const { socialNetName, socialNetURL, socialMediaIcon } = item;

  return (
    <a
      href={socialNetURL}
      style={{
        display: "flex",
        textDecoration: "none",
        color: "#000",
      }}
    >
      <span
        style={{
          padding: "0px 7px",
          backgroundColor: "#252831",
          borderRadius: "50%",
          color: "#fff",
          marginRight: "10px",
        }}
      >
        {socialMediaIcon}
      </span>
      <Typography variant="body1">{socialNetName}</Typography>
    </a>
  );
};

export default SocialMediaLink;
