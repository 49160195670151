import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Stack,
  Tabs,
  Typography,
} from "@mui/material";

import { FiMenu } from "react-icons/fi";
import CustomTab from "../userContent/CustomTab";
import TabPanel from "../../makerPage/tabPanel/TabPanel";
import { useState } from "react";
import EventsCard from "../eventCard/EventCard";
import InfiniteScroll from "react-infinite-scroll-component";
import { useNavigate } from "react-router-dom";
import DreamCard from "../dreamCard/DreamCard";
import { useSelector } from "react-redux";
import LanguageDropdown from "../../general/LanguageDropdown";

const DreamsMainContent = ({
  allDreams,
  nextDreamsAllTab,
  dreamsNumber,
  monthDreams,
  nextDreamsMonthTab,
  monthlyDreamsNumber,
  weekDreams,
  nextDreamsWeekTab,
  weekDreamsNumber,
  setOpenRightSidebar,
}) => {
  const [value, setValue] = useState(0);
  const { general } = useSelector((state) => state.loggedUser.language);
  const navigate = useNavigate();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const BATCH_SIZE = 5;

  return (
    <Box component="main" bgcolor="#f7f7f7">
      <Stack direction="row" alignItems="center">
        <Typography variant="h4" fontWeight="bold" mr="auto">
          <span style={{ color: "#6c47ee" }}>{general?.my}</span>{" "}
          {general?.dreams}
        </Typography>

        {/* <Box
          component="span"
          display={{ xs: "block", md: "none" }}
          sx={{ cursor: "pointer" }}
          onClick={() => setOpenRightSidebar(true)}
        >
          <FiMenu size={21} />
        </Box> */}
        <LanguageDropdown color={"#6c47ee"} borderColor={"#6c47ee"} />
      </Stack>

      <Stack
        direction={{ xs: "column", md: "row" }}
        alignItems={{ xs: "left", md: "center" }}
        mb={{ xs: "30px", md: 0 }}
        height="110px"
      >
        {/* <Tabs
          value={value}
          variant="scrollable"
          allowScrollButtonsMobile
          onChange={handleChange}
          indicatorColor="none" // Remove the default indicator
        > */}
        {/* <CustomTab label="Todo" value={0} style={{ marginLeft: 0 }} /> */}
        {/* <CustomTab label="Mensual" value={1} />
          <CustomTab label="Semanal" value={2} /> */}
        {/* </Tabs> */}

        <Button
          variant="contained"
          sx={{
            ml: { xs: 0, md: "auto" },
            mt: { xs: "30px", md: 0 },
            bgcolor: "#0659fd",
            maxWidth: "180px",
          }}
          onClick={() => navigate("/home/dreamer/create-dream")}
        >
          {general?.addDream}
        </Button>
      </Stack>

      <TabPanel value={value} index={0} panelWidth={{ lg: "100%", xs: "100%" }}>
        <InfiniteScroll
          style={{ scrollbarWidth: "none" }}
          dataLength={allDreams.length} //This is important field to render the next data
          next={nextDreamsAllTab}
          hasMore={dreamsNumber >= BATCH_SIZE}
          loader={
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              height="200px"
            >
              <CircularProgress />
            </Stack>
          }
          endMessage={
            <p style={{ textAlign: "center", marginTop: "40px" }}>
              <b>{general?.noDreams}😐</b>
            </p>
          }
        >
          <Grid container spacing={4}>
            {allDreams.map((dream) => {
              if (dream) {
                return (
                  <Grid item xs={12} md={6} key={dream.id}>
                    <DreamCard dreamData={dream} />
                  </Grid>
                );
              }
            })}
          </Grid>
        </InfiniteScroll>
      </TabPanel>

      {/* <TabPanel value={value} index={1} panelWidth={{ lg: "100%", xs: "100%" }}>
        <InfiniteScroll
          style={{ scrollbarWidth: "none" }}
          dataLength={monthDreams.length} //This is important field to render the next data
          next={nextDreamsMonthTab}
          hasMore={monthlyDreamsNumber >= BATCH_SIZE}
          loader={
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              height="200px"
            >
              <CircularProgress />
            </Stack>
          }
          endMessage={
            <p style={{ textAlign: "center", marginTop: "20px" }}>
              <b>Parece que no hay más sueños 😐 </b>
            </p>
          }
        >
          <Grid container spacing={4}>
            {monthDreams.map((dream) => {
              return (
                <Grid item xs={12} md={6} key={dream.id}>
                  <DreamCard dreamData={dream} />
                </Grid>
              );
            })}
          </Grid>
        </InfiniteScroll>
      </TabPanel> */}

      {/* <TabPanel value={value} index={2} panelWidth={{ lg: "100%", xs: "100%" }}>
        <InfiniteScroll
          style={{ scrollbarWidth: "none" }}
          dataLength={weekDreams.length} //This is important field to render the next data
          next={nextDreamsWeekTab}
          hasMore={weekDreamsNumber >= BATCH_SIZE}
          loader={
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              height="200px"
            >
              <CircularProgress />
            </Stack>
          }
          endMessage={
            <p style={{ textAlign: "center", marginTop: "20px" }}>
              <b>Parece que no hay más sueños 😐 </b>
            </p>
          }
        >
          <Grid container spacing={4}>
            {weekDreams.map((dream) => {
              return (
                <Grid item sm={12} md={6} key={dream.id}>
                  <DreamCard dreamData={dream} />
                </Grid>
              );
            })}
          </Grid>
        </InfiniteScroll>
      </TabPanel> */}
    </Box>
  );
};

export default DreamsMainContent;
