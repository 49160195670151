import { Box, useMediaQuery } from "@mui/material";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";

import BlogSlide from "../blogSlide/BlogSlide";

import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";

import "./blogSection.scss";

import { FiChevronRight, FiChevronLeft } from "react-icons/fi";
import { Fragment } from "react";
import Colors from "../../../utils/Colors";

const BlogSection = ({ landing }) => {
  const handleDragStart = (e) => e.preventDefault();

  const isDown1100 = useMediaQuery("(max-width:1100px)");
  const isDown899 = useMediaQuery("(max-width:899px)");

  const items = [
    <Box
      className="item"
      sx={{
        padding: "15px",
        width: "100%",
        maxWidth: "625px",
        margin: "auto",
        //justifyContent: "center",
      }}
      onDragStart={handleDragStart}
    >
      <BlogSlide />
    </Box>,
    <Box
      className="item"
      sx={{
        width: "100%",
        maxWidth: "625px",
        padding: "15px",
        //padding: "15px 30px",
        margin: "auto",
      }}
      onDragStart={handleDragStart}
    >
      <BlogSlide />
    </Box>,
    <Box
      className="item"
      sx={{
        width: "100%",
        maxWidth: "625px",
        padding: "15px",
        //padding: "15px 30px",
        margin: "auto",
      }}
      onDragStart={handleDragStart}
    >
      <BlogSlide />
    </Box>,
    <Box
      className="item"
      sx={{
        width: "100%",
        maxWidth: "625px",
        padding: "15px",
        //padding: "15px 30px",
        margin: "auto",
      }}
      onDragStart={handleDragStart}
    >
      <BlogSlide />
    </Box>,
  ];

  const responsive = {
    0: { items: 1 },
    //568: { items: 1 },
    1200: { items: 2 },
    1450: { items: 3 },
  };

  return (
    <Box
      component={"section"}
      sx={{
        backgroundColor: "#f7f1ff",
        // paddingRight: isDown1100 ? "20px" : "40px",
        // paddingLeft: isDown1100 ? "20px" : "40px",
        paddingRight: 0,
        paddingLeft: 0,
        paddingTop: "90px",
        paddingBottom: "130px",
        marginBottom: 0,
        //padding: isDown1100 ? "90px 40px" : "",
        overflow: "hidden",
      }}
    >
      <Stack
        direction={isDown1100 ? "column" : "row"}
        justifyContent="space-between"
        paddingLeft={{ xs: "20px", sm: "40px" }}
        paddingRight={{ xs: "20px", sm: "40px" }}
        sx={{ padding: "40px" }}
        alignItems="center"
        textAlign={isDown1100 && "center"}
        width="100%"
        //width={isDown1100 ? "100%" : "80%"}
        marginRight="auto"
        marginLeft="auto"
        rowGap={isDown1100 ? 2 : ""}
      >
        <Typography variant="h3" fontWeight="bold">
          {landing.blogSection.title}
        </Typography>
        <Typography variant="body1">
          {landing.blogSection.text2 &&
            landing.blogSection.text2.split("\n").map((paragraph, index) => (
              <Fragment key={index}>
                {paragraph.includes("SmileUP+") ? (
                  <>
                    {paragraph.split("SmileUP+").map((part, partIndex) => (
                      <Fragment key={partIndex}>
                        {partIndex > 0 && (
                          <span
                            style={{
                              color: Colors.grey,
                              fontWeight: "bold",
                            }}
                          >
                            Smile
                            <span
                              style={{
                                color: Colors.smileUpYellow,
                                fontWeight: "bold",
                              }}
                            >
                              UP+
                            </span>
                          </span>
                        )}
                        {part}
                      </Fragment>
                    ))}
                  </>
                ) : (
                  paragraph
                )}
                <br />
              </Fragment>
            ))}
        </Typography>
        <Button variant="contained" sx={{ backgroundColor: "#8828FF" }}>
          {landing.blogSection.btnText}
        </Button>
      </Stack>

      <AliceCarousel
        mouseTracking
        disableDotsControls
        items={items}
        responsive={responsive}
        paddingLeft={25}
        paddingRight={25}
        //autoWidth
        renderPrevButton={() => {
          return (
            <span
              className="control-button"
              style={{
                backgroundColor: "#8828FF",
                borderRadius: "50%",
                padding: "5px 5px",
                color: "#fff",
                display: isDown899 ? "none" : "flex",
                //position: "absolute",
                //right: "90px",
                //display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "40px",
              }}
            >
              <FiChevronLeft />
            </span>
          );
        }}
        renderNextButton={() => {
          return (
            <span
              className="control-button"
              style={{
                backgroundColor: "#8828FF",
                borderRadius: "50%",
                padding: "5px 5px",
                color: "#fff",
                display: isDown899 ? "none" : "flex",
                //position: "absolute",
                //right: "40px",
                //display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "40px",
              }}
            >
              <FiChevronRight />
            </span>
          );
        }}
      />
    </Box>
  );
};

export default BlogSection;
