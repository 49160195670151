import {
  Box,
  Card,
  FormControl,
  Grid,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";

import { FiArrowLeft } from "react-icons/fi";

import { socialNetworkList } from "./socialMediaNetList";
import SocialMediaLink from "./SocialMediaLink";
import { useNavigate } from "react-router-dom";

const Support = () => {
  const navigate = useNavigate();

  const isDown915 = useMediaQuery("(max-width:915px)");

  return (
    <Box component="main">
      {/* <span
        onClick={() => navigate(-1)}
        style={{
          cursor: "pointer",
          display: "block",
          margin: "40px 0",
          width: "20px",
        }}
      >
        <FiArrowLeft size={24} />
      </span> */}

      <Typography variant="h5" fontWeight="bold" mb={4}>
        <span style={{ color: "#864bf1" }}>Soporte</span> Técnico
      </Typography>

      <form>
        <Grid container spacing={3}>
          <Grid item md={6} xs={12}>
            <Grid container spacing={3} mt={2}>
              <Grid item sm={6} xs={12}>
                <FormControl
                  variant="outlined"
                  sx={{ width: "100%", padding: 0 }}
                >
                  <label htmlFor="outlined-name" style={{ fontWeight: "bold" }}>
                    Nombre
                  </label>
                  <OutlinedInput type={"text"} sx={{ borderRadius: "24px" }} />

                  {/* <FormHelperText error id="outlined-url">
                {touched.videoURL && errors.videoURL ? errors.videoURL : " "}
              </FormHelperText> */}
                </FormControl>
              </Grid>
              <Grid item sm={6} xs={12}>
                <FormControl
                  variant="outlined"
                  sx={{ width: "100%", padding: 0 }}
                >
                  <label
                    htmlFor="outlined-email"
                    style={{ fontWeight: "bold" }}
                  >
                    Email
                  </label>
                  <OutlinedInput type={"email"} sx={{ borderRadius: "24px" }} />

                  {/* <FormHelperText error id="outlined-url">
                {touched.videoURL && errors.videoURL ? errors.videoURL : " "}
              </FormHelperText> */}
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <label
                    htmlFor="outlined-assistance-type"
                    style={{ fontWeight: "bold" }}
                  >
                    Tipo de ayuda
                  </label>
                  <Select sx={{ borderRadius: "24px" }}>
                    <MenuItem value={"placeholder"}>
                      Cuenta inhabilitada
                    </MenuItem>
                    <MenuItem value={"placeholder"}>Otros</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <label
                    htmlFor="outlined-description"
                    style={{ fontWeight: "bold" }}
                  >
                    Descripción
                  </label>
                  <OutlinedInput
                    sx={{
                      borderRadius: "24px",
                      display: "block",
                    }}
                    type={"textarea"}
                    multiline
                    minRows={9}
                    id="outlined-description"
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={6} xs={12}>
            <Grid container rowSpacing={3}>
              <Grid item xs={12}>
                <Card
                  variant="outlined"
                  sx={{
                    borderRadius: "24px",
                    padding: "23px",
                  }}
                >
                  <Stack gap={2}>
                    <Typography
                      variant={isDown915 ? "h6" : "h5"}
                      fontWeight="bold"
                    >
                      Contactarme directamente
                    </Typography>
                    <Typography variant="body1">hola@smileup.com</Typography>
                    <Typography variant="body1">+506 88888888</Typography>
                    <Typography variant="body1">
                      Para apoyar a SmileUp hablemos
                    </Typography>
                  </Stack>
                </Card>
              </Grid>
              <Grid item xs={12}>
                <Card
                  variant="outlined"
                  sx={{ borderRadius: "24px", padding: "24px" }}
                >
                  <Typography variant="h5" fontWeight="bold" mb={3}>
                    Redes Sociales
                  </Typography>
                  <Stack gap={2}>
                    {socialNetworkList.map((item) => {
                      return <SocialMediaLink item={item} />;
                    })}
                  </Stack>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default Support;
