import { Box, Stack, Typography } from "@mui/material";
import { useEffect } from "react";
import { getPostImages } from "../../../store/actions/dreamers";
import { useState } from "react";
import { updateOneNotification } from "../../../store/actions/userActions";

import "./styles.scss";
import { useLocation, useNavigate } from "react-router-dom";

const NotificationItem = ({ item, userId }) => {
  const calculateTimeSince = () => {
    const dateJSformat = new Date(
      item.cdate.seconds * 1000 + item.cdate.nanoseconds / 100000
    );
    const today = new Date();
    const offsetTime = dateJSformat || new Date();
    const timeDifference = today - offsetTime;

    if (timeDifference < 1000) {
      return "Justo ahora";
    } else if (timeDifference < 60 * 1000) {
      return Math.floor(timeDifference / 1000) + " segundos"; // Seconds ago
    } else if (timeDifference < 60 * 60 * 1000) {
      return Math.floor(timeDifference / (60 * 1000)) + " minutos"; // Minutes ago
    } else if (timeDifference < 24 * 60 * 60 * 1000) {
      return Math.floor(timeDifference / (60 * 60 * 1000)) + " horas"; // Hours ago
    } else {
      return Math.floor(timeDifference / (24 * 60 * 60 * 1000)) + " días"; // Days ago
    }
  };

  const timeSinceNotification = calculateTimeSince();

  const [isReadState, setIsReadState] = useState(false);
  const [profileURL, setProfileURL] = useState("");
  const [bgColor, setBgColor] = useState("transparent");
  const [image, setImage] = useState(null);

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const getNotificationImg = async () => {
      try {
        const img = await getPostImages(userId, item.postLikeId);
        setImage(img[0]);
      } catch (error) {
        console.error("Error in getNotificationImg:", error);
      }
    };

    if (item && item.type === "postLike") {
      getNotificationImg();
    }

    if (item) {
      setIsReadState(item.isRead);
    }
  }, [item]);

  const handleClick = () => {
    const reverseIsRead = true;
    setIsReadState(reverseIsRead);
    const newData = {
      isRead: reverseIsRead,
    };

    if (!item.isRead) {
      updateOneNotification(userId, item.id, newData);
    }

    if (location.pathname.includes("maker")) {
      //go to maker profile
      const url = `/home/maker/profile/${item.fromUserId}`;
      navigate(url);
      //setProfileURL(url);
    } else {
      //go to dreamer profile
      const url = `/home/dreamer/profile/${item.fromUserId}`;
      navigate(url);
      //setProfileURL(url);
    }
  };

  return (
    <div
      style={{ backgroundColor: bgColor, padding: "10px" }}
      onClick={handleClick}
      onMouseEnter={() => setBgColor("#5abbe0")}
      onMouseLeave={() => setBgColor("transparent")}
    >
      <Box color="#fff" sx={{ cursor: "pointer" }}>
        <Stack direction="row" alignItems="center" gap={2}>
          <span
            style={{
              backgroundColor: item.isRead ? "transparent" : "#ff4e4e",
              borderRadius: "50%",
              width: "10px",
              height: "10px",
            }}
          ></span>
          <Box width="28px" height="28px">
            <img
              style={{ borderRadius: "10px" }}
              src="https://picsum.photos/id/1/200/300"
              alt="profile"
              width="100%"
              height="100%"
            />
          </Box>
          <Stack direction="row" gap={2} alignItems="center">
            <Stack gap={1}>
              <Stack direction="row" gap={1} alignItems="center">
                <Typography
                  fontWeight="bold"
                  variant="body1"
                  fontSize="14px"
                  className="truncate-notification-text"
                >
                  {item.fromUserName}
                </Typography>
                <Typography variant="body1" fontSize="12px">
                  {timeSinceNotification}
                </Typography>
              </Stack>
              <Typography variant="body1" fontSize="14px">
                {item.subject}
              </Typography>
              {/* {item.type === 'postLike' ? : } */}
              {/* {type === "like" ? (
              <Typography variant="body1" fontSize="14px">
                Le gusta tu foto
              </Typography>
            ) : (
              <Typography variant="body1" fontSize="14px">
                Te envió una solicitud
              </Typography>
            )} */}

              {/* {type === "friendRequest" && (
              <Stack direction="row" gap={1}>
                <Button
                  variant="contained"
                  size="small"
                  disableElevation
                  sx={{ backgroundColor: "#53d769", borderRadius: "4px" }}
                >
                  Aceptar
                </Button>
                <Button
                  variant="contained"
                  size="small"
                  disableElevation
                  sx={{ backgroundColor: "#67adc7", borderRadius: "4px" }}
                >
                  Ignorar
                </Button>
              </Stack>
            )} */}
            </Stack>

            {/* {item.type === "postLike" ? (
              <Box width="40px" height="40px">
                <img
                  src={image?.imgURL}
                  style={{ borderRadius: "8px" }}
                  alt=""
                  width="100%"
                  height="100%"
                />
              </Box>
            ) : null} */}
          </Stack>
        </Stack>
      </Box>
    </div>
  );
};

export default NotificationItem;
