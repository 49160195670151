import { Box, Stack } from "@mui/material";

import smileUpLogo from "../../assets/img/logoSmile.png";

import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";

import { useLocation, useNavigate } from "react-router-dom";
import { logoutUser } from "../../store/actions/auth";
import { callLogOutAlert } from "../../customAlert/LogOutAlert";

const NavigationMenu = ({ iconsList, mode, bgColor, route }) => {
  const dispatch = useDispatch();
  const [isHeaderMode, setIsHeaderMode] = useState(false);
  const { general, swals } = useSelector((state) => state.loggedUser.language);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (mode === "header") {
      setIsHeaderMode(true);
    } else {
      setIsHeaderMode(false);
    }
  }, [mode]);

  const logOut = () => {
    const endSesion = () => dispatch(logoutUser());

    callLogOutAlert(endSesion, swals?.logoutWarning, swals);
  };

  const handleClick = (index, url) => {
    if (index === iconsList.length - 1) {
      logOut();
    } else {
      navigate(url);
    }
  };

  const determineIconbg = (iconURL) => {
    if (isHeaderMode && iconURL === location.pathname) {
      return "rgba(255, 255, 255, 0.20)";
    } else if (iconURL === location.pathname) {
      return "rgba(255, 255, 255)";
    } else if (!isHeaderMode) {
      return "rgba(255, 255, 255, 0.20)";
    }
  };

  const determineIconColor = (iconURL) => {
    if (isHeaderMode) {
      return "#FFF";
    } else if (iconURL === location.pathname) {
      return "#a73ef5";
    } else {
      return "#FFF";
    }
  };
  const navigateTo = (link) => {
    navigate(link);
  };
  const homeLink = route ? route : "maker";

  return (
    <Box
      height="100%"
      sx={{
        display: "flex",
        justifyContent: "center",
        flexDirection: isHeaderMode ? "row" : "column",
        background: bgColor,
        //background: "linear-gradient(#6345ED, #7F42F0, #DC39FC)",
      }}
    >
      <Box
        sx={{
          display: { sm: "block", xs: "none" },
          width: "85%",
          margin: "auto",
          cursor: "pointer",
        }}
        onClick={() => navigateTo(`/home/${homeLink}`)}
      >
        <img
          src={smileUpLogo}
          width="100%"
          height="auto"
          alt="Smileup logo"
          style={{
            display: "block",
            marginTop: isHeaderMode ? "10px" : "40px",
            padding: "0 10px",
            maxWidth: "200px",
          }}
        />
      </Box>

      <Stack
        direction={isHeaderMode ? "row" : "column"}
        justifyContent="space-between"
        width="100%"
        alignItems="center"
        height={isHeaderMode ? "" : "100%"}
      >
        <Stack
          padding={isHeaderMode ? "10px 0" : "100px 0 0 0"}
          gap={1}
          //gap={isHeaderMode ? 0 : 5}
          direction={isHeaderMode ? "row" : "column"}
          width="100%"
          alignItems="center"
          justifyContent={{ xs: "center", sm: "end" }}
        >
          {iconsList.map((item, index) => {
            return (
              <div
                key={index}
                style={{
                  textAlign: "center",
                  marginBottom: isHeaderMode ? 0 : "27px",
                }}
              >
                <span
                  onClick={() => handleClick(index, item.url)}
                  style={{
                    backgroundColor: determineIconbg(item.url),
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "48px",
                    height: "48px",
                    borderRadius: "12px",
                    padding: isHeaderMode ? "" : "15px",
                    color: determineIconColor(item.url),
                    margin: "auto",
                    cursor: "pointer",
                    marginBottom: "8px",
                  }}
                >
                  {item.icon}
                </span>
                <small style={{ color: "#fff", fontSize: "13px" }}>
                  {!isHeaderMode && item.caption}
                </small>
              </div>
            );
          })}
        </Stack>

        {/* <Box
          component="span"
          sx={{
            backgroundColor: isHeaderMode ? "" : "rgba(255, 255, 255, 0.20)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "48px",
            height: "48px",
            borderRadius: "12px",
            padding: isHeaderMode ? "" : "15px",
            color: "#fff",
            marginTop: isHeaderMode ? "" : "auto",
            marginBottom: { md: "60px", xs: 0 },
            cursor: "pointer",
          }}
        >
          <BiExit size={24} onClick={logOut} />
        </Box> */}
      </Stack>
    </Box>
  );
};

export default NavigationMenu;
