import { Box, Stack, useMediaQuery } from "@mui/material";
import Grid from "@mui/material/Grid";

import fundationLogo from "../../../assets/img/eres-mi-sonrisa-logo.svg";
import tucan from "../../../assets/img/about-us-page-tucan.png";
import semiCircle from "../../../assets/img/about-us-semi-circle.png";

const Fundation = ({ aboutPage }) => {
  const isDown980 = useMediaQuery("(max-width:980px)");
  const isDown900 = useMediaQuery("(max-width:900px)");
  const isDown460 = useMediaQuery("(max-width:460px)");

  //paddingLeft={{ xs: "20px", sm: "40px", md: "90px" }}

  return (
    <Box
      component="section"
      sx={{
        backgroundImage: `url(${semiCircle})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: isDown900 ? "" : "contain",
        paddingLeft: { xs: "20px", sm: "40px", md: "90px" },
        paddingRight: { xs: "20px", sm: "40px", md: "90px" },
        //paddingLeft: { xs: "20px", md: 0 },
        //paddingRight: isDown980 ? "20px" : "40px",
      }}
    >
      <Grid container alignItems="center" spacing={4}>
        <Grid
          item
          xl={4}
          lg={5}
          md={6}
          xs={12}
          display="flex"
          justifyContent="center"
        >
          <img
            src={tucan}
            alt="Tucan sobre rama"
            width={isDown460 ? "90%" : "auto"}
          />
        </Grid>
        <Grid item xl={7} lg={7} md={6} xs={12}>
          <Box
            padding={0}
            //padding={{ xs: 0, md: "20px" }}
            sx={{ margin: { xs: 0, md: "auto" }, display: "block" }}
            width={{ xs: "100%", md: "90%", lg: "auto" }}
          >
            <img
              src={fundationLogo}
              //style={{ margin: "auto", display: "block" }}
              alt="Fundacion eres mi sonrisa logo"
              width={"100%"}
              height={"100%"}
              style={{ maxWidth: "406px", maxHeight: "125px" }}
              //width={isDown460 ? "80%" : "auto"}
            />
            <Stack direction="row">
              <p className="fw-bold ms-auto"> {aboutPage.section2.text}</p>
            </Stack>
            <p style={{ textAlign: "justify" }}>
              {aboutPage?.section2.content}
            </p>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Fundation;
