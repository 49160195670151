import { BiHappyAlt, BiSend } from "react-icons/bi";

import { Box, InputAdornment } from "@mui/material";
import Input from "@mui/material/Input";

import Picker from "@emoji-mart/react";
import { useState } from "react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

const CommentsInput = ({
  handleSubmit,
  getFieldProps,
  outlined,
  setFieldValue,
  values,
}) => {
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const { comments } = useSelector((state) => {
    // console.log(state); // Log the entire state
    return state.loggedUser.language;
  });
  // useEffect(() => {
  //   setFieldValue("comment", selectedEmoji);
  // }, [selectedEmoji]);

  return (
    <form
      style={{ position: "relative" }}
      onSubmit={(event) => {
        event.preventDefault();
        handleSubmit(event);
      }}
    >
      <Input
        {...getFieldProps("comment")}
        disableUnderline
        id="outlined-adornment-comment"
        fullWidth
        type="text"
        placeholder={comments?.reply}
        sx={{
          width: "100%",
          padding: "7px",
          backgroundColor: outlined ? "#fff" : "#f7f7f7",
          border: outlined ? "2px solid rgba(143, 146, 161, 0.2)" : "none",
        }}
        endAdornment={
          <InputAdornment position="end" sx={{ cursor: "pointer" }}>
            <BiHappyAlt
              color="8F92A1"
              onClick={() => setShowEmojiPicker((prevValue) => !prevValue)}
            />
            <span onClick={(event) => handleSubmit(event)}>
              <BiSend color="#0659FD" />
            </span>
          </InputAdornment>
        }
      />

      <Box
        position="absolute"
        right={0}
        zIndex={100}
        display={showEmojiPicker ? "block" : "none"}
      >
        <Picker
          locale="es"
          perLine={7}
          onEmojiSelect={(data) =>
            setFieldValue("comment", values.comment + data.native)
          }
        />
      </Box>
    </form>
  );
};

export default CommentsInput;
