import Container from "@mui/material/Container";
import { Box, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import Grid from "@mui/material/Grid";

import sectionBg from "../../../assets/img/whoYouAre-bg.png";
import UserTypeCard from "../userTypeCard/UserTypeCard";

import transformerImg from "../../../assets/img/world-in-your-hands.png";
import dayDreamerImg from "../../../assets/img/rocket.png";
import greenBall from "../../../assets/img/little-green-ball.png";
import yellowBall from "../../../assets/img/medium-yellow-ball.png";

import { ReactComponent as ThinRibbon } from "../../../assets/img/thin-ribbon.svg";
import { ReactComponent as GreenRibbon } from "../../../assets/img/greenRibbon.svg";
import { useSelector } from "react-redux";

const WhoYouAreSection = ({ openRegister, landing }) => {
  const makerContent = landing.makerSection.text3;
  const theme = useTheme();
  const dreamerContent = landing.dreamerSection.text3;
  const { general } = useSelector((state) => state.loggedUser.language);
  const isDown1200 = useMediaQuery("(max-width:1200px)");
  const isDown1000 = useMediaQuery("(max-width:1000px)");
  const isDown800 = useMediaQuery("(max-width:800px)");
  const isDown700 = useMediaQuery("(max-width:700px)");

  return (
    <Box
      component={"section"}
      sx={{
        backgroundImage: `url(${sectionBg})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "150% 100%",
        backgroundPosition: "center",
        height: isDown1200 ? "auto" : "100vh",
        paddingBottom: "120px",
        paddingLeft: "20px",
        paddingRight: "20px",
      }}
    >
      <Container
        maxWidth="xl"
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          height: "100%",
          [theme.breakpoints.down("sm")]: {
            padding: "0 !important",
          },
        }}
      >
        <Typography
          variant="h4"
          component="h3"
          marginBottom="70px"
          className="text-center py-4 fw-bold"
        >
          {landing.makerSection.text1}
        </Typography>

        <Grid container spacing={7} justifyContent="center" position="relative">
          <Grid
            item
            alignItems="center"
            display={{ xs: "none", sm: "flex" }}
            lg={1}
            position={isDown1200 ? "absolute" : ""}
            sx={
              isDown1000
                ? isDown800
                  ? { left: "-15%", top: "25%" }
                  : { left: 0, top: "25%" }
                : { left: "15%", top: "25%" }
            }
          >
            <img
              src={greenBall}
              alt=""
              width={40}
              height={40}
              style={{ marginBottom: "100px" }}
            />
          </Grid>
          <Grid item sx={{ position: "relative" }} lg={5}>
            <Box
              sx={{
                display: { xs: "none", md: "block" },
                borderRadius: "50%",
                width: isDown1200 ? (isDown800 ? "170px" : "250px") : "325px",
                height: isDown1200 ? (isDown800 ? "170px" : "250px") : "325px",
                background: "#18acfe",
                position: "absolute",
                left: isDown1200 ? (isDown800 ? "-85px" : "-50px") : "-80px",
                top: isDown1200 ? (isDown800 ? "-85px" : "-50px") : "-90px",
                //left: isDown800 ? (isDown700 ? "-50px" : "-100px") : "-150px",
                //top: isDown800 ? (isDown700 ? "-50px" : "-100px") : "-150px",
              }}
            ></Box>

            <UserTypeCard
              imgSource={transformerImg}
              title={landing.makerSection.text2}
              content={makerContent}
              openRegister={openRegister}
              landing={landing}
              general={general}
            />
          </Grid>
          <Grid item sx={{ position: "relative" }} lg={5}>
            <Box
              sx={{
                display: { xs: "none", md: "block" },
                borderRadius: "50%",
                width: isDown1200 ? (isDown800 ? "160px" : "250px") : "300px",
                height: isDown1200 ? (isDown800 ? "160px" : "250px") : "300px",
                //width: "170px",
                //height: "170px",
                background: "#8828FF",
                position: "absolute",
                right: "-110px",
                bottom: "-70px",
              }}
            ></Box>

            <ThinRibbon
              style={{
                position: "absolute",
                right: "25%",
                zIndex: 300,
                bottom: "-350px",
              }}
            />
            <GreenRibbon
              style={{
                position: "absolute",
                right: "25%",
                zIndex: 300,
                bottom: "-350px",
              }}
            />

            <UserTypeCard
              imgSource={dayDreamerImg}
              title={landing.dreamerSection.text2}
              content={dreamerContent}
              openRegister={openRegister}
              landing={landing}
              general={general}
            />
          </Grid>
          <Grid
            item
            alignItems="center"
            display={{ xs: "none", sm: "flex" }}
            lg={1}
            position={isDown1200 ? "absolute" : ""}
            sx={
              isDown1000
                ? isDown800
                  ? { right: "-15%", bottom: "25%" }
                  : { right: "0", bottom: "25%" }
                : { right: "15%", bottom: "25%" }
            }
          >
            <img
              src={yellowBall}
              alt=""
              width={48}
              height={48}
              style={{ marginTop: "40px" }}
            />
          </Grid>
        </Grid>
        {/* </Box> */}
      </Container>
    </Box>
  );
};

export default WhoYouAreSection;
