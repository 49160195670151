import {
  Box,
  Button,
  CircularProgress,
  Stack,
  Typography,
} from "@mui/material";

import { BiChevronRight } from "react-icons/bi";

import { Animated } from "react-animated-css";

import kevin from "../../assets/img/kevin-maker.jpg";
import anna from "../../assets/img/anna-maker.jpg";
import anastasia from "../../assets/img/anastasia-maker.jpg";
import noImagePlaceHolder from "../../assets/img/avatar-no-image.png";
import ToggleAccount from "../toggleAccount/ToggleAccount";
import { Fragment, useState } from "react";
import CreateDreamModal from "../../components/modals/CreateDreamModal";
import PopupProfileConfig from "../../components/dreamerPage/popupProfileConfig/PopupProfileConfig";
import AddUser from "../addUser/AddUser";
import FriendListItem from "../friendListItem/FriendListItem";
import { useEffect } from "react";
import { getFollowersWithLimit } from "../../store/actions/dreamers";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

const RightMenuMakers = ({
  openPopupMenu,
  setOpenPopupMenu,
  setNotificationsMod,
  theme: { bgColor, fontColor } = false,
}) => {
  const [openCreateDream, setOpenCreateDream] = useState(false);
  const { general } = useSelector((state) => state.loggedUser.language);
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.loggedUser.data);
  const followers = useSelector((state) => state.dreamers.followers);
  const followedUsers = useSelector((state) => state.loggedUser.followedUsers);
  const location = useLocation();
  const isLoadingFollowers = useSelector(
    (state) => state.dreamers.isLoadingFollowers
  );

  useEffect(() => {
    const getFollowersPreview = () => {
      dispatch(getFollowersWithLimit(3, currentUser.id));
    };

    if (followers.length < 3) {
      getFollowersPreview();
    }
  }, []);

  if (!followers) {
    <CircularProgress />;
  }

  return (
    <Box
      sx={{
        backgroundColor: "#0659fd",
        height: "100vh",
        borderTopLeftRadius: "32px",
        borderBottomLeftRadius: "32px",
        padding: "20px",
        overflow: "scroll",
      }}
    >
      <Stack direction="row" justifyContent="flex-end" gap={2}>
        <span
          onClick={() => setNotificationsMod(true)}
          style={{
            width: "48px",
            height: "48px",
            backgroundColor: "#79d0f1",
            borderRadius: "12px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: "22px",
            color: "#fff",
            fontWeight: "bold",
            cursor: "pointer",
          }}
        >
          {currentUser.unreadNotificationsNum}
        </span>
        <div
          style={{
            width: "48px",
            height: "48px",
            borderRadius: "12px",
            position: "relative",
          }}
        >
          <img
            src={currentUser?.imgURL || noImagePlaceHolder}
            onClick={() => setOpenPopupMenu((prevValue) => !prevValue)}
            alt=""
            style={{
              objectFit: "cover",
              borderRadius: "12px",
              width: "100%",
              height: "100%",
              cursor: "pointer",
            }}
          />
          {/* {openPopupMenu && ( */}
          <Animated
            animationIn="fadeInDown"
            animationOut="fadeOutUp"
            isVisible={openPopupMenu || false}
            animationInDuration={500}
            animationOutDuration={500}
            animateOnMount={false}
            style={{ position: "relative", zIndex: "100" }}
          >
            <PopupProfileConfig />
          </Animated>
          {/* )} */}
        </div>
      </Stack>

      <Box
        sx={{
          borderRadius: "12px",
          backgroundColor: "#ff4e4e",
          color: "#fff",
          padding: "15px",
          marginTop: "45px",
        }}
      >
        <Typography fontWeight="bold"> {general?.premium}</Typography>
        <Typography>{general?.tryPremium}</Typography>

        <Button
          variant="contained"
          disableElevation
          sx={{
            color: "#fff",
            backgroundColor: "rgba(30, 31, 32, 0.2)",
            borderRadius: "4px",
            marginTop: "20px",
          }}
        >
          {general?.viewMore}
          <BiChevronRight />
        </Button>
      </Box>

      <Stack sx={{ marginTop: "60px" }} gap={3}>
        <ToggleAccount
          fontColor={"white"}
          currentType={
            location.pathname.includes("dreamer") ? "dreamer" : "maker"
          }
        />
        <Typography variant="h5" color="#fff" fontWeight="bold">
          {general?.followers}
        </Typography>

        {isLoadingFollowers ? (
          <Stack direction="row" justifyContent="center">
            <CircularProgress />
          </Stack>
        ) : followers?.length ? (
          followers.slice(0, 4).map((follower, index) => {
            return (
              <Fragment key={index}>
                <AddUser
                  user={follower}
                  location={"San José"}
                  followedUsers={followedUsers}
                />
              </Fragment>
            );
          })
        ) : (
          <Typography variant="body1" color="#fff">
            {general?.noOne} 🤨
          </Typography>
        )}
      </Stack>

      {/* <Button
        variant="text"
        sx={{
          color: "#8F92A1",
          fontWeight: "bold",
          marginTop: "20px",
          paddingLeft: 0,
        }}
      >
        VER MAS <BiChevronRight size={22} />
      </Button> */}

      <Stack sx={{ marginTop: "60px" }} gap={3}>
        {/* <Typography variant="h5" color="#fff" fontWeight="bold">
          Amigos
        </Typography>

        <FriendListItem photo={anna} content={"Made in Tiquicia"} />
        <FriendListItem photo={anastasia} content={"Made in Tiquicia"} />
        <FriendListItem photo={kevin} content={"Made in Tiquicia"} /> */}
      </Stack>

      <CreateDreamModal
        openModal={openCreateDream}
        handleOpen={setOpenCreateDream}
      />
    </Box>
  );
};

export default RightMenuMakers;
