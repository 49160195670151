import {
  Box,
  Button,
  FormHelperText,
  Stack,
  TextField,
  useMediaQuery,
} from "@mui/material";
import Chip from "@mui/material/Chip";

import noImagePlaceHolder from "../../../assets/img/avatar-no-image.png";

import {
  BiDotsVerticalRounded,
  BiCameraMovie,
  BiCamera,
  BiPlus,
} from "react-icons/bi";
import { useState } from "react";
import CreateImagePostModal from "../../modals/CreateImagePostModal";
import CreateVideoPostModal from "../../modals/CreateVideoPostModal";
import { useFormik } from "formik";
import {
  createImageForPost,
  createPost,
  createVideoPost,
  getJustCreatedId,
} from "../../../store/actions/dreamers";
import { useDispatch, useSelector } from "react-redux";
import { timestampCreador } from "../../../firebase/firebase-config";
import { callSuccessAlert } from "../../../customAlert/SuccessAlert";

const PublishCard = ({ startLoading, general, dreamersPage }) => {
  const [openCreateImagePost, setOpenCreateImagePost] = useState(false);
  const [openCreateVideoPost, setOpenCreateVideoPost] = useState(false);
  const [files, setFiles] = useState([]);
  const [postType, setPostType] = useState(true); //true image - false video

  const currenUser = useSelector((state) => state.loggedUser.data);
  const userData = useSelector((state) => state.loggedUser.data);
  const dispatch = useDispatch();
  const isDown415 = useMediaQuery("(max-width:415px)");

  const formik = useFormik({
    initialValues: {
      postName: "",
      description: "",
      currentDreamId: "",
      file: files,
      videoURL: "",
    },
    //validationSchema: createImagePostSchema,
    onSubmit: async (values) => {
      if (postType) {
        const postData = {
          name: userData.name,
          description: values.description,
          type: files.length ? "image" : "text",
          cdate: timestampCreador.fromDate(new Date()),
          isActive: true,
        };

        dispatch(createPost(currenUser.id, postData, files));
      } else {
        const postData = {
          name: userData.name,
          description: values.description,
          type: values.videoURL ? "video" : "text",
          cdate: timestampCreador.fromDate(new Date()),
          isActive: true,
        };

        if (values.videoURL) {
          postData.videoURL = values.videoURL;
        }

        const postId = await createVideoPost(currenUser.id, postData);
        dispatch(getJustCreatedId(postId));
      }

      formik.resetForm();
      setFiles([]);
      startLoading(true);
      callSuccessAlert("Nueva publicación creada");
    },
    // validate: (values) => {
    //   const errors = {};
    //   if (files.length === 0 && !values.videoURL) {
    //     errors.description =
    //       "Seleccione elementos para su publicacion y trate nuevamente";
    //   }
    //   return errors;
    // },
  });

  const handleDeleteFile = (index) => {
    const newFileList = [...files];

    newFileList.splice(index, 1);
    setFiles(newFileList);
  };

  return (
    <Box
      sx={{
        backgroundColor: "#fff",
        marginTop: "40px",
        padding: "30px",
        borderRadius: "12px",
      }}
    >
      <form
        onSubmit={(event) => {
          event.preventDefault();

          formik.handleSubmit();
        }}
      >
        <FormHelperText id="outlined-description" sx={{ mb: 4 }}>
          {formik.touched.description && formik.errors.description
            ? formik.errors.description
            : " "}
        </FormHelperText>
        <Stack direction="row" justifyContent="space-between">
          <Stack direction="row" gap={3} width="70%" mb={5}>
            <span style={{ width: "38px", height: "38px" }}>
              <img
                src={currenUser?.imgURL || noImagePlaceHolder}
                alt="perfil"
                style={{
                  objectFit: "cover",
                  borderRadius: "12px",
                  width: "100%",
                  height: "100%",
                }}
              />
            </span>

            <TextField
              id="outlined-multiline-flexible"
              name="description"
              value={formik.values.description}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              placeholder={dreamersPage?.publishPlaceholder}
              multiline
              rows={2}
              variant="standard"
              InputProps={{
                disableUnderline: true,
              }}
              sx={{ width: "100%" }}
            />
          </Stack>

          <BiDotsVerticalRounded size={22} color="#8F92A1" />
        </Stack>
        <Stack direction="row">
          <Stack
            direction={isDown415 ? "column" : "row"}
            justifyContent="space-between"
            width="100%"
            gap={3}
          >
            <Stack direction="row" gap={3}>
              <span
                onClick={() => setOpenCreateImagePost(true)}
                style={{
                  backgroundColor: "#F7F7F7",
                  borderRadius: "12px",
                  padding: "10px 12px",
                  cursor: "pointer",
                }}
              >
                <BiCamera color="#8F92A1" size={22} />
              </span>
              <span
                onClick={() => setOpenCreateVideoPost(true)}
                style={{
                  backgroundColor: "#F7F7F7",
                  borderRadius: "12px",
                  padding: "10px 12px",
                  cursor: "pointer",
                }}
              >
                <BiCameraMovie color="#8F92A1" size={22} />
              </span>
              {/* <span
                onClick={() => {}}
                style={{
                  backgroundColor: "#F7F7F7",
                  borderRadius: "12px",
                  padding: "10px 12px",
                  cursor: "pointer",
                }}
              >
                <BiPlus color="#8F92A1" size={22} />
              </span> */}
            </Stack>
            <Button
              type="submit"
              variant="contained"
              sx={{ background: "#0659FD", borderRadius: "12px" }}
            >
              {dreamersPage?.publish}
            </Button>
          </Stack>
        </Stack>
        {files.length > 0 && (
          <Stack direction="row" gap={1} mt={3}>
            {files.map((file, index) => {
              return (
                <Chip
                  label={file.name}
                  onDelete={() => handleDeleteFile(index)}
                />
              );
            })}
          </Stack>
        )}
        {formik.values.videoURL !== "" && (
          <Stack direction="row" mt={3}>
            <Chip
              label={formik.values.videoURL}
              //onClick={handleClick}
              onDelete={() => formik.setFieldValue("videoURL", "")}
            />
          </Stack>
        )}
      </form>

      <CreateImagePostModal
        openModal={openCreateImagePost}
        handleOpen={setOpenCreateImagePost}
        setImgFiles={setFiles}
        setPostType={setPostType}
      />
      <CreateVideoPostModal
        openModal={openCreateVideoPost}
        handleOpen={setOpenCreateVideoPost}
        formik={formik}
        setPostType={setPostType}
      />
    </Box>
  );
};

export default PublishCard;
