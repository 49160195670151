import { Animated } from "react-animated-css";
import PopupProfileConfig from "../popupProfileConfig/PopupProfileConfig";

import { Box, Stack } from "@mui/material";
import noImagePlaceholder from "../../../assets/img/avatar-no-image.png";

import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux";

const TopUserActions = ({
  selectedIcon,
  setNotificationsMod,
  setOpenPopupMenu,
  openPopupMenu,
  setOpenRightSidebar,
  general,
}) => {
  const userData = useSelector((state) => state.loggedUser.data);
  return (
    <Stack direction="row" gap={2} alignItems="center" width="100%">
      <Box
        width="30px"
        component="span"
        onClick={() =>
          setOpenRightSidebar ? setOpenRightSidebar(false) : null
        }
        //onClick={() => dispatch(toggleLeftMenu())}
        display={{ xs: "block", lg: "none" }}
        sx={{ color: { xs: "#f7f7f7", md: "#fff" } }}
      >
        <CloseIcon />
      </Box>
      <span
        onClick={() => (setNotificationsMod ? setNotificationsMod(true) : null)}
        style={{
          width: "48px",
          height: "48px",
          backgroundColor: "#79d0f1",
          borderRadius: "12px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "22px",
          color: "#fff",
          fontWeight: "bold",
          cursor: "pointer",
          marginLeft: "auto",
        }}
      >
        {userData.unreadNotificationsNum}
      </span>
      <Stack
        direction="row"
        alignItems="center"
        sx={{
          width: "48px",
          height: "48px",
          borderRadius: "12px",
          position: "relative",
        }}
      >
        {selectedIcon ? (
          <>{selectedIcon}</>
        ) : (
          // <img
          //   src={icon}
          //   onClick={() => setOpenPopupMenu((prevValue) => !prevValue)}
          //   alt=""
          //   style={{
          //     objectFit: "contain",
          //     borderRadius: "12px",
          //     width: "100%",
          //     height: "100%",
          //     cursor: "pointer",
          //   }}
          // />
          <img
            src={userData?.imgURL || noImagePlaceholder}
            onClick={() => setOpenPopupMenu((prevValue) => !prevValue)}
            alt=""
            style={{
              objectFit: "cover",
              borderRadius: "12px",
              width: "100%",
              height: "100%",
              cursor: "pointer",
            }}
          />
        )}
        <Animated
          animationIn="fadeInDown"
          animationOut="fadeOutUp"
          isVisible={openPopupMenu || false}
          animationInDuration={500}
          animationOutDuration={500}
          animateOnMount={false}
          style={{ position: "relative", zIndex: "100" }}
        >
          <PopupProfileConfig />
        </Animated>
      </Stack>
    </Stack>
  );
};

export default TopUserActions;
