import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";

import { Box, List } from "@mui/material";
import { getFilterType } from "../../../store/actions/dreamers";
import { useDispatch } from "react-redux";

const SearchFilters = ({ filterType, setFilterType }) => {
  const dispatch = useDispatch();

  return (
    <Box
      sx={{
        bgcolor: "background.paper",
        borderRadius: "12px",
      }}
    >
      <List dense>
        <ListItem sx={{ color: filterType === "all" ? "#a73ef5" : "" }}>
          <ListItemButton onClick={() => setFilterType("all")}>
            <ListItemText primary="Todos" />
          </ListItemButton>
        </ListItem>
        <ListItem sx={{ color: filterType === "lastHour" ? "#a73ef5" : "" }}>
          <ListItemButton onClick={() => setFilterType("lastHour")}>
            <ListItemText primary="Ultima hora" />
          </ListItemButton>
        </ListItem>
        <ListItem sx={{ color: filterType === "today" ? "#a73ef5" : "" }}>
          <ListItemButton onClick={() => setFilterType("today")}>
            <ListItemText primary="Hoy" />
          </ListItemButton>
        </ListItem>
        <ListItem sx={{ color: filterType === "thisWeek" ? "#a73ef5" : "" }}>
          <ListItemButton onClick={() => setFilterType("thisWeek")}>
            <ListItemText primary="Esta semana" />
          </ListItemButton>
        </ListItem>
        <ListItem sx={{ color: filterType === "thisMonth" ? "#a73ef5" : "" }}>
          <ListItemButton onClick={() => setFilterType("thisMonth")}>
            <ListItemText primary="Este mes" />
          </ListItemButton>
        </ListItem>
        <ListItem sx={{ color: filterType === "thisYear" ? "#a73ef5" : "" }}>
          <ListItemButton onClick={() => setFilterType("thisYear")}>
            <ListItemText primary="Este año" />
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );
};

export default SearchFilters;
