import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  IconButton,
  useMediaQuery,
} from "@mui/material";
import { Typography } from "@mui/material";
import { Stack } from "@mui/material";
import CardActions from "@mui/material/CardActions";
import {
  FaWhatsapp,
  FaInstagram,
  FaFacebook,
  FaTelegram,
  FaLink,
} from "react-icons/fa";
import {
  FacebookShareButton,
  InstapaperShareButton,
  TelegramShareButton,
  WhatsappShareButton,
} from "react-share";
import { FiMoreHorizontal } from "react-icons/fi";
import { useSelector } from "react-redux";

import logoSmileUp from "../../../assets/img/smile-blue-logo.svg";
import noImagePlaceholder from "../../../assets/img/avatar-no-image.png";

import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";

import { useEffect, useState } from "react";
import { getDreamMakers } from "../../../store/actions/dreamers";

const DreamerCard = ({ dream }) => {
  const [dreamOwnerData, setDreamOwnerData] = useState(null);

  const isDown1630 = useMediaQuery("(max-width:1630px)");
  const { general, makersPage } = useSelector((state) => {
    // console.log(state); // Log the entire state
    return state.loggedUser.language;
  });
  const makers = useSelector((state) => state.dreamers.makersSupportDreams);
  const { dreamers } = useSelector((state) => state.dreamers);
  const [dreamMakers, setDreamMakers] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const [shareTitle, setShareTitle] = useState("");
  useEffect(() => {
    const setDreamOwner = () => {
      const dreamOwner = dreamers.find(
        (dreamer) => dreamer.id === dream.userOwnerId
      );
      setDreamOwnerData(dreamOwner);
    };

    if (dreamers) {
      setDreamOwner();
    }
  }, [dreamers, dream]);
  const shareUrl = `https://foundation-smileup.web.app/home/dreamer/profile/${dream?.userOwnerId}`;
  // const dreamMakers = makers
  //   .filter((maker) => maker.dreamId === dream.id)
  //   .slice(0, 3);

  //const followersNumber http://localhost:3000/static/media/face-placeholder.0f9e2d5805d752646021.jpg= useCountFollowers(dream.id);
  const followersNumber = 0;

  const {
    name: dreamName,
    description: dreamDescription,
    imgURL,
    userOwnerName,
    email,
  } = dream;

  const fetchDreamMakers = async () => {
    try {
      const dreamMakersData = await getDreamMakers(dream.userOwnerId, dream.id);
      setDreamMakers(dreamMakersData.docs.map((doc) => doc.data()));
    } catch (error) {
      console.error("Error fetching dream makers:", error);
    }
  };

  useEffect(() => {
    fetchDreamMakers();
  }, [dream.ownerId, dream.id]);
  const handleDreamClick = (event) => {
    event.preventDefault();
    if (!location.pathname.includes("dream-details")) {
      navigate(`/home/maker/dream-details/${dreamOwnerData.id}/${dream.id}`);
    }
  };
  const handleCopyLink = async () => {
    try {
      await navigator.clipboard.writeText(shareUrl);
      console.log("Link copied to clipboard");
    } catch (error) {
      console.error("Failed to copy link to clipboard:", error);
    }
  };
  return (
    <Card
      variant="outlined"
      sx={{
        borderRadius: "24px",
        width: "100%",
        border: "1px solid #eaeaea",
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <CardMedia
        component="img"
        height="200"
        image={imgURL || logoSmileUp}
        alt="Dreamer cover"
        sx={
          imgURL
            ? { padding: "15px", borderRadius: "25px" }
            : { padding: "15px", borderRadius: "25px", objectFit: "contain" }
        }
      />
      <Stack position="relative">
        <Box display="flex" justifyContent="flex-end" width="90%">
          {/* <Button
            variant="contained"
            sx={{
              backgroundColor: "#252831",
              width: "100px",
              position: "absolute",
              top: "-40px",
            }}
          >
            Seguir
          </Button> */}
        </Box>
        <Stack direction="row" my={2} gap={2} padding="0 15px">
          <Avatar
            alt="Perfil del soñador"
            src={dreamOwnerData?.imgURL || noImagePlaceholder}
            sx={{ height: "60px", width: "60px", objectFit: "cover" }}
          />
          <div>
            <Typography variant="h5" fontWeight="bold">
              {userOwnerName}
            </Typography>
            <Typography variant="body1">{email}</Typography>
          </div>
        </Stack>
        <Box padding="0 15px">
          <Stack direction="row" spacing={2}>
            <IconButton
              color="primary"
              aria-label="copy"
              onClick={handleCopyLink}
            >
              <FaLink size={14} />
            </IconButton>
            <WhatsappShareButton url={shareUrl} title={shareTitle}>
              <FaWhatsapp
                style={{ color: "#25D366", cursor: "pointer" }}
                size={18}
              />
            </WhatsappShareButton>

            <InstapaperShareButton url={shareUrl} title={shareTitle}>
              <FaInstagram
                style={{ color: "#E4405F", cursor: "pointer" }}
                size={18}
              />
            </InstapaperShareButton>

            <FacebookShareButton url={shareUrl} quote={shareTitle}>
              <FaFacebook
                style={{ color: "#1877F2", cursor: "pointer" }}
                size={18}
              />
            </FacebookShareButton>

            <TelegramShareButton url={shareUrl} title={shareTitle}>
              <FaTelegram
                style={{ color: "#0088cc", cursor: "pointer" }}
                size={18}
              />
            </TelegramShareButton>
          </Stack>
        </Box>
      </Stack>
      <CardContent
        sx={{
          backgroundColor: "#f4f4f4",
          padding: 0,
          flexGrow: 1,
        }}
      >
        <Box
          sx={{
            borderBottomLeftRadius: "20px",
            borderBottomRightRadius: "20px",
            backgroundColor: "#fff",
            width: "100%",
            padding: "16px",
            height: "100%",
          }}
        >
          <Typography variant="h5" fontWeight="bold" marginBottom="16px">
            {dreamName}
          </Typography>
          <Typography variant="body1" marginBottom="16px" width="80%">
            {dreamDescription}
          </Typography>
          <Stack direction="row" gap={2}>
            {dreamMakers.map((maker, index) => {
              return index >= 3 ? (
                <Box
                  sx={{
                    height: "54px",
                    width: "54px",
                    borderRadius: "50%",
                    border: "1px solid #eaeaea",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <FiMoreHorizontal />
                </Box>
              ) : (
                <Avatar
                  alt="previsualización de un proyecto"
                  src={maker.imgURL}
                  sx={{ height: "54px", width: "54px", objectFit: "cover" }}
                />
              );
            })}
          </Stack>
        </Box>
      </CardContent>
      <CardActions
        sx={{ backgroundColor: "#f4f4f4", padding: "15px 12px 30px 12px" }}
      >
        <Stack direction="row" width="100%" gap={isDown1630 ? 1 : 2}>
          <Box>
            <Typography variant="body1" fontSize="10px">
              {general?.makers}
            </Typography>
            <Typography variant="body1" fontSize="14px" fontWeight="bold">
              {dreamMakers.length}
            </Typography>
          </Box>

          <Button
            size="small"
            variant="contained"
            onClick={(event) => handleDreamClick(event)}
            sx={{
              marginLeft: "auto",
              display: "inline-block",
              background:
                "linear-gradient(90deg, #6345ED 0%, #7F42F0 52.6%, #DC39FC 100%)",
            }}
          >
            {general?.transform}
          </Button>
        </Stack>
      </CardActions>
    </Card>
  );
};

export default DreamerCard;
