import { Box, Modal, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";

const NeedAnAccountModal = ({ open, handleOpen }) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: "12px",
  };

  return (
    <Modal open={open} onClose={() => handleOpen(false)}>
      <Box sx={style}>
        <Box position="absolute" top={0} right={0}>
          <IconButton onClick={() => handleOpen(false)}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          ¿Quiere ser soñador?
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          Comuníquese{" "}
          <a target="_blank" href="https://www.whatsapp.com/?lang=es_LA">
            aquí
          </a>
        </Typography>
      </Box>
    </Modal>
  );
};

export default NeedAnAccountModal;
