import {
  db,
  firebaseFieldVal,
  storageRef,
  timestampCreador,
} from "../../firebase/firebase-config";
import { types } from "../types/types";
import { createNotification, getOneUser } from "./userActions";

export const getMakers = async () => {
  try {
    const makers = [];
    const querySnapshot = await db
      .collection("users")
      .where("isMaker", "==", true)
      .get();

    querySnapshot.docs.forEach((doc) => {
      makers.push({ id: doc.id, ...doc.data() });
    });

    return makers;
  } catch (error) {
    console.log(error);
  }
};

export const getAllDreams = () => {
  return async (dispatch) => {
    try {
      dispatch(isLoadingDreams(true));
      const dreams = [];

      const querySnapshot = await db.collectionGroup("dreams").get();
      const data = querySnapshot.docs;

      for (const datum of data) {
        const docRef = datum.ref;
        const parentCollectionRef = docRef.parent.parent;

        const userData = await parentCollectionRef.get();

        if (userData) {
          dreams.push({
            id: datum.id,
            ...datum.data(),
            userOwnerName: userData.data().name,
            userOwnerId: userData.id,
            email: userData.data().email,
          });
        }
      }

      dispatch(getDreams(dreams));
      dispatch(isLoadingDreams(false));
    } catch (error) {
      console.log(error);
      dispatch(isLoadingDreams(false));
    }
  };
};
export const getDream = async (userId, dreamId) => {
  try {
    // Assuming 'users' is the collection of users
    const userDocRef = db.collection("users").doc(userId);
    const userSnapshot = await userDocRef.get();

    if (userSnapshot.exists) {
      const dreamsCollectionRef = userDocRef.collection("dreams");
      const dreamDocRef = dreamsCollectionRef.doc(dreamId);
      const dreamSnapshot = await dreamDocRef.get();

      if (dreamSnapshot.exists) {
        const dreamData = {
          id: dreamSnapshot.id,
          ...dreamSnapshot.data(),
          userOwnerName: userSnapshot.data().name,
          userOwnerId: userSnapshot.id,
          email: userSnapshot.data().email,
        };
        return dreamData;
      } else {
        console.error("Dream not found");
      }
    } else {
      console.error("User not found");
    }
  } catch (error) {
    console.error(error);
  }

  return null; // Return null if the dream is not found or an error occurs
};

export const getDreamsBatch = (
  lastDream,
  BATCH_SIZE,
  setDreamsData,
  executeAction
) => {
  return async (dispatch) => {
    let skipFinally = false;

    try {
      dispatch(isLoadingDreams(true));
      let query = db
        .collectionGroup("dreams")
        .orderBy("cdate", "desc")
        .limit(BATCH_SIZE);

      if (lastDream) {
        query = query.startAfter(lastDream.cdate);
      }

      const snapshot = await query.get();
      const dreamDocs = snapshot.docs;
      const dreams = [];

      for (const dream of dreamDocs) {
        const docId = dream.id;
        const docRef = dream.ref;

        const parentCollectionRef = docRef.parent.parent;

        const userData = await parentCollectionRef.get();

        dreams.push({
          id: docId,
          userOwnerName: userData.data().name,
          userOwnerId: userData.id,
          email: userData.data().email,
          ...dream.data(),
        });
      }

      const newLastDream = dreams[dreams.length - 1];
      const dreamsNum = dreams.length;
      setDreamsData(newLastDream, dreamsNum);

      dispatch(getDreams(dreams));
      dispatch(getDreamsLastBatchSize(dreamDocs.length));
      dispatch(isLoadingDreams(false));
    } catch (error) {
      console.log(error);
      dispatch(isLoadingDreams(false));
      skipFinally = true;
    } finally {
      if (executeAction && !skipFinally) {
        executeAction();
      }
    }
  };
};

export const getMakersFromDream = (userId, dreamId) => {
  return async (dispatch) => {
    try {
      dispatch(isLoadingDreamer(true));

      const makers = [];
      const makersData = await db
        .collection("users")
        .doc(userId)
        .collection("dreams")
        .doc(dreamId)
        .collection("makers")
        .get();

      makersData.docs.forEach((doc) => {
        makers.push({ id: doc.id, dreamId, ...doc.data() });
      });

      dispatch(getMakersByDream(makers));
      dispatch(isLoadingDreamer(true));
    } catch (error) {
      console.log(error);
    }
  };
};

export const getFollowersWithLimit = (limitNumber, userId) => {
  return async (dispatch) => {
    try {
      const followers = [];
      dispatch(isLoadingFollowers(true));

      let query = db
        .collection("users")
        .doc(userId)
        .collection("followers")
        .orderBy("cdate", "desc");

      if (limitNumber) {
        query = query.limit(limitNumber);
      }

      const result = await query.get();

      for (const follower of result.docs) {
        const user = await getOneUser(follower.data().userRef);
        followers.push({ id: follower.data().userRef, ...user });
      }

      dispatch(getFollowers(followers));
      dispatch(isLoadingFollowers(false));
    } catch (error) {
      console.log(error);
      dispatch(isLoadingFollowers(false));
    }
  };
};

export const getFollowersAmount = async (dreamerId, dreamId) => {
  try {
    const data = await db
      .collection("users")
      .doc(dreamerId)
      .collection("dreams")
      .doc(dreamId)
      .collection("followers")
      .get();

    const amount = data.docs.length;

    return amount;
  } catch (error) {
    console.log(error);
  }
};
export const getDreamMakers = async (dreamerId, dreamId) => {
  try {
    const data = await db
      .collection("users")
      .doc(dreamerId)
      .collection("dreams")
      .doc(dreamId)
      .collection("makers")
      .get();

    return data;
  } catch (error) {
    console.log(error);
  }
};
export const addMakerToDream = async (
  dreamerId,
  dreamId,
  userId,
  makerName,
  imgURL
) => {
  try {
    // Check if the maker already exists in the dream to avoid duplicates
    const existingMaker = await db
      .collection("users")
      .doc(dreamerId)
      .collection("dreams")
      .doc(dreamId)
      .collection("makers")
      .doc(userId)
      .get();

    if (existingMaker.exists) {
      console.log("Maker already exists in the dream.");
      return; // Maker already exists, no need to add again
    }

    // Add the maker to the dream
    await db
      .collection("users")
      .doc(dreamerId)
      .collection("dreams")
      .doc(dreamId)
      .collection("makers")
      .doc(userId)
      .set({
        userRef: userId,
        name: makerName,
        imgURL: imgURL,
      });

    console.log("Maker added to the dream successfully.");
  } catch (error) {
    console.error("Error adding maker to dream:", error);
  }
};

export const getDreamersPosts = async () => {
  try {
    const posts = [];
    const querySnapshot = await db.collectionGroup("posts").get();

    querySnapshot.docs.forEach((doc) => {
      posts.push({ id: doc.id, ...doc.data() });
    });

    return posts;
  } catch (error) {
    console.log(error);
  }
};

export const getDreamers = (elementsList, dreamersCurrentList) => {
  return async (dispatch) => {
    try {
      dispatch(isLoadingDreamer(true));
      const dreamers = [];
      const collectionRef = db.collection("users");
      const fetchedDreamerIds = new Set();

      for (const element of elementsList) {
        // Check Redux dreamers
        const existsInRedux = dreamersCurrentList.some(
          (dreamer) => dreamer.id === element.userOwnerId
        );

        // Check already fetched dreamers
        const alreadyFetched = fetchedDreamerIds.has(element.userOwnerId);

        if (!existsInRedux && !alreadyFetched) {
          const dreamer = await collectionRef.doc(element.userOwnerId).get();
          dreamers.push({ id: element.userOwnerId, ...dreamer.data() });
          fetchedDreamerIds.add(element.userOwnerId);
        }
      }

      dispatch(setDreamers(dreamers));
      dispatch(isLoadingDreamer(false));
    } catch (error) {
      console.log(error);
      dispatch(isLoadingDreamer(false));
    }
  };
};

export const getPosts = async (userId, lastPost, BATCH_SIZE, dateValue) => {
  try {
    const postRef = db.collectionGroup("posts");
    let query = postRef
      .orderBy("cdate", "desc")
      .limit(BATCH_SIZE)
      .where("isActive", "==", true);

    if (lastPost) {
      query = query.startAfter(lastPost.cdate);
    }

    if (dateValue) {
      query = query.where("cdate", ">=", dateValue);
    }

    const snapshot = await query.get();
    const postDocs = snapshot.docs;
    const posts = [];

    for (const post of postDocs) {
      const docId = post.id;

      //Getting likes data
      const likesData = await post.ref.parent
        .doc(docId)
        .collection("likes")
        .where("userRef", "==", userId)
        .get();

      //Creating likes array
      const likes = [];
      likesData.docs.forEach((like) => {
        likes.push({ id: like.id, ...like.data() });
      });

      posts.push({
        id: docId,
        ...post.data(),
        userOwnerId: post.ref.parent.parent.id,
        postLikes: likes,
      });
    }

    return posts;
  } catch (error) {
    console.log(error);
  }
};

export const getOnePost = async (userId, postId, action) => {
  let skipFinally = false;
  try {
    const result = await db
      .collection("users")
      .doc(userId)
      .collection("posts")
      .doc(postId)
      .get();

    const post = {
      id: postId,
      ...result.data(),
      userOwnerId: result.ref.parent.parent.id,
    };

    return post;
  } catch (error) {
    console.log(error);
    skipFinally = true;
  } finally {
    if (action && !skipFinally) {
      action();
    }
  }
};

export const filterPosts = async (lastPost, BATCH_SIZE, dateValue) => {
  try {
    const posts = [];
    let query = db
      .collectionGroup("posts")
      .orderBy("cdate", "desc")
      .limit(BATCH_SIZE)
      .where("cdate", ">=", dateValue);

    if (lastPost) {
      query = query.startAfter(lastPost.cdate);
    }

    const data = await query.get();

    data.docs.forEach((doc) => {
      posts.push({
        id: doc.id,
        parentId: doc.ref.parent.parent.id,
        ...doc.data(),
      });
    });

    return posts;
  } catch (error) {
    console.log(error);
  }
};

export const getFilterType = (type) => ({
  type: types.getFilterType,
  payload: {
    type,
  },
});

export const getOneUserPosts = async (lastPost, BATCH_SIZE, userId) => {
  try {
    const postRef = db.collection("users").doc(userId).collection("posts");
    // const postRef = db.collectionGroup("posts");
    let query = postRef.orderBy("cdate", "desc").limit(BATCH_SIZE);

    if (lastPost) {
      query = query.startAfter(lastPost.cdate);
    }

    const snapshot = await query.get();
    const postDocs = snapshot.docs;
    const posts = [];

    for (const post of postDocs) {
      //Getting images data
      const docId = post.id;
      const imagesData = await post.ref.parent
        .doc(docId)
        .collection("images")
        .get();

      //Creating images array
      const images = [];
      imagesData.docs.forEach((img) => {
        images.push({ id: img.id, ...img.data() });
      });

      //Getting likes data
      const likesData = await post.ref.parent
        .doc(docId)
        .collection("likes")
        .where("userRef", "==", userId)
        .get();

      //Creating likes array
      const likes = [];
      likesData.docs.forEach((like) => {
        likes.push({ id: like.id, ...like.data() });
      });

      posts.push({
        id: docId,
        ...post.data(),
        userOwnerId: post.ref.parent.parent.id,
        postImages: images,
        postLikes: likes,
      });
    }

    return posts;
  } catch (error) {
    console.log(error);
  }
};

export const getOneComment = async (userId, postId, commentId) => {
  try {
    const result = await db
      .collection("users")
      .doc(userId)
      .collection("posts")
      .doc(postId)
      .collection("comments")
      .doc(commentId)
      .get();

    const userRef = result.data().userRef;
    //const commentOwnerData = await getOneUser(userRef);

    const comment = {
      id: result.id,
      ...result.data(),
      //commentOwnerData,
      postId,
    };

    return comment;
  } catch (error) {
    console.log(error);
  }
};

export const getPostComments = async (
  userId,
  postId,
  limitNumber,
  lastComment
) => {
  try {
    const postComments = [];
    let query = db
      .collection("users")
      .doc(userId)
      .collection("posts")
      .doc(postId)
      .collection("comments")
      .where("isActive", "==", true)
      .orderBy("cdate", "desc");

    if (limitNumber) {
      query = query.limit(limitNumber);
    }

    if (lastComment) {
      query = query.startAfter(lastComment.cdate);
    }

    const querySnapshot = await query.get();

    for (const doc of querySnapshot.docs) {
      const userRef = doc.data().userRef;
      const commentOwnerData = await getOneUser(userRef);

      const docId = doc.id;

      //Getting likes data

      const likesData = await doc.ref.parent
        .doc(docId)
        .collection("likes")
        .where("userRef", "==", userId)
        .get();

      //Creating likes array
      const likes = [];
      likesData.docs.forEach((like) => {
        likes.push({ id: like.id, ...like.data() });
      });

      postComments.push({
        id: doc.id,
        ...doc.data(),
        commentOwnerData,
        postId,
        commentLikes: likes,
      });
    }

    return postComments;
  } catch (error) {
    console.log(error);
  }
};

export const getRecentComments = (
  postId,
  setNewComments,
  postOwnerId,
  limitNumber,
  userId
) => {
  try {
    let query = db
      .collection("users")
      .doc(postOwnerId)
      .collection("posts")
      .doc(postId)
      .collection("comments")
      .orderBy("cdate", "desc");

    if (limitNumber) {
      query = query.limit(limitNumber);
    }

    const unsubscribe = query.onSnapshot(async (querySnapshot) => {
      const newComments = [];
      const commentDocs = querySnapshot.docs;

      for (const comment of commentDocs) {
        const userRef = comment.data().userRef;
        const commentOwnerData = await getOneUser(userRef);

        const docId = comment.id;

        //Getting likes data

        const likesData = await comment.ref.parent
          .doc(docId)
          .collection("likes")
          .where("userRef", "==", userId)
          .get();

        //Creating likes array
        const likes = [];
        likesData.docs.forEach((like) => {
          likes.push({ id: like.id, ...like.data() });
        });

        newComments.push({
          id: comment.id,
          ...comment.data(),
          commentOwnerData,
          postId,
          commentLikes: likes,
        });
      }
      setNewComments(newComments);
    });

    return unsubscribe;
  } catch (error) {
    console.log(error);
  }
};

export const createComment = async (userId, postId, commentData) => {
  try {
    const data = await db
      .collection("users")
      .doc(userId)
      .collection("posts")
      .doc(postId)
      .collection("comments")
      .add(commentData);

    const increment = firebaseFieldVal.increment(1);

    db.collection("users")
      .doc(userId)
      .collection("posts")
      .doc(postId)
      .set({ commentsCount: increment }, { merge: true });

    return data.id;
  } catch (error) {
    console.log(error);
  }
};

export const getPostImages = async (userId, postId, limitNumber) => {
  try {
    const postImages = [];
    let query;
    query = db
      .collection("users")
      .doc(userId)
      .collection("posts")
      .doc(postId)
      .collection("images");

    if (limitNumber) {
      query = query.limit(limitNumber);
    }

    query = await query.get();

    query.docs.forEach((doc) => {
      postImages.push({ id: doc.id, ...doc.data() });
    });

    return postImages;
  } catch (error) {
    console.log(error);
  }
};

export const getPostOneImage = async (userId, postId) => {
  try {
    const postImages = [];
    const querySnapshot = await db
      .collection("users")
      .doc(userId)
      .collection("posts")
      .doc(postId)
      .collection("images")
      .get();

    querySnapshot.docs.forEach((doc) => {
      postImages.push({ id: doc.id, ...doc.data() });
    });

    return postImages;
  } catch (error) {
    console.log(error);
  }
};

export const getUserImages = async (userId) => {
  try {
    const images = [];
    const data = await db
      .collectionGroup("images")
      .where("userRef", "==", userId)
      .orderBy("cdate", "desc")
      .get();

    data.forEach((item) => images.push({ ...item.data(), id: item.id }));

    return images;
  } catch (error) {
    console.log(error);
  }
};

export const getImagesPreview = async (limitNumber) => {
  try {
    const images = [];
    const data = await db
      .collectionGroup("images")
      // .where("userRef", "==", userId)
      .orderBy("cdate", "desc")
      .limit(limitNumber)
      .get();

    data.forEach((item) => images.push({ ...item.data(), id: item.id }));

    return images;
  } catch (error) {
    console.log(error);
  }
};

export const getVideosPreview = async (limitNumber) => {
  try {
    const videos = [];
    const data = await db
      // .collection("users")
      // .doc(userId)
      .collectionGroup("posts")
      .orderBy("cdate", "desc")
      .where("type", "==", "video")
      .limit(limitNumber)
      .get();

    data.forEach((item) =>
      videos.push({
        videoURL: item.data().videoURL,
        id: item.id,
        ...item.data(),
      })
    );

    return videos;
  } catch (error) {
    console.log(error);
  }
};

export const getUserVideos = async (userId) => {
  try {
    const videos = [];
    const data = await db
      .collection("users")
      .doc(userId)
      .collection("posts")
      .where("type", "==", "video")
      .get();

    data.forEach((item) =>
      videos.push({
        videoURL: item.data().videoURL,
        id: item.id,
        ...item.data(),
      })
    );

    return videos;
  } catch (error) {
    console.log(error);
  }
};

export const getUserEvents = async (userId) => {
  try {
    const events = [];

    const data = await db
      .collection("events")
      .where("userRef", "==", userId)
      .get();

    data.forEach((item) => events.push({ ...item.data(), id: item.id }));

    return events;
  } catch (error) {
    console.log(error);
  }
};

export const searchQuery = async (searchTerm, userId) => {
  try {
    const dreamers = [];
    const posts = [];

    const dreamersData = await db
      .collection("users")
      .where("isDreamer", "==", true)
      .where("name", ">=", searchTerm)
      .where("name", "<=", searchTerm + "\uf8ff")
      .get();

    // const dreamersData = await db
    //   .collection("users")
    //   .where("isDreamer", "==", true)
    //   .where("name", "==", searchTerm)
    //   .get();
    const postsData = await db
      .collectionGroup("posts")
      .where("description", "==", searchTerm)
      .get();

    for (const dreamer of dreamersData.docs) {
      dreamers.push({ id: dreamer.id, ...dreamer.data() });
    }

    for (const post of postsData.docs) {
      posts.push({
        id: post.id,
        ...post.data(),
        parentId: post.ref.parent.parent.id,
      });
    }

    return { dreamers, posts };
  } catch (error) {
    console.log(error);
  }
};

export const createDream = async (userId, dreamData) => {
  try {
    const data = await db
      .collection("users")
      .doc(userId)
      .collection("dreams")
      .add(dreamData);

    return data.id;
  } catch (error) {
    console.log(error);
  }
};

export const createVideoPost = async (userId, postData) => {
  try {
    const data = await db
      .collection("users")
      .doc(userId)
      .collection("posts")
      .add(postData);

    const increment = firebaseFieldVal.increment(1);

    db.collection("users")
      .doc(userId)
      .set({ postsCount: increment }, { merge: true });

    return data.id;
  } catch (error) {
    console.log(error);
  }
};

export const createPost = (userId, postData, files) => {
  return async (dispatch) => {
    let result;

    try {
      dispatch(isCreatingImages(true));

      result = await db
        .collection("users")
        .doc(userId)
        .collection("posts")
        .add(postData);

      for (const file of files) {
        await createImageForPost(file, userId, result.id);
      }

      const increment = firebaseFieldVal.increment(1);

      db.collection("users")
        .doc(userId)
        .set({ postsCount: increment }, { merge: true });
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(isCreatingImages(false));
      dispatch(getJustCreatedId(result.id));
    }
  };
};

export const getJustCreatedId = (id) => ({
  type: types.getJustCreatedPost,
  payload: {
    id,
  },
});

export const createImageForDream = async (imageUpload, userId, dreamId) => {
  try {
    const path = `users/${userId}/dreams/${dreamId}/dream-img`;
    const fileUrl = await uploadFile(path, imageUpload);
    const newImg = { imgURL: fileUrl };

    await db
      .collection("users")
      .doc(userId)
      .collection("dreams")
      .doc(dreamId)
      .update(newImg);
  } catch (error) {
    console.log(error);
  }
};

export const createImageForPost = async (imageUpload, userId, postId) => {
  try {
    const path = `users/${userId}/posts/${postId}/${imageUpload.name}`;
    const fileUrl = await uploadFile(path, imageUpload);
    const newImg = {
      imgURL: fileUrl,
      userRef: userId,
      cdate: timestampCreador.fromDate(new Date()),
    };

    await db
      .collection("users")
      .doc(userId)
      .collection("posts")
      .doc(postId)
      .collection("images")
      .add(newImg);
  } catch (error) {
    console.log(error);
  }
};

export const createVideoForPosts = async (
  videoUpload,
  userId,
  dreamId,
  postId
) => {
  try {
    const path = `users/${userId}/dreams/${dreamId}/posts/${postId}/videoPost`;
    const fileUrl = await uploadFile(path, videoUpload);
    const newVideoFile = { videoURL: fileUrl };

    await db
      .collection("users")
      .doc(userId)
      .collection("dreams")
      .doc(dreamId)
      .update(newVideoFile);
  } catch (error) {
    console.log(error);
  }
};

export const uploadFile = async (path, file) => {
  if (file === null) return;

  try {
    const upload = await storageRef.ref(path).put(file);
    const fileUrl = await upload.ref.getDownloadURL();

    return fileUrl;
  } catch (error) {
    console.log("No se puede subir el archivo seleccionado");
    console.log(error);
  }
};

export const getFollowers = (followers) => ({
  type: types.getFollowers,
  payload: {
    followers,
  },
});

export const isLoadingFollowers = (isLoading) => ({
  type: types.isLoadingFollowers,
  payload: {
    isLoading,
  },
});

export const isLoadingDreamer = (isLoading) => ({
  type: types.isLoadingDreamer,
  payload: {
    isLoading,
  },
});

export const isLoadingDreams = (isLoading) => ({
  type: types.isLoadingDreams,
  payload: {
    isLoading,
  },
});

export const isCreatingImages = (state) => ({
  type: types.isCreatingImages,
  payload: {
    state,
  },
});

export const setDreamers = (dreamers) => ({
  type: types.getDreamers,
  payload: {
    dreamers,
  },
});

export const getDreams = (dreams) => ({
  type: types.getAllDreams,
  payload: {
    dreams,
  },
});

export const getDreamsLastBatchSize = (batchSize) => ({
  type: types.getDreamsBatchSize,
  payload: {
    batchSize,
  },
});

export const getBatchPosts = (posts) => ({
  type: types.getPosts,
  payload: {
    posts,
  },
});

export const getMakersByDream = (makers) => ({
  type: types.getMakersByDream,
  payload: {
    makers,
  },
});
