import { Box, Grid, Typography, useMediaQuery } from "@mui/material";
import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";

import CauseCard from "../causeCard/CauseCard";

import causesBg from "../../../assets/img/causes-bg.png";

import { cardsWholeGrid } from "./cardsData";

import { ReactComponent as Morpho } from "../../../assets/img/morpho.svg";
import { useState } from "react";
import { useSelector } from "react-redux";

const CausesResponsiveSection = () => {
  const { general, landing } = useSelector((state) => {
    // console.log(state); // Log the entire state
    return state.loggedUser.language;
  });
  const [spacingEnhancer, setSpacingEnhacer] = useState(1);
  const isDown1490 = useMediaQuery("(max-width:1490px)");
  const isDown1400 = useMediaQuery("(max-width:1400px)");
  const isDown950 = useMediaQuery("(max-width:950px)");
  const isDown890 = useMediaQuery("(max-width:891px)");
  const isDown620 = useMediaQuery("(max-width:619px)");
  const isUp701 = useMediaQuery("(min-width:701px)");

  // useEffect(() => {
  //   const handleWindowResize = () => {
  //     const windowWidth = window.innerWidth;
  //     let newSpacing = 1;

  //     if (windowWidth < 1550) {
  //       newSpacing = 18;
  //     } else if (windowWidth < 1650) {
  //       newSpacing = 18;
  //     } else if (windowWidth < 1725) {
  //       newSpacing = 15;
  //     } else if (windowWidth < 1820) {
  //       newSpacing = 10;
  //     }

  //     setSpacingEnhacer(newSpacing);
  //   };

  //   window.addEventListener("resize", handleWindowResize);

  //   return () => {
  //     window.removeEventListener("resize", handleWindowResize);
  //   };
  // }, []);

  return (
    <Box
      component="section"
      sx={{
        backgroundImage: `url(${causesBg})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        height: isDown1400
          ? isDown950
            ? isDown890
              ? isDown620
                ? "2300px"
                : "1400px"
              : "1100px"
            : "1200px"
          : "90vh",
        // height: isDown1400
        //   ? isDown950
        //     ? //? isDown720
        //       isDown620
        //       ? "2300px"
        //       : "1400px"
        //     : "1200px"
        //   : "90vh",
        position: "relative",
        zIndex: 400,
        marginBottom: 0,
        flexGrow: 1,
        paddingLeft: 0,
        paddingRight: 0,
        //paddingLeft: isDown1490 ? (isDown1400 ? "90px" : 0) : "",
      }}
    >
      <Morpho style={{ position: "absolute", top: "-150px", left: -20 }} />

      <Box
        sx={{
          pt: "150px",
          pb: "70px",
        }}
      >
        <Typography
          variant="h3"
          color="#fff"
          className="fw-bold"
          textAlign="center"
        >
          {landing?.causeSection?.text1}
        </Typography>
        <Typography variant="body1" color="#fff" textAlign="center">
          {landing?.causeSection?.text2}
        </Typography>
      </Box>

      <Grid
        container
        justifyContent="space-evenly"
        rowSpacing={5}
        padding="0 40px"
      >
        {cardsWholeGrid.map((cardDetail) => {
          return (
            <Grid item>
              <CauseCard
                key={cardDetail.num}
                title={cardDetail.title}
                causesNum={cardDetail.num}
                illustration={cardDetail.component}
              />
            </Grid>
          );
        })}
        <Grid item>
          <Card
            sx={{
              width: "270px",
              height: "270px",
              background: "#C08DFF",
              borderRadius: "15px",
            }}
          >
            <CardContent
              sx={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexWrap: "wrap",
              }}
            >
              <div>
                <Typography color="#fff">
                  <span style={{ fontSize: "22px" }}>50+</span>{" "}
                  {landing?.causeSection?.linktext}
                </Typography>{" "}
                <Typography color="#fff">
                  {landing?.causeSection?.text1}
                </Typography>
              </div>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CausesResponsiveSection;
