import {
  Box,
  Button,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";

import { useFormik } from "formik";

import { FiArrowLeft } from "react-icons/fi";

import { useNavigate } from "react-router-dom";
import { profileConfigSchema } from "../../../schema/profileConfigOptions/profileConfigOptions";
import ConfigMyAccount from "../configMyAccount/ConfigMyAccount";
import ConfigSocialMedia from "../configSocialMedia/ConfigSocialMedia";
import { updateProfile } from "../../../store/actions/userActions";
import { useSelector } from "react-redux";
import { callSuccessAlert } from "../../../customAlert/SuccessAlert";
import { callUpdateAlert } from "../../../customAlert/UpdateElement";

const ProfileConfigOptions = () => {
  const navigate = useNavigate();
  const currentUser = useSelector((state) => state.loggedUser.data);
  const userData = useSelector((state) => state.loggedUser.data);

  const isDown450 = useMediaQuery("(max-width:450px)");

  const { handleChange, handleSubmit, values, errors, touched, handleBlur } =
    useFormik({
      initialValues: {
        language: userData?.profileSettings?.language ?? "",
        currency: userData?.profileSettings?.currency ?? "",
        showEmail: userData?.profileSettings?.showEmail === true ? "Si" : "No",
        showProfiles:
          userData?.profileSettings?.showProfiles === true ? "Si" : "No",
        showSocialProfiles:
          userData?.settings?.showSocial === true ? "Si" : "No",
        facebook: userData?.social?.facebook ?? "",
        twitter: userData?.social?.twitter ?? "",
        twitch: userData?.social?.twitch ?? "",
        instagram: userData?.social?.instagram ?? "",
        youtube: userData?.social?.youtube ?? "",
        discord: userData?.social?.discord ?? "",
      },
      validationSchema: profileConfigSchema,
      onSubmit: (values) => {
        const profileConfig = {
          profileSettings: {
            language: values.language,
            currency: values.currency,
            showEmail: values.showEmail === "Si" ? true : false,
            showProfiles: values.showProfiles === "Si" ? true : false,
            showSocial: values.showSocialProfiles === "Si" ? true : false,
          },
        };

        const profileSocialData = {
          social: {
            facebook: values.facebook,
            twitter: values.twitter,
            twitch: values.twitch,
            instagram: values.instagram,
            youtube: values.youtube,
            discord: values.discord,
          },
        };

        const updateProfileConfiguration = () => {
          const updateSocial = () => {
            updateProfile(currentUser.id, profileSocialData);
          };

          updateProfile(currentUser.id, profileConfig, updateSocial);
        };

        callUpdateAlert(
          updateProfileConfiguration,
          undefined,
          "Perfil Actualizado",
          "¿Desea modifcar su configuración?"
        );
      },
    });

  return (
    // <Box component="main"> mt={10}
    <Grid
      xl={9}
      lg={8}
      md={11}
      xs={12}
      item
      minHeight="100vh"
      bgcolor="#f7f7f7"
      sx={{ padding: { md: "20px 60px", xs: "20px 25px" } }}
    >
      <Box component="main">
        <span
          onClick={() => navigate(-1)}
          style={{
            cursor: "pointer",
            display: "block",
            margin: "40px 0",
            width: "20px",
          }}
        >
          <FiArrowLeft size={24} />
        </span>

        <Typography variant={isDown450 ? "h4" : "h3"} fontWeight="bold" mb={4}>
          Configuraciones de <span style={{ color: "#864bf1" }}>Perfil</span>
        </Typography>

        <form onSubmit={(event) => handleSubmit(event)}>
          <ConfigMyAccount
            values={values}
            handleBlur={handleBlur}
            handleChange={handleChange}
            touched={touched}
            errors={errors}
          />

          <ConfigSocialMedia
            values={values}
            handleBlur={handleBlur}
            handleChange={handleChange}
            touched={touched}
            errors={errors}
          />

          <Stack direction="row">
            <Button
              type="submit"
              variant="contained"
              sx={{
                backgroundColor: "#000",
                padding: "10px 25px",
                marginTop: "20px",
              }}
            >
              Guardar cambios
            </Button>
          </Stack>
        </form>
      </Box>
    </Grid>
  );
};

export default ProfileConfigOptions;
