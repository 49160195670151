import React, { useState } from "react";
import {
  Dialog,
  Box,
  Stepper,
  Step,
  StepLabel,
  Button,
  useMediaQuery,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import SignInFirstStep from "./SignInFirstStep";

const SignInModal = ({ open, handleOpen, handleClose, login }) => {
  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const Step1Content = () => {
    return <SignInFirstStep handleNext={handleNext} currentStep={activeStep} />;
  };

  const steps = [{ label: "Paso 1", content: <Step1Content /> }];

  return (
    <Dialog
      fullWidth
      maxWidth="xl"
      PaperProps={{
        sx: {
          bgcolor: "#fff",
          boxShadow: "none",
          borderRadius: "20px !important",
          width: "100%",
          overflowX: "hidden !important",
        },
      }}
      open={open}
      onClose={handleOpen}
    >
      <Box>
        <IconButton
          edge="end"
          color="inherit"
          onClick={handleClose}
          aria-label="close"
          sx={{ position: "absolute", top: 0, right: 10 }}
        >
          <CloseIcon />
        </IconButton>
        {steps[activeStep].content}
      </Box>
    </Dialog>
  );
};

export default SignInModal;
