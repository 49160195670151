import { Box } from "@mui/material";
import groupIcon from "../../../assets/img/group.svg";

const InitialCard = () => {
  return (
    <Box
      sx={{
        position: "absolute",
        backgroundColor: "#fff",
        //top: "calc(100% - 150px)",
        //top: 0,
        bottom: 0,
        right: -6,
        //right: "220px",
        textAlign: "center",
        height: "150px",
        padding: "15px 10px",
        borderBottomRightRadius: "20px",
        boxShadow: "none",
      }}
    >
      <img src={groupIcon} alt="" width="20px" className="mx-auto d-block" />
      <p
        style={{
          fontSize: "30px",
          color: "#8828ff",
          marginBottom: "0",
          textAlign: "center",
          fontWeight: "bold",
        }}
      >
        +20
      </p>
      <p style={{ fontWeight: "bold", textAlign: "center" }}>Profesionales</p>
    </Box>
  );
};

export default InitialCard;
