import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import { Container } from "react-bootstrap";

import { FiChevronRight } from "react-icons/fi";
import ReactPlayer from "react-player";
import VideoDescription from "../videoDescription/VideoDescription";

const MyVideos = ({ videos }) => {
  return (
    <>
      {videos.length ? (
        <Stack gap={3} bgcolor="#fff" borderRadius="12px" padding="30px">
          <Typography variant="h6" fontWeight="bold">
            Videos
          </Typography>
          <Grid container rowSpacing={4}>
            {videos.slice(0, 3).map((item) => {
              return (
                <Grid item lg={12}>
                  <Stack direction="row" gap={2}>
                    <ReactPlayer
                      width="100%"
                      height="100%"
                      url={item.videoURL}
                    />
                    <VideoDescription videoData={item} />
                  </Stack>
                </Grid>
              );
            })}
          </Grid>
          <Button
            variant="outlined"
            sx={{
              borderRadius: "4px",
              color: "#000",
              borderColor: "rgba(143, 146, 161, 0.2)",
              width: "120px",
            }}
          >
            Ver más <FiChevronRight />
          </Button>
        </Stack>
      ) : (
        <Stack gap={3} bgcolor="#fff" borderRadius="12px" padding="30px">
          <Typography variant="h6" fontWeight="bold">
            Videos
          </Typography>
          <Box
            minHeight="400px"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <h3>No hay ningún video por aquí ಠ_ಠ</h3>
          </Box>
        </Stack>
      )}
    </>
  );
};

export default MyVideos;
