import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import { Typography, Box } from "@mui/material";
import Grid from "@mui/material/Grid";

import "react-alice-carousel/lib/alice-carousel.css";

const BlogSlide = ({ title, content, ...otherProps }) => {
  const { imgSource } = otherProps;

  return (
    // <div style={{ padding: "200px" }}>
    <Card
      sx={{
        borderRadius: "20px",
        backgroundColor: "#FDFDFD",
        padding: "24px",
        // height: "500px",
        boxShadow: "none",
        userSelect: "none",
      }}
    >
      <Grid container>
        <Grid item sm={6} xs={12}>
          <Box sx={{ p: 2 }}>
            <div style={{ backgroundColor: "#F5F6F7", borderRadius: "18px" }}>
              <Box
                width={{ xs: "100%", md: "180px" }}
                sx={{
                  objectFit: "cover",
                  //width: { sm: "100%", md: "180px" },
                  height: "280px",
                  margin: "0 auto",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <img src={imgSource} alt="" />
              </Box>
            </div>
            <Box sx={{ mt: 2 }}>
              <p className="mb-0">Posted on</p>
              <Typography color="#8828FF">Enero 26, 2023</Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item sm={6}>
          <CardHeader
            title={"3 áreas tecnologicas donde podes guiarte"}
            titleTypographyProps={{ fontSize: "22px", fontWeight: "bold" }}
          ></CardHeader>
          <CardContent>
            <Typography lineHeight="28px">
              facilitando la colaboración y la solidaridad. Para marcar una
              diferencia positiva en la sociedad.
            </Typography>
          </CardContent>
        </Grid>
      </Grid>
    </Card>
    // </div>
  );
};

export default BlogSlide;
