import { Box, Grid, Stack } from "@mui/material";
import Faq from "../../components/faq/Faq";
import FaqTopics from "../../components/faq/FaqTopics";
import NavigationBar from "../../components/landingPage/navbar/NavigationBar";
import Footer from "../../components/footer/Footer";
import { useState } from "react";
import LogInModal from "../../components/modals/LogInModal";
import RegisterModal from "../../components/modals/RegisterModal";
import PasswordRecoveryModal from "../../components/modals/PasswordRecoveryModal";
import SignUpModal from "../../components/SignUp/SignUpModal";
import SignInModal from "../../components/signIn/SignInModal";
import { useSelector } from "react-redux";

const FaqView = () => {
  const [currentTopic, setCurrentTopic] = useState("all");
  const [lastTopic, setLastTopic] = useState("Todos");
  const { faq, login, footer, faqText } = useSelector(
    (state) => state.loggedUser.language
  );
  const [openLogIn, setOpenLogIn] = useState(false);
  const [openRegister, setOpenRegister] = useState(false);
  const [openPassRecovery, setOpenPassRecovery] = useState(false);
  const [openSignUpSteps, setOpenSignUpSteps] = useState(false);
  const [openSignInSteps, setOpenSignInSteps] = useState(false);

  return (
    <>
      <Box
        bgcolor="#260055"
        paddingLeft={{ xs: "20px", sm: "40px", lg: "90px" }}
        paddingRight={{ xs: "20px", sm: "40px", lg: "90px" }}
      >
        <header>
          <NavigationBar
            setOpenLogIn={setOpenLogIn}
            setOpenRegister={setOpenRegister}
          />
        </header>
      </Box>

      <Box
        minHeight="100vh"
        display="flex"
        alignItems="center"
        bgcolor="#f7f7f7"
        paddingLeft={{ xs: "20px", sm: "40px", lg: "90px" }}
        paddingRight={{ xs: "20px", sm: "40px", lg: "90px" }}
      >
        <Grid container columnSpacing={2} my={3}>
          <Grid item xl={6} lg={4} display="flex" justifyContent="center">
            <Stack alignItems="center" justifyContent="center" width="100%">
              <FaqTopics
                currentTopic={currentTopic}
                setTopic={setCurrentTopic}
                lastTopic={lastTopic}
                setLastTopic={setLastTopic}
                faqText={faqText}
              />
            </Stack>
          </Grid>
          <Grid item xl={6} lg={8}>
            <Faq
              currentTopic={currentTopic}
              setTopic={setCurrentTopic}
              lastTopic={lastTopic}
              setLastTopic={setLastTopic}
              faq={faq}
            />
          </Grid>
        </Grid>
      </Box>

      <Footer footer={footer} />

      {/* Modals */}
      <LogInModal
        open={openLogIn}
        handleOpen={setOpenLogIn}
        handleOpenRegister={setOpenRegister}
        handleOpenPassRecovery={setOpenPassRecovery}
        handleOpenSingInStep={setOpenSignInSteps}
        login={login}
      />
      <RegisterModal
        open={openRegister}
        handleOpenLogIn={setOpenLogIn}
        handleOpenPassRecovery={setOpenPassRecovery}
        handleOpenSignUpSteps={setOpenSignUpSteps}
        handleOpenSignIn={setOpenLogIn}
        handleOpenSignInStep={setOpenSignInSteps}
        handleOpenSignUp={setOpenRegister}
      />

      <PasswordRecoveryModal
        open={openPassRecovery}
        handleOpen={setOpenPassRecovery}
        handleOpenLogIn={setOpenLogIn}
      />

      <SignUpModal open={openSignUpSteps} handleOpen={setOpenSignUpSteps} />
      <SignInModal open={openSignInSteps} handleOpen={setOpenSignInSteps} />
    </>
  );
};

export default FaqView;
