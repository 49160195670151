import { Box, CircularProgress, Stack } from "@mui/material";

import UserFollowedCarousel from "../userFollowedCarousel/UsersFollowedCarousel";
import PublishCard from "../publishCard/PublishCard";
import PhotoCollectionPostCard from "../photoCollectionPost/PhotoCollectionPostCard";
import VideoPostCard from "../videoPost/VideoPostCard";

import {
  getDreamers,
  getDreams,
  getOnePost,
  getPosts,
} from "../../../store/actions/dreamers";
import { Fragment, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import InfiniteScroll from "react-infinite-scroll-component";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import CommentsModal from "../../modals/CommentsModal";
import { useLocation } from "react-router-dom";

const Feed = ({ dateValue, filterType, dreamersPage, general }) => {
  const [lastPost, setLastPost] = useState(null);
  const [posts, setPosts] = useState([]);
  const [postsNumber, setPostsNumber] = useState(100);
  const [postOwnerId, setPostOwnerId] = useState(-1);
  const [selectedPostId, setSelectedPostId] = useState(-1);
  const [openCommentsModal, setOpenCommentsModal] = useState(false);
  const [fetchPosts, setFetchPosts] = useState(false);
  const [loading, setLoading] = useState(false);

  const BATCH_SIZE = 5;
  const isFirstUse = useRef(false);
  const dispatch = useDispatch();

  const recentPostId = useSelector((state) => state.dreamers.justCreatedPostId);
  const currentUser = useSelector((state) => state.loggedUser.data);
  const dreamers = useSelector((state) => state.dreamers.dreamers);

  const location = useLocation();

  useEffect(() => {
    let ignore = false;

    const getNextPosts = async () => {
      let newPosts = [];
      if (filterType === "all") {
        newPosts = await getPosts(currentUser.id, lastPost, BATCH_SIZE);
      } else {
        newPosts = await getPosts(
          currentUser.id,
          lastPost,
          BATCH_SIZE,
          dateValue
        );
      }
      setPosts((prevPosts) => {
        const posts = [...prevPosts, ...newPosts];

        const uniqueIds = [];

        const unique = posts.filter((post) => {
          const isDuplicate = uniqueIds.includes(post.id);

          if (!isDuplicate) {
            uniqueIds.push(post.id);

            return true;
          }

          return false;
        });

        unique.sort(
          (firstValue, secondValue) => secondValue.cdate - firstValue.cdate
        );

        return unique;
      });

      setLastPost(newPosts[newPosts.length - 1]);
      setPostsNumber(newPosts.length);
      dispatch(getDreamers(newPosts, dreamers));
      //dispatch(getBatchPosts(newPosts));
    };

    if (postsNumber >= BATCH_SIZE) {
      if (!ignore) {
        getNextPosts();
      }
    }

    return () => {
      ignore = true;
    };
  }, [fetchPosts]);

  useEffect(() => {
    setPosts([]);
    setLastPost(null);
    setPostsNumber(100);

    if (isFirstUse.current) {
      setFetchPosts((prevValue) => !prevValue);
    }

    isFirstUse.current = true;
  }, [filterType]);

  useEffect(() => {
    const getRecentPost = async () => {
      const recentPost = await getOnePost(currentUser.id, recentPostId, () =>
        setLoading(false)
      );
      setPosts((prevPosts) => {
        prevPosts.unshift(recentPost);

        return prevPosts.slice();
      });
    };

    if (!!recentPostId) {
      getRecentPost();
    }
  }, [recentPostId]);

  return (
    <Box component={"main"} id="scrollableBox" overflow="auto" minHeight="90vh">
      <UserFollowedCarousel general={general} dreamersPage={dreamersPage} />
      {location.pathname.includes("maker") ? null : (
        <PublishCard
          startLoading={setLoading}
          general={general}
          dreamersPage={dreamersPage}
        />
      )}
      <InfiniteScroll
        style={{ scrollbarWidth: "none" }}
        dataLength={posts.length} //This is important field to render the next data
        next={() => setFetchPosts((prevValue) => !prevValue)}
        hasMore={postsNumber >= BATCH_SIZE}
        loader={
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            height="100px"
          >
            <CircularProgress />
          </Stack>
        }
        endMessage={
          <p style={{ textAlign: "center", marginTop: "20px" }}>
            <b>{general?.reachEnd}</b>
          </p>
        }
      >
        <ResponsiveMasonry columnsCountBreakPoints={{ 800: 1, 900: 2 }}>
          <Masonry gutter="30px">
            {loading
              ? null
              : posts.map((post, index) => {
                  return (
                    <Fragment key={index}>
                      {post.type === "image" ? (
                        <PhotoCollectionPostCard
                          likesNumber={326}
                          elementIndex={index}
                          setSelectedPost={setSelectedPostId}
                          setPostOwner={setPostOwnerId}
                          handleOpenModal={setOpenCommentsModal}
                          data={post}
                          key={post.id}
                        />
                      ) : (
                        <VideoPostCard
                          likesNumber={326}
                          setSelectedPost={setSelectedPostId}
                          setPostOwner={setPostOwnerId}
                          handleOpenModal={setOpenCommentsModal}
                          data={post}
                          key={post.id}
                        />
                      )}
                    </Fragment>
                  );
                })}
          </Masonry>
        </ResponsiveMasonry>
      </InfiniteScroll>
      <CommentsModal
        openModal={openCommentsModal}
        handleOpen={setOpenCommentsModal}
        postId={selectedPostId}
        postOwnerId={postOwnerId}
      />
    </Box>
  );
};

export default Feed;
