import { Dialog, useMediaQuery } from "@mui/material";

import CommentsContainer from "../dreamerPage/commentsContainer/CommentsContainer";

import { useFormik } from "formik";
import { useSelector } from "react-redux";
import { timestampCreador } from "../../firebase/firebase-config";
import { createComment } from "../../store/actions/dreamers";

const CommentsModal = ({ openModal, handleOpen, postOwnerId, postId }) => {
  const isDown600 = useMediaQuery("(max-width:600px)");

  const currentUser = useSelector((state) => state.loggedUser.data);

  const { getFieldProps, handleSubmit } = useFormik({
    initialValues: {
      comment: "",
    },
    //validationSchema: logInSchema,
    onSubmit: (values) => {
      const commentData = {
        cdate: timestampCreador.fromDate(new Date()),
        content: values.comment,
        userRef: currentUser.id,
        isActive: true,
      };

      createComment(postOwnerId, postId, commentData);
    },
  });

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={openModal}
      scroll={isDown600 ? "body" : "paper"}
      onClose={() => handleOpen(false)}
    >
      <CommentsContainer userId={postOwnerId} postId={postId} />
      {/* <form
        onSubmit={(event) => {
          event.preventDefault();
          handleSubmit();
        }}
      >
        <DialogTitle id="scroll-dialog-title">
          Comentarios de publicacion
        </DialogTitle>
        <DialogContent
          //className="d-flex"
          sx={{
            //width: isDown1200 ? "50%" : "100%",
            //minWidth: 325,
            // position: "absolute",
            // top: "50%",
            // left: "50%",
            // transform: "translate(-50%, -50%)",
            padding: "24px",
            // borderRadius: "20px",
            backgroundColor: "#fff",
            maxHeight: "618px",
            //backgroundColor: "#f5f5f5",
          }}
        >
          <CommentsContainer userId={postOwnerId} postId={postId} />
        </DialogContent>

        <DialogActions sx={{ padding: "24px" }}>
          <Button
            variant="text"
            sx={{ paddingLeft: 0, marginRight: "auto" }}
            onClick={() => setShowMore((prevState) => !prevState)}
          >
            Mostrar más
          </Button>

          <Box>
            <OutlinedInput
              {...getFieldProps("comment")}
              id="outlined-adornment-comment"
              fullWidth
              type="text"
              placeholder="Escribe un comentario..."
              sx={{ width: "100%" }}
              endAdornment={
                <InputAdornment position="end" sx={{ cursor: "pointer" }}>
                  <BiFace color="8F92A1" />
                  <span onClick={(event) => handleSubmit(event)}>
                    <BiSend color="#0659FD" />
                  </span>
                </InputAdornment>
              }
            />
          </Box>
        </DialogActions>
      </form> */}
    </Dialog>
  );
};

export default CommentsModal;
